/* eslint-disable no-underscore-dangle */
import { DomLoadedCheck } from '../utils/dom-loaded-check';
import { loader } from '../utils/ui-utils';
import {
  URL__PAYMENT,
  URL__PAYMENT_METHODS,
  URL__PAYMENT_PAGE,
} from '../_global-constants';
import {http} from "../utils/http-requests";

(() => {
  DomLoadedCheck(init);
})();
const PAYMENT_TYPE_PAYPAL = 'paypal';
const PAYMENT_TYPE_SCHEME = 'scheme';
const PAYMENT_TYPE_IDEAL = 'ideal';
const PAYMENT_TYPE_GIROPAY = 'giropay';
const PAYMENT_TYPE_SOFORT = 'directEbanking';

async function init() {
  if (document.querySelectorAll('.payment-dropin-container').length == 1) {
    let dropinContainer = document.querySelector('.payment-dropin-container');
    let originKey = dropinContainer.getAttribute('data-payment_originkey');
    let locale = dropinContainer.getAttribute('data-payment_locale');
    let environment = dropinContainer.getAttribute('data-payment_environment');
    let paymentRedirectionUrl = dropinContainer.getAttribute(
      'data-payment_redirection_url',
    );

    try {
      let response = await http.get(URL__PAYMENT_METHODS);
      const isInvoicePresent = !!document.querySelector('form.invoice-payment');
      const paymentConfig = {
        locale: locale,
        environment: environment,
        paymentMethodsResponse: response,
        originKey: originKey,
        openFirstPaymentMethod: !isInvoicePresent,
      };
      const checkout = new AdyenCheckout(paymentConfig);

      checkout
        .create('dropin', {
          onSubmit: (state, dropin) => {
            loader({
              active: true,
              container: document.querySelector('.order-confirmation-section__gutter'),
            });

            if (state && state.isValid) {
              _submitState(state, dropin, paymentRedirectionUrl);
            } else {
              window.location.assign(URL__PAYMENT_PAGE);
            }
          },
          onAdditionalDetails: () => {},
        })
        .mount('#dropin');

      return true;

    }
    catch(error) {
      return error;
    }
  }
}

async function _submitState(state, dropin, paymentRedirectionUrl) {
  let request = _getPaymentRequestBuilder(state);

  try {
    let response = await http.post(URL__PAYMENT, request);

    _handlePaymentResponse(dropin, response, paymentRedirectionUrl);

    return true;
  }
  catch (error) {
    window.location.assign(URL__PAYMENT_PAGE);

    return error;
  }
}

function _handlePaymentResponse(dropin, payload, paymentRedirectionUrl) {
  if (payload && payload.action) {
    dropin.handleAction(payload.action);
  } else {
    window.location.assign(
      paymentRedirectionUrl + '?query=' + encodeURI(JSON.stringify(payload)),
    );
  }
}

function _getPaymentRequestBuilder(state) {
  let paymentRequest = {};
  const paymentValidator = _withPayment(state);

  if (paymentValidator.isPaypal()) {
    return new Request.Builder(state.data.paymentMethod.type)
      .paymentPaypalRequest(state, paymentRequest)
      .build();
  }
  if (paymentValidator.isIdeal()) {
    return new Request.Builder(state.data.paymentMethod.type)
      .paymentIdealRequest(state, paymentRequest)
      .build();
  }
  if (paymentValidator.isCreditCard()) {
    return new Request.Builder(state.data.paymentMethod.type)
      .paymentCreditCartRequest(state, paymentRequest)
      .build();
  }
  if (paymentValidator.isGiroPay()) {
    return new Request.Builder(state.data.paymentMethod.type)
      .paymentGiroRequest(state, paymentRequest)
      .build();
  }
  if (paymentValidator.isSofortPay()) {
    return new Request.Builder(state.data.paymentMethod.type)
      .paymentSofortPayRequest(state, paymentRequest)
      .build();
  }

  return paymentRequest;
}

function _withPayment(state) {
  return {
    isPaypal() {
      return state.data.paymentMethod.type === PAYMENT_TYPE_PAYPAL;
    },
    isIdeal() {
      return state.data.paymentMethod.type === PAYMENT_TYPE_IDEAL;
    },
    isCreditCard() {
      return state.data.paymentMethod.type === PAYMENT_TYPE_SCHEME;
    },
    isGiroPay() {
      return state.data.paymentMethod.type === PAYMENT_TYPE_GIROPAY;
    },
    isSofortPay() {
      return state.data.paymentMethod.type === PAYMENT_TYPE_SOFORT;
    },
  };
}

class Request {
  constructor(build) {
    this.type = build.type;
    this.data = build.data;
  }
  static get Builder() {
    class Builder {
      constructor(type) {
        this.type = type;
      }
      paymentCreditCartRequest(state, data) {
        if (data) {
          data.encryptedCardNumber =
            state.data.paymentMethod.encryptedCardNumber;
          data.encryptedSecurityCode =
            state.data.paymentMethod.encryptedSecurityCode;
          data.encryptedExpiryMonth =
            state.data.paymentMethod.encryptedExpiryMonth;
          data.encryptedExpiryYear =
            state.data.paymentMethod.encryptedExpiryYear;
        }
        this.data = data;

        return this;
      }
      paymentPaypalRequest(state, data) {
        this.data = data;

        return this;
      }
      paymentIdealRequest(state, data) {
        if (data) {
          data.issuer = state.data.paymentMethod.issuer;
        }
        this.data = data;

        return this;
      }
      paymentGiroRequest(state, data) {
        if (data) {
          data.bic = state.data.paymentMethod.bic;
        }
        this.data = data;

        return this;
      }
      paymentSofortPayRequest(state, data) {
        this.data = data;

        return this;
      }
      build() {
        return new Request(this);
      }
    }

    return Builder;
  }
}
