import { createNamespacedHelpers } from 'vuex';
import { VUE_URL__SHIPPING_METHOD } from '../_global-constants';
import { CART_NAMESPACE } from '../store/modules/cart/index';
import cartMutationTypes from '../store/modules/cart/mutationTypes';
import { addEventMobileTargetDestination } from '../components/target-destination';
import {http} from "../utils/http-requests";
import { addEventDialog } from './dialog';

const { mapMutations } = createNamespacedHelpers(CART_NAMESPACE);
let savedMode = sessionStorage.getItem('shippingMode');

export default {
  name: 'VueDeliveryOrPickup',

  render() {
    return this.$scopedSlots.default({
      mode: this.mode,
      changeShippingMethod: this.changeShippingMethod,
      selectMode: this.selectMode,
    });
  },

  data() {
    return {
      mode: '',
    };
  },

  mounted() {
    this.checkSavedMode();
    let dialogTriggers = this.$el.querySelectorAll('.show-dialog-delivery');
    let targetDestinationTriggerElementsOutsideMainNavigation = this.$el.querySelectorAll(
      '.toggle--target-destination.show-dialog-delivery.is-not-main-navigation',
    );

    addEventDialog(dialogTriggers);
    addEventMobileTargetDestination(
      targetDestinationTriggerElementsOutsideMainNavigation,
    );
  },

  methods: {
    ...mapMutations({
      setCart: cartMutationTypes.CART_MUTATION__SET_CART,
    }),

    getDeliveryMethodKey() {
      let deliveryMethodKey = this.$el.querySelector(
        'input[type="radio"][name="deliveryMethodKey"]:checked',
      );

      if (!deliveryMethodKey) {
        deliveryMethodKey = this.$el.querySelector(
          'input[type="radio"][name="mode"]:checked',
        );
      }

      return deliveryMethodKey.value;
    },

    async changeShippingMethod(event, caller) {
      // select html tag onchange is triggered on page load.
      // This prevents the additional request.
      if (!event?.isTrusted) {
        return;
      }

      let loader = this.$loading.show({
        active: true,
        container: this.$el,
      });
      const requestBody = caller === 'select-mode' ? '' : this.getDeliveryMethodKey();

      try {
        let data = await http.put(VUE_URL__SHIPPING_METHOD, requestBody);

        this.setCart( data.cart);

        return data;
      }
      catch (error) {
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message,
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(
          error.message || error,
        );

        notification.show();

        return;
      }
      finally {
        loader.hide();
      }
    },

    selectMode(e) {
      sessionStorage.removeItem('shippingMode');
      this.clearAllSelectedElements();
      this.changeShippingMethod(e, 'select-mode');
    },

    checkSavedMode() {
      // Preselect delivery/pickup method if it was saved before
      if (savedMode) {
        document.getElementById(savedMode).checked = true;
        this.changeShippingMethod({isTrusted: true});

        return;
      }
    },

    clearDeliveryMethod() {
      const deliveryMethodKey = this.$root.$refs.deliveryMethods.$el.querySelector('input:checked');

      sessionStorage.removeItem('savedDeliveryMethodKey');

      if (deliveryMethodKey) {
        deliveryMethodKey.checked = false;
      }
    },

    clearAllSelectedElements() {
      if (this.$root.$refs.deliveryDates) {
        this.$root.$refs.deliveryDates.updateSelectedDate('', '');
      }
      if (this.$root.$refs.deliveryMethods) {
        this.clearDeliveryMethod();
      }
      this.$root.$refs.pickupDates.updateSelectedDate('', '');
      const selectedFormDateInputDelivery = this.$root.$el
        .querySelector('input[type="radio"][name="selectedDeliveryDate"]:checked');
      const selectedFormDateInputPickup = this.$root.$el
        .querySelector('input[type="radio"][name="selectedPickupDate"]:checked');

      if (selectedFormDateInputDelivery) {
        selectedFormDateInputDelivery.checked = false;
      }
      if (selectedFormDateInputPickup) {
        selectedFormDateInputPickup.checked = false;
      }
    },
  },
};
