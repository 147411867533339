import { UNIT_OF_MEASUREMENT } from '../_global-constants';

export const calculateQuantityWithMfa = (requestedQuantity, mfa, orderUOM) => {
  let quantity;

  if (mfa === 1 || orderUOM === UNIT_OF_MEASUREMENT.PACKAGE) {
    quantity = requestedQuantity;
  } else if (mfa > 0) {
    if (orderUOM === UNIT_OF_MEASUREMENT.KILOGRAM) {
      quantity = mfa * Math.ceil(requestedQuantity / mfa);
    } else {
      quantity = Math.trunc(mfa * Math.ceil(requestedQuantity / mfa));
    }
  } else {
    quantity = requestedQuantity;
  }

  return quantity;
};
