export const getFeatureValue = (key, state) => {
  return state.featureToggles?.[key] ?? false
}

export const getAllFeatureValues = (featureToggles) => {
  return Object.values(featureToggles).reduce((acc, value) => {
    acc[value] = getFeatureValue.bind(null, value)

    return acc
  }, {})
}