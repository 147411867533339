let timeout = null;

export default {
  name: 'VueMyAddresses',
  render() {
    return this.$scopedSlots.default({
      changeAddressForm: this.changeAddressForm,
      address: this.address,
      isStandardAddress: this.isStandardAddress,
      enableButton: this.enableButton,
      isPrivacyChecked: this.isPrivacyChecked,
    });
  },
  data: function() {
    return {
      address: {},
      isStandardAddress: false,
      isPrivacyChecked: false,
    };
  },
  methods: {
    changeAddressForm(data, isStandardAddress) {
      this.address = data;
      this.isStandardAddress = isStandardAddress;
      this.isPrivacyChecked = false;

      let element = this.$el.getElementsByTagName('input');
      let event = new Event('change');

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        for (let item of element) {
          item.dispatchEvent(event);
        }
      }, 500);
    },

    enableButton(e) {
      this.isPrivacyChecked = e.target.checked;
    },
  },
};
