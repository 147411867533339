function DomLoadedCheck(funcCallback) {
  if (document.readyState === 'complete') {
    funcCallback();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      funcCallback();
    });
  }
}

export default function() {
  throw new Error('Not implemented');
}

export { DomLoadedCheck };
