import {
  VUE_URL__CART_BULK_UPLOAD_VALIDATE,
  VUE_URL__CART_BULK_UPLOAD,
  VUE_URL__CART_BULK_UPLOAD_CONFIG,
} from '../../../_global-constants';
import {http} from "../../../utils/http-requests";
import mutationTypes from './mutationTypes';


const actions = {
  async getTemplateConfig({ commit }) {
    try {
      const request = await http.get(VUE_URL__CART_BULK_UPLOAD_CONFIG)

      commit(mutationTypes.SET_CONFIG, request);
    } catch {
      throw new Error;
    }
  },

  async sendValidationRequest({}, data) {
    try {
      const response = await http.post(VUE_URL__CART_BULK_UPLOAD_VALIDATE,data)

      return response;
    } catch {
      throw new Error;
    }
  },

  async sendValidData({}, data) {
    try {
      const responseContent = await http.post(VUE_URL__CART_BULK_UPLOAD, data)

      return responseContent;
    } catch {
      throw new Error;
    }
  },
}

export default actions;
