<template>
  <div
    :class="[
      'fragment--badge',
      {
        'is-error' : error,
        'is-bigger' : big,
        'is-success' : success,
      },
      classes,
    ]"
  >
    <span
      v-text="label"
      class="fragment--label-badge"
    >
    </span>
  </div>
</template>

<script>
  export default {
    name: "VueBadge",
    props:{
      label: {
        type: String,
        default: '',
      },
      classes: {
        type: Array,
        default: () => [],
      },
      error: {
        type: Boolean,
        default: false,
      },
      big: {
        type: Boolean,
        default: false,
      },
      success: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
