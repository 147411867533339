import { createNamespacedHelpers } from 'vuex';

import { CART_NAMESPACE } from '../store/modules/cart/index';
import { CART_GETTER__RATIONS } from '../store/modules/cart/getters';

const { mapGetters } = createNamespacedHelpers(CART_NAMESPACE);
const IS_ACTIVE_CLASSNAME = 'is-active';

export default {
  name: 'VueRations',
  render() {
    return this.$scopedSlots.default({
      rations: this.data ? this.data : this.rations,
      hideAvailable: this.hideAvailable,
      hidePointsUsed: this.hidePointsUsed,
      hideMonthlyLimit: this.hideMonthlyLimit,
    });
  },
  props: {
    data: {
      required: false,
    },

    hideAvailable: {
      type: Boolean,
      default: false,
    },
    hidePointsUsed: {
      type: Boolean,
      default: false,
    },
    hideMonthlyLimit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      rations: CART_GETTER__RATIONS,
    }),
  },
  mounted() {
    if (this.data || this.rations) {
      this.$el.classList.add(IS_ACTIVE_CLASSNAME);
    }
  },
};
