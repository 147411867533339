<template>
  <div class="hierarchy-level">
    <label class="font-h4 text-uppercase">{{ filter.title }}</label>
    <span
      class="filter-toggle-visibility"
      @click="toggleVisibility"
    ></span>
    <template v-if="isContentVisible">
      <div
        class="filter-search-box"
        style="position: relative;"
      >
        <input
          type="text"
          :placeholder="filter.placeholder"
          @input="searchItems"
        />
      </div>
      <div
        v-for="item in shownItems"
        :key="getFacetName(item)"
      >
        <div class="form-checkbox">
          <label class="has-float-label">
            <input
              type="checkbox"
              :checked="item.isRefined"
              @change="onItemClick(item)"
            />
            <span>
              <span>{{ getFacetName(item) }}</span>
              <span class="text-grey">({{ item.count }})</span>
            </span>
          </label>
        </div>
      </div>

      <button
        v-if="isShowMoreButtonShown"
        class="show-all-panel is-tertiary has-no-margin text-grey text-uppercase"
        @click="toggleShowMore"
      >
        <span
          v-if="isShowingMore"
          class="more-less-link is-link min-right"
          th:text="#{search.facets.show-less}"
        >Show less</span>
        <span
          v-else
          class="more-less-link is-link plus-right"
          th:text="#{search.facets.show-more}"
        >Show more</span>
      </button>
    </template>
  </div>
</template>

<script>
  import { getFacetName } from '../../utils/facet-hierarchy';

  export default {
    name: 'VueHierarchyLevel',

    props: {
      initialItems: {
        type: Array,
        default: () => [],
      },
      filter: {
        type: Object,
        default: () => ({
          title: "",
          placeholder: "",
        }),
      },
    },

    data: function() {
      return {
        items: this.initialItems,
        isShowingMore: false,
        limit: 10,
        isContentVisible: true,
        searchString: "",
      };
    },

    computed: {
      shownItems() {
        let items = [...this.items];

        items.sort((a, b) => b.isRefined - a.isRefined);

        return items.reduce((acc, item, index) => {
          return acc.concat(this.isItemShown(item, index) ? item : [] )
        }, []);
      },

      isShowMoreButtonShown() {
        return this.initialItems.length > this.limit || this.shownItems.length < this.initialItems.length;
      },
    },

    methods: {
      getFacetName,

      onItemClick(item) {
        this.$emit('item-clicked', item);
      },

      toggleVisibility() {
        this.isContentVisible = !this.isContentVisible;
      },

      searchItems($event) {
        this.searchString = $event.target.value;
        this.items = this.initialItems.filter(this.search);
      },

      search(item) {
        return getFacetName(item).toLowerCase().includes(this.searchString.toLowerCase());
      },

      toggleShowMore() {
        this.isShowingMore = !this.isShowingMore;
      },

      isItemShown(item, index) {
        // Algolia adds items from subcategories to first level of hierarchy, these items are not shown
        const isBrokenItem = item.count === 0;
        const isFoundInSearch = this.search(item) && this.searchString.length > 0;

        if (isBrokenItem) {
          return false;
        }

        if (item.isRefined || isFoundInSearch) {
          return true;
        }

        const refinedItems = this.initialItems.filter(item => item.isRefined);

        if (refinedItems.length > 0 && !this.isShowingMore || isBrokenItem) {
          return false;
        }

        if (index < this.limit && !this.isShowingMore) {
          return true;
        }

        return this.isShowingMore;
      },
    },

    watch: {
      initialItems(newItems) {
        this.items = newItems;
      },
    },
  }
</script>