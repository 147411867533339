<template>
  <AisSearchBox>
    <template #default="{ currentRefinement, refine }">
      <input
        type="search"
        :class="
          [
            'ais-SearchBox-input',
            {
              'is-error': !validator(currentRefinement)
            }
          ]"

        :placeholder="placeholder"
        :value="currentRefinement"
        @input="refine($event.currentTarget.value)"
      />
      <span
        v-if="!validator(currentRefinement)"
        class="is-error-text"
      >
        {{ errorMsg }}
      </span>
    </template>
  </AisSearchBox>
</template>

<script>
  import { AisSearchBox } from 'vue-instantsearch';

  export default {
    name: 'VueAisSearchInput',
    components: {
      AisSearchBox,
    },
    props: {
      placeholder: {
        type: String,
        default: null,
      },
      errorMsg: {
        type: String,
        default: null,
      },
      validator:{
        type: Function,
        default: ()=>{},
      },
    },
  }
</script>
