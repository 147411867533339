import {
  VUE_URL__USER_LIST,
  VUE_EVENT_NAME__RERENDER_PAGINATION,
} from '../_global-constants';
import { debounce } from '../utils/debounce';
import {http} from "../utils/http-requests";


export default {
  name: 'VueUsersTable',
  render() {
    return this.$scopedSlots.default({
      isMobile: this.isMobile,
      users: this.users,
      callAPI: this.callAPI,
      pagination: this.pagination,
      updateSearchQuery: this.updateSearchQuery,
      isUserEnteredMinQuery: this.isUserEnteredMinQuery,
    });
  },
  data() {
    return {
      isMobile: false,
      users: [],
      searchQuery: '',
    };
  },
  mounted: function() {
    this.getUsers();
    this.$nextTick(function() {
      window.addEventListener('resize', this.onResize);
      this.onResize();
    });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 1000;
    },

    getUsers: function() {
      let url = VUE_URL__USER_LIST;

      if (this.isUserEnteredMinQuery) {
        url += '?q=' + encodeURIComponent(this.searchQueryFormatted);
      }

      this.callAPI(url);
    },

    callAPI: async function(url) {
      let loader = this.$loading.show({
        active: true,
        container: this.$el,
      });

      try {
        let response = await http.get(url);

        this.users = response.users;

        if (this.isUserEnteredMinQuery) {
          this.addQueryToPageHref(response.pagination.pages);
        }

        this.pagination = response.pagination;

        this.$root.$emit(
          VUE_EVENT_NAME__RERENDER_PAGINATION,
          response.pagination,
        );

        return true;
      }
      catch (error) {
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message,
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(
          error.message || error,
        );

        notification.show();

        return;
      }
      finally {
        loader.hide();
      }
    },

    updateSearchQuery(event) {
      this.searchQuery = event.target.value;
    },

    addQueryToPageHref(pages) {
      pages.map((page) => {
        page.href += '&q=' + encodeURIComponent(this.searchQueryFormatted);
      });
    },
  },

  computed: {
    isUserEnteredMinQuery() {
      return this.searchQuery.length > 2;
    },

    searchQueryFormatted() {
      return this.searchQuery.toLowerCase();
    },
  },

  watch: {
    searchQuery: debounce(function() {
      this.getUsers();
    }),
  },
};
