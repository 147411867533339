const NOTIFICATION_LEVELS = ['info', 'warning', 'error', 'success'];
const SELECTOR__CLOSE = '.notification__close';

export default {
  name: 'VueNotification',

  data() {
    return {
      isActive: false,
      levelData: this.level.toLowerCase(),
      messageData: '',
    };
  },

  props: {
    level: {
      required: true,
      type: String,
      validator: level =>
        NOTIFICATION_LEVELS.indexOf(level.toLowerCase()) !== -1,
    },

    message: {
      required: true,
      type: String,
    },

    isCompact: {
      default: false,
      required: false,
      type: Boolean,
    },

    isGlobal: {
      default: false,
      required: false,
      type: Boolean,
    },

    isActiveByDefault: {
      default: false,
      required: false,
      type: Boolean,
    },

    canClose: {
      default: true,
      required: false,
      type: Boolean,
    },
  },

  methods: {
    hide() {
      this.isActive = false;
    },

    show() {
      this.isActive = true;
    },
  },

  computed: {
    iconReference() {
      return `#icon--notification--${this.levelData.toLowerCase()}`;
    },

    levelCssClass() {
      return `is-level-${this.levelData.toLowerCase()}`;
    },
  },

  mounted() {
    if (this.isActiveByDefault) {
      this.show();
    }

    let closeElement = this.$el.querySelector(SELECTOR__CLOSE);

    if (!closeElement) {return;}

    if (this.canClose) {
      closeElement.addEventListener('click', this.hide);
    } else {
      closeElement.remove();
    }
  },

  render() {
    return this.$scopedSlots.default({
      message: this.messageData.length ? this.messageData : this.message,
      iconReference: this.iconReference,
      isActive: this.isActive,
      isCompact: this.isCompact,
      isGlobal: this.isGlobal,
      levelCssClass: this.levelCssClass,
    });
  },
};
