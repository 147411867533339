import { compareStrings } from '../utils/index';

export default {
  name: 'VueRequestUserChanges',

  props: {
    initialAccountType: {
      type: String,
      default: '',
    },
    initialOrganizationType: {
      type: String,
      default: '',
    },
    initialUserData: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      accountType: this.initialAccountType,
      organizationType: this.initialOrganizationType,
      otherOrganizationType: '',
    }
  },

  mounted() {
    this.subscribeOnFormOpen();
    this.restoreSelectedFields();
  },

  computed: {
    isPersonal() {
      return compareStrings(this.accountType, 'personal');
    },

    isOrganization() {
      return compareStrings(this.accountType, 'organization');
    },

    isOtherOrganizationType() {
      return compareStrings(this.organizationType, 'other');
    },

    userData() {
      const keyValuePairs = this.initialUserData
        .replace(/\n/g, "")
        .trim()
        .split(',');
      const stringifiedValues = {
        "true": true,
        "false": false,
        "null": null,
      };

      return keyValuePairs.reduce((acc, keyValue) => {
        const [key, value] = keyValue.split(":");
        const normalizeValue = (value) => {
          const normalizedValueKey = Object.keys(stringifiedValues).find(key => key === value);

          return normalizedValueKey ? stringifiedValues[normalizedValueKey] : value;
        }

        return {...acc, [key.trim()]: normalizeValue(value) }
      }, {})
    },
  },

  methods: {
    restoreSelectedFields() {
      this.restoreAccountType();
      this.restoreOrganizationType();
      this.restoreOtherFields();
    },

    subscribeOnFormOpen() {
      const input = document.querySelector('input[name="change-personal-details"]');

      input.addEventListener('change', ({ target }) => {
        if (target.checked) {
          this.restoreSelectedFields();
        }
      })
    },

    restoreAccountType() {
      if (this.$el.accountType) {
        this.accountType = this.$el.accountType.value || this.initialAccountType;
      }
    },

    restoreOrganizationType() {
      const organizationType = this.$el.querySelector('select[name="organizationType"]');

      if (!organizationType) {
        return;
      }

      const selectedOrganizationType = organizationType.querySelector('option[selected]:not([disabled])');

      if (selectedOrganizationType) {
        this.organizationType = selectedOrganizationType.value;

        return;
      }
      const organizationTypes = ['embassy', 'mission', 'organization'];
      const isOtherOrganizationType =
        this.initialOrganizationType.length > 0
        && !organizationTypes.includes(this.initialOrganizationType.toLowerCase());

      if (isOtherOrganizationType) {
        const organizationTypeInput = this.$el.querySelector('input[name="organizationType"]');

        this.organizationType = 'Other';
        this.otherOrganizationType = this.initialOrganizationType;
        if (organizationTypeInput) {
          organizationTypeInput.value = this.initialOrganizationType;
        }
      } else {
        this.organizationType = this.initialOrganizationType;
      }
    },

    restoreOtherFields() {
      Object.entries(this.userData).forEach(([fieldName, value]) => {
        const element = this.$el.querySelector(`[name="${fieldName}"]`);

        if (!element || value === null) {
          return;
        }

        if (element.type === 'checkbox') {
          element.checked = value;
        } else {
          element.value = value;
        }
      });
    },

    onFormFieldChange({ target }) {
      this[target.name] = target.value;
    },

    onFormSubmit() {
      if (this.isOtherOrganizationType) {
        const select = [].find.call(this.$el.organizationType, node => node.tagName === "SELECT");

        select.remove();
      }
    },
  },

  render() {
    return this.$scopedSlots.default({
      onFormFieldChange: this.onFormFieldChange,
      isPersonal: this.isPersonal,
      isOrganization: this.isOrganization,
      otherOrganizationType: this.otherOrganizationType,
      isOtherOrganizationType: this.isOtherOrganizationType,
      organizationType: this.organizationType,
      onFormSubmit: this.onFormSubmit,
    });
  },
};
