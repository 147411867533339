<template>
  <div
    class="button-container cart-totals__submit-button"
  >
    <vue-alert
      v-for="message in checkoutErrorMessages"
      :key="message.content"
      :message="message.content"
      :level="message.level"
      :isActiveByDefault="true"
    />

    <div class="cart-totals__submit-button-container">
      <button
        type="submit"
        class="is-primary has-no-margin has-no-max-width"
        :disabled="!isCheckoutEnabled"
        :data-reservation-text="translations.placeReservationButton"
        :data-default-text="isTradingCustomer ? translations.placeQuoteAndTocButton : translations.checkoutButton"
      >
      </button>
      <a
        :href="rootLink"
        class="is-link arrow-left"
      > {{ translations.backToShoppingList }}</a>
    </div>
  </div>
</template>
<script>

  import { mapGetters } from 'vuex';

  import { CART_NAMESPACE } from '../../store/modules/cart/index';

  import { TRANSLATIONS_NAMESPACE } from '../../store/modules/translations';

  import {
    CART_GETTER__IS_CHECKOUT_ENABLED,
    CART_GETTER__CHECKOUT_ERROR_MESSAGES,
    CART_GETTER__INVALID_LINE_ITEMS,
  } from '../../store/modules/cart/getters';

  import VueAlert from '../../components/alert/alert.vue'

  export default {
    name: 'VueOrderButton',

    components: {
      VueAlert,
    },

    props: {

      isTradingCustomer: {
        type: Boolean,
        required: true,
      },
    },


    computed: {
      ...mapGetters(CART_NAMESPACE,{
        hasInvalidLineItems: CART_GETTER__INVALID_LINE_ITEMS,
        isCheckoutEnabledInternal: CART_GETTER__IS_CHECKOUT_ENABLED,
        checkoutErrorMessages: CART_GETTER__CHECKOUT_ERROR_MESSAGES,
      }),

      ...mapGetters(TRANSLATIONS_NAMESPACE, ['translations']),


      isCheckoutEnabled() {
        return this.isCheckoutEnabledInternal && !this.hasInvalidLineItems
      },
    },


    created(){
      // make non-reactive variable `rootlink` available to the template
      this.rootLink= window.BNS.rootLink;
    },
  };

</script>
