<template>
  <label class="has-float-label trading-price-input">
    <vue-numeric-input
      ref="numericInput"
      :name="$attrs.name"
      :value="inputValue"
      :step="0.01"
      :hasArrows="false"
      @input="updateTradePrice"
    />
    <span
      class="price-currency"
    >
      {{ currency }}
    </span>
    <span class="price-package-type">
      {{ packageTypeShort }}
    </span>
    <div class="form-notifications">
      <div
        v-show="!noError && showInvalidateMessage"
        class="is-invalid"
      >
        {{ translations.proposedPriceLowerThanProductPriceError }}
      </div>
      <div :class="{ 'is-help': isDefaultHelpSlot }">
        <slot name="help">
          <span
            class="font-extra-small-body-copy text-grey"
          >
            {{ translations.proposedPrice }}
          </span>
        </slot>
      </div>
    </div>
  </label>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { TRANSLATIONS_NAMESPACE, getterTypes as translationsGetterTypes } from '../../store/modules/translations'
  import { VueNumericInput } from '../../vue-base-components'

  const INPUT_EMIT_TIMER = 500

  export default {
    name: 'VueTradingPriceInput',
    components: { VueNumericInput },
    props: {
      value: {
        type: [String, Number],
        default: null,
      },
      currency: {
        type: String,
        default: '',
      },
      packageTypeShort: {
        type: String,
        default: null,
      },
      noError: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showInvalidateMessage: false,
        inputValue: '',
        inputEmitter: null,
      }
    },
    computed: {
      isDefaultHelpSlot() {
        return !Boolean(this.$slots.help)
      },

      ...mapGetters(TRANSLATIONS_NAMESPACE, {
        'translations': translationsGetterTypes.TRANSLATIONS,
      }),
    },
    created() {
      this.validate(this.value, false)
    },
    methods: {
      validate(value, invalidateField = true) {
        if (value === 0) {
          this.showInvalidateMessage = true
          if (invalidateField) {
            this.$refs.numericInput.invalidate()
          }
          this.$emit('error', true)

          return false
        }


        const regexp = /^\d+(\.\d{1,2})?$/;

        if (value < 1 && !regexp.test(value)) {
          this.showInvalidateMessage = false
          if (invalidateField) {
            this.$refs.numericInput.invalidate()
          }
          this.$emit('error', true)

          return false
        }

        return true
      },
      tradingPriceValueChangeHandler(newValue) {
        if (newValue != this.inputValue) {
          this.inputValue = newValue
        }
      },
      updateTradePrice(event) {
        this.$emit('error', false) // Clear any error state on input

        clearTimeout(this.inputEmitter)
        const value = Number(event.target.value)

        this.$refs.numericInput.resetValidation()
        this.inputValue = event.target.value // store value in string to keep the decimal places when using backspace

        if (!this.validate(value)) {
          return
        }

        if (value !== this.value) {
          this.$emit('input', event) // Emit normal input event
          this.inputEmitter = setTimeout(() => {
            this.$emit('update', {
              event,
              value,
            })
          }, INPUT_EMIT_TIMER)
        }
      },
    },
    watch: {
      value: {
        immediate: true,
        handler: 'tradingPriceValueChangeHandler',
      },
    },
  }
</script>