import { SEARCH_DELAY } from '../_global-constants';

export function debounce(func, delay = SEARCH_DELAY) {
  let timerId;

  return function(...args) {
    const context = this;

    clearTimeout(timerId);
    timerId = setTimeout(() => func.apply(context, args), delay);
  };
}
