import { createNamespacedHelpers, mapGetters } from 'vuex';

import {
  FEATURE_NAMESPACE,
  getterTypes,
} from '../store/modules/features';
import { CART_NAMESPACE } from '../store/modules/cart';
import {
  CART_GETTER__ORDER_LINES,
  CART_GETTER__TOTAL_ITEMS,
  CART_GETTER__LOGISTIC_DETAILS,
} from '../store/modules/cart/getters';
import { CART_ACTION__SHOW_ERROR } from '../store/modules/cart/actions';
import cartMutationTypes from '../store/modules/cart/mutationTypes';
import {http} from "../utils/http-requests";

import { VUE_URL__SHIPPING_METHOD } from '../_global-constants';

const { mapActions, mapMutations } = createNamespacedHelpers(CART_NAMESPACE);
const RESERVATION_KEY_NAME = 'Reservation';
const DELIVERY_KEY_NAME = 'Delivery';

export default {
  name: 'VueCart',

  computed: {
    ...mapGetters(CART_NAMESPACE, {
      orderLines: CART_GETTER__ORDER_LINES,
      logisticDetails: CART_GETTER__LOGISTIC_DETAILS,
      totalItems: CART_GETTER__TOTAL_ITEMS,
    }),
    ...mapGetters(FEATURE_NAMESPACE, {
      isSpolEnabled: getterTypes.IS_SPOL_ENABLED,
    }),
  },

  methods: {
    ...mapActions({
      showError: CART_ACTION__SHOW_ERROR,
    }),

    ...mapMutations({
      setCart: cartMutationTypes.CART_MUTATION__SET_CART,
    }),

    isDateSelected() {
      const savedLaterDate = sessionStorage.getItem('selectedLaterDate');
      const selectedDeliveryDate = document.querySelector('input[name="selectedDeliveryDate"]:checked');

      return savedLaterDate || selectedDeliveryDate;
    },

    async changeReservation(isReservation) {
      const reservationCheckbox = this.$el.querySelector('#reservation');
      const deliveryMethod = this.$el.querySelector('input[name="deliveryMethodKey"]');

      reservationCheckbox.checked = isReservation;

      let loader = this.$loading.show({
        active: true,
        container: this.$el,
      });
      let body;

      if (isReservation) {
        body = RESERVATION_KEY_NAME;
        deliveryMethod.value = RESERVATION_KEY_NAME;
      } else if (this.isDateSelected()) {
        body = DELIVERY_KEY_NAME;
        deliveryMethod.value = DELIVERY_KEY_NAME;
      }

      try {
        let response = await http.put(VUE_URL__SHIPPING_METHOD,body)

        this.setCart(response.cart);

        return response;
      }
      catch (e) {
        this.showError
      }
      finally {
        loader.hide();
      }
    },
  },

  render() {
    return this.$scopedSlots.default({
      orderLines: this.orderLines,
      totalItems: this.totalItems,
      logisticDetails: this.logisticDetails,
      changeReservation: this.changeReservation,
      isSpolEnabled: this.isSpolEnabled,
    });
  },
};
