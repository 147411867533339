<template>
  <vue-popup
    v-if="showModal"
    ref="modal"
    class="component--request-and-order-popup text-align-left"
    :class="{ 'request-and-notify-popup' : isRequestAndNotifyPopupActive }"
    :title="titleWithProductName"
    @close="$event => closeModal($event)"
  >
    <template #content>
      <p
        v-html="isRequestAndNotifyPopupActive ? translations.requestAndNotifyPopupText : translations.requestAndOrderPopupText"
        class="space-mt-5"
      ></p>

      <div
        class="bottom-items"
      >
        <div
          v-if="!isRequestAndNotifyPopupActive"
          class="form-group order-quantity"
        >
          <div class="form-label">
            <div class="is-help text-grey">
              {{
                translations.requestAndOrderPopupOrderButtonLabel
              }}
            </div>
            <vue-product-cart-icon
              :addedQuantity="addedQuantityData.addedQuantity"
              :isApproved="isRequestButtonApproved"
            />
          </div>
          <div class="wraper">
            <div class="form-input">
              <label class="has-float-label is-pos-initial">
                <vue-product-count-input
                  v-if="showModal"
                  :key="currentProduct.id"
                  inputClass="is-small"
                  :step="step"
                  :currentProduct="currentProduct"
                  :minValue="step"
                  :stockQuantity="availableQuantity"
                  :maxOrderQuantity="maxOrderQuantity"
                  :totalItemsInCart="totalItemsInCart"
                  :showErrors="true"
                  :stockQuantityErrorMessage="translations.stockQuantityErrorMessage"
                  :maxOrderQuantityErrorMessage="translations.maxOrderQuantityErrorMessage"
                  :cartContainsMaxLimitMessage="translations.cartContainsMaxLimitMessage"
                  :pattern="pattern"
                  :initialValue="maxPossibleOrder"
                  name="orderQuantity"
                  maxlength="3"
                  required
                  @has-error="disableOrderButton"
                />
                <div class="form-notifications">
                  <div class="is-help text-grey">{{
                    packagingDescription
                  }}</div>
                </div>
              </label>
            </div>
            <div class="form-addon">
              <button
                type="button"
                class="is-primary is-color-complementary is-small has-no-margin"
                name="cart"
                :disabled="isOrderButtonDisabled"
                :class="{ 'is-approved' : isOrderButtonApproved, 'is-loading' : isOrderButtonLoading }"
                @click="updateQuantity($event)"
              >
                <span class="is-state-default">{{
                  translations.requestAndOrderPopupOrderButtonText
                }}</span>
                <span class="is-state-loading"> </span>
                <span class="is-state-approved">{{
                  translations.buttonLabelAdded
                }}</span>
              </button>
            </div>
          </div>
        </div>

        <div
          class="form-group request-quantity"
        >
          <div class="form-label">
            <div class="is-help text-grey">
              {{
                translations.requestAndOrderPopupRequestButtonLabel
              }}
              <div
                class="hint__icon"
                @click="showHint"
              >
                <svg
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="#icon--notification--info"
                  ></use>
                </svg>
              </div>
            </div>
          </div>
          <div class="wraper">
            <div class="form-input">
              <label class="has-float-label is-pos-initial">
                <vue-product-count-input
                  v-if="showModal"
                  :key="currentProduct.id"
                  inputClass="is-small"
                  :step="step"
                  :minValue="step"
                  :maxOrderQuantity="2147483647"
                  :stockQuantity="2147483647"
                  :showErrors="true"
                  :pattern="pattern"
                  :initialValue="stockRequestInitialValue"
                  :currentProduct="currentProduct"
                  :disabled="isRequestFieldDisabled"
                  name="requestQuantity"
                  maxlength="3"
                  required
                  @change="requestNotifyValue()"
                />
                <div
                  v-if="isRequestAndNotifyPopupActive"
                  class="form-notifications"
                >
                  <div class="is-help text-grey">{{
                    packagingDescription
                  }}</div>
                </div>
              </label>
            </div>
            <div class="form-addon">
              <button
                type="button"
                class="is-primary is-small has-no-margin"
                name="cart"
                :disabled="isRequestButtonDisabled"
                :class="{
                  'is-approved' : isRequestButtonApproved,
                  'is-loading' : isRequestButtonLoading,
                  'is-color-complementary' : isRequestAndNotifyPopupActive
                }"
                @click="requestQuantity($event)"
              >
                <span class="is-state-default">{{
                  isRequestAndNotifyPopupActive
                    ? translations.requestAndNotifyPopupButtonLabel
                    : translations.requestAndOrderPopupRequestButtonText
                }}</span>
                <span class="is-state-loading"> </span>
                <span class="is-state-approved">{{
                  translations.buttonLabelAdded
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <vue-alert
        v-if="isHintOpened"
        :message="translations.requestAndOrderPopupRequestButtonHint"
        level="info"
        isActiveByDefault
        canClose
        @close="setHintStatus(false)"
      />

      <vue-alert
        v-if="isRequestSuccessfull"
        :message="alertSuccessMessage"
        level="success"
        isActiveByDefault
        canClose
        @close="closeSuccessfullAlert"
      />

      <vue-alert
        v-if="errors.length > 0"
        :message="translations.requestAndOrderPopupErrorMessage"
        level="error"
        isActiveByDefault
        canClose
        @close="closeErrorAlert"
      />
    </template>
  </vue-popup>
</template>

<script>
  import { createNamespacedHelpers, mapGetters } from 'vuex';
  import { fillPlaceholderWithValue } from '../utils';
  import { calculateQuantityWithMfa } from '../utils/calculate-quantity-with-mfa';
  import { CART_NAMESPACE } from '../store/modules/cart/index';
  import { CART_ACTION__ADD_TO_CART } from '../store/modules/cart/actions';
  import { PRODUCT_NAMESPACE } from '../store/modules/product';

  import { GTMEvents } from '../consts/gtm_events';
  import VueProductCountInput from './product-count-input.vue';
  import VueProductCartIcon from './product-cart-icon.vue';
  import VueAlert from './alert/alert.vue';
  import VuePopup from './popup.vue';

  const DISABLED_STATE_TIME = 2000;
  const ALERT_ACTIVE_TIME = 5000;
  const { mapActions: mapActionsCart } = createNamespacedHelpers(CART_NAMESPACE);
  const { mapActions: mapActionsProduct} = createNamespacedHelpers(PRODUCT_NAMESPACE);

  export default {
    name: 'VueRequestAndOrderPopup',

    components: { VuePopup, VueProductCountInput, VueAlert, VueProductCartIcon },

    inject: ['addedQuantityData'],

    props: {
      currentProduct: {
        type: Object,
        default: () => ({}),
      },

      isModalShown: {
        type: Boolean,
        default: false,
      },

      isCart: {
        type: Boolean,
        default: false,
      },

      requestedQuantity: {
        type: [String, Number],
        default: 0,
      },

      totalItemsInCart: {
        type: [String, Number],
        default: 0,
      },

      isRequestAndNotifyPopupActive: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        isRequestSuccessfull: false,
        errors: [],
        warnings: [],
        serverMessages: [],
        translations: defaultTranslations,
        stockRequestInitialValue: 0,
        isOrderButtonDisabled: false,
        isOrderButtonApproved: false,
        isOrderButtonLoading: false,
        isRequestButtonDisabled: false,
        isRequestFieldDisabled: false,
        isRequestButtonApproved: false,
        isRequestButtonLoading: false,
        alertSuccessMessage: '',
        successfulAlertTimeout: null,
      }
    },

    computed: {
      ...mapGetters(PRODUCT_NAMESPACE, ['isHintOpened']),

      showModal() {
        return this.isModalShown || this.isRequestAndNotifyPopupActive;
      },

      productDetail() {
        const { masterVariant, productVariantDetail } = this.currentProduct;

        return masterVariant || productVariantDetail;
      },

      titleWithProductName() {
        return this.isRequestAndNotifyPopupActive
          ? this.translations.requestAndNotifyPopupTitle
          : this.translations.requestAndOrderPopupTitle + ' ' + this.currentProduct.name;
      },

      maxQtyOrderable() {
        const { maxOrderQuantity, availableQuantity } = this.productDetail;
        let maxOrder = maxOrderQuantity != 0 ? maxOrderQuantity : availableQuantity;

        if (availableQuantity < maxOrder) {
          maxOrder = availableQuantity;
        }

        return maxOrder
      },

      maxPossibleOrder() {
        return this.maxQtyOrderable - this.totalItemsInCart;
      },

      step() {
        const { orderEntryRules } = this.productDetail;

        return orderEntryRules.step;
      },

      availableQuantity() {
        const { availableQuantity } = this.productDetail;

        return availableQuantity;
      },

      maxOrderQuantity() {
        const { maxOrderQuantity } = this.productDetail;

        return maxOrderQuantity;
      },

      pattern() {
        const { orderEntryRules } = this.productDetail;

        return orderEntryRules.pattern;
      },

      packagingDescription() {
        const { packagingDescription } = this.productDetail;

        return packagingDescription;
      },

      sku() {
        const { sku } = this.productDetail;

        return sku;
      },
    },

    mounted() {
      if (!this.isRequestAndNotifyPopupActive) {
        this.stockRequestInitial();
      } else {
        this.isRequestButtonDisabled = true;
      }
      document.body.appendChild(this.$refs.modal.$el);
    },

    methods: {
      ...mapActionsCart({ addCart: CART_ACTION__ADD_TO_CART }),
      ...mapActionsProduct(['requestStock', 'setHintStatus', 'addSubscription']),

      stockRequestInitial() {
        let totalQuantityRequested = Number(this.requestedQuantity);

        if (!this.isCart) {
          totalQuantityRequested += Number(this.totalItemsInCart);
        }

        const stockRequestQuantity = totalQuantityRequested - this.maxQtyOrderable;

        this.stockRequestInitialValue = this.getMfaQuantity(stockRequestQuantity);
      },

      closeModal() {
        if (this.isRequestAndNotifyPopupActive) {
          this.$emit('close-notify-popup');
        } else {
          this.$emit('hide-modal');
        }
        this.clearValidationState();
        this.setHintStatus(false);
        this.isRequestButtonDisabled = false;
        this.isRequestFieldDisabled = false;
      },

      closeSuccessfullAlert() {
        clearTimeout(this.successfulAlertTimeout);
        this.isRequestSuccessfull = false;
      },

      closeErrorAlert() {
        this.clearValidationState();
      },

      clearValidationState() {
        this.errors = [];
        this.warnings = [];
      },

      async requestQuantity() {
        clearTimeout(this.successfulAlertTimeout);
        this.isRequestSuccessfull = false;
        this.isRequestButtonDisabled = true;
        this.isRequestFieldDisabled = true;
        this.isRequestButtonLoading = true;

        const quantity = Number(document.querySelector('input[name="requestQuantity"]').value);
        const mfaQuantity = this.getMfaQuantity(quantity);
        const { proposedTradingPrice } = this.currentProduct;
        let request = {
          skuID: this.sku,
          quantity: mfaQuantity,
          ...(proposedTradingPrice && {'price': Number(proposedTradingPrice) }),
        };

        if (this.isRequestAndNotifyPopupActive) {
          const productComponent = this.$parent.$parent;

          try {
            await this.addSubscription(this.sku);

            productComponent.isSubscribedStockNotifications = true;
            productComponent.isInputAvailable = false;
            productComponent.isStockNotificationHidden = false;
          } catch (error) {
            this.errors.push('error');
            this.isRequestButtonDisabled = false;
            this.isRequestFieldDisabled = false;
            productComponent.isSubscribedStockNotificationsTransition = false;
            productComponent.isSubscribedStockNotifications = false;

            return;
          }

          productComponent.qty = mfaQuantity;
        }

        try {
          await this.requestStock(request);
        } catch (error) {
          this.errors.push('error');
          this.isRequestButtonDisabled = false;
          this.isRequestFieldDisabled = false;
          this.isRequestButtonLoading = false;
          this.isRequestButtonApproved = false;

          return;
        }

        this.alertSuccessMessage = fillPlaceholderWithValue(
          this.translations.requestAndOrderPopupSuccessMessageForRequest,
          mfaQuantity,
        );
        if (!this.isRequestAndNotifyPopupActive) {
          this.stockRequestInitialValue = 0;
          this.isRequestButtonApproved = true;
          this.isRequestButtonLoading = false;
        }
        this.isRequestSuccessfull = true;

        setTimeout(() => {
          if (!this.isRequestAndNotifyPopupActive) {
            this.isRequestButtonApproved = false;
          } else {
            this.isRequestButtonDisabled = false;
            this.isRequestFieldDisabled = false;
          }
        }, DISABLED_STATE_TIME);

        this.successfulAlertTimeout = setTimeout(() => {
          this.isRequestSuccessfull = false;
        }, ALERT_ACTIVE_TIME);

        if(this.isRequestAndNotifyPopupActive) {
          setTimeout(() => {
            this.closeModal();
          }, DISABLED_STATE_TIME);

          window.dataLayer.push({
            event: GTMEvents.OUT_OF_STOCK_REQUEST,
          });
        } else {
          window.BNS.addNonBlacklistedDataToDataLayer({
            'event': 'request-from-popup',
            'click_id': `request-from-request-popup-${this.currentProduct.id}`,
          });
        }
      },

      updateQuantity() {
        const productComponent = this.$parent.$parent;

        this.addToCart();

        if (!this.isCart) {
          productComponent.qty = 1;
        }
      },

      addToCart() {
        const productComponent = this.$parent.$parent;
        let quantity = Number(document.querySelector('input[name="orderQuantity"]').value);

        if (!quantity) {
          return;
        }

        if (productComponent.isTradingOffer && !productComponent.tradingPrice) {
          return;
        }

        clearTimeout(this.successfulAlertTimeout);
        this.isRequestSuccessfull = false;
        this.alertSuccessMessage = this.translations.requestAndOrderPopupSuccessMessageForOrder;
        this.isOrderButtonDisabled = true;
        this.isOrderButtonLoading = true;

        const productToAdd = {
          sku: this.sku,
          quantity,
          tradingPrice: productComponent.tradingPrice,
        };

        // add to the vuex store
        this.addCart(productToAdd)
          .then(this.addToCartCallback)
          .catch((error) => {
            this.errors.push('error');
            this.isOrderButtonDisabled = false;
            this.isOrderButtonLoading = false;
          });
      },

      addToCartCallback({ totalItemsOfAddedProductInCart }) {
        this.isOrderButtonApproved = true;
        this.addedQuantity = totalItemsOfAddedProductInCart;
        this.addedQuantityData.updateAddedQuantity(totalItemsOfAddedProductInCart);
        this.isOrderButtonLoading = false;
        this.isRequestSuccessfull = true;

        setTimeout(() => {
          this.isOrderButtonApproved = false;
          this.isTradingOpen = false;
          this.updateDisability();
        }, DISABLED_STATE_TIME);

        this.successfulAlertTimeout = setTimeout(() => {
          this.isRequestSuccessfull = false;
        }, ALERT_ACTIVE_TIME);
      },

      updateDisability() {
        const productComponent = this.$parent.$parent;
        const { availableQuantity, maxOrderQuantity } = this.productDetail;
        const qty = Number(document.querySelector('input[name="orderQuantity"]').value);
        const isQtyAbsent = qty === 0;
        const addedQty = Number(this.addedQuantity);
        const availableQtyNumber = Number(availableQuantity);
        const maxOrderQtyNumber = Number(maxOrderQuantity);
        const isQtyGreaterThanMaxOrderQty = (qty > maxOrderQtyNumber - addedQty) && maxOrderQuantity !== 0;
        const isQtyGreaterThanAvailableQuantity = addedQty > availableQtyNumber;
        const isNewQtyGreaterThanMaxOrderQty = (1 > maxOrderQtyNumber - addedQty) && maxOrderQuantity !== 0;
        const isNewQtyGreaterThanAvailableQuantity = 1 > availableQtyNumber - addedQty;

        this.isOrderButtonDisabled = (isQtyAbsent || isQtyGreaterThanMaxOrderQty || isQtyGreaterThanAvailableQuantity);

        productComponent.isDisabled = (isNewQtyGreaterThanMaxOrderQty || isNewQtyGreaterThanAvailableQuantity);
        if (productComponent.isOrderAndRequestAvailable) {
          productComponent.isRequestStockDisabled = productComponent.qty === 0;
        }
      },

      requestNotifyValue() {
        if (this.isRequestAndNotifyPopupActive) {
          const requestedQuantityElement = document.querySelector('input[name="requestQuantity"]');
          const requestQty = requestedQuantityElement?.value || 0;

          this.isRequestButtonDisabled = requestQty == 0;
        } else {
          return false
        }
      },

      showHint() {
        this.setHintStatus(true);
      },

      disableOrderButton(value) {
        this.isOrderButtonDisabled = false;

        if (value !== null) {
          this.isOrderButtonDisabled = true;
        }
      },

      disableRequestButton(value) {
        this.isRequestButtonDisabled = false;

        if (value !== null) {
          this.isRequestButtonDisabled = true;
        }
      },

      getMfaQuantity(quantity) {
        const { multiplicationFactor, orderUOM, priceUOM } = this.productDetail;

        return calculateQuantityWithMfa(
          quantity,
          multiplicationFactor,
          orderUOM,
          priceUOM,
        );
      },
    },
  };
</script>
