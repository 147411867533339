<!-- eslint-disable max-len -->
<template>
  <div
    class="clear-cart"
  >
    <span
      class="link"
      @click="showModal"
    >
      <svg
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 13 13"
      >
        <path
          d="M1.56923077,4.43076923 L1.56923077,9.81538462 C1.56923077,10.6653846 2.21513287,11.3538462 3.01258741,11.3538462 L8.06433566,11.3538462 C8.86179021,11.3538462 9.50769231,10.6653846 9.50769231,9.81538462 L9.50769231,4.43076923"
        ></path>
        <path
          d="M0,2.30769231 L11.0769231,2.30769231"
        ></path>
        <polyline
          points="3.69230769 2.11468531 3.69230769 0 7.38461538 0 7.38461538 2.11468531"
        ></polyline>
      </svg>
      {{ translations.clearCartButton }}
    </span>

    <vue-popup
      v-if="isModalShown"
      :title="translations.clearCartTitle"
      name="clearCartPopop"
      @close="$event => closeModal($event)"
    >
      <template #content>
        <p class="space-mb-5 space-mt-5">
          {{ translations.clearCartIntro }}
        </p>

        <div class="bottom-items">
          <button
            class="button is-primary is-color-complementary text-uppercase"
            @click="clearCartHandler"
          >
            {{ translations.clearCartYes }}
          </button>
          <button
            class="button is-primary text-uppercase"
            @click="closeModal"
          >
            {{ translations.clearCartNo }}
          </button>
        </div>
        <vue-alert
          v-if="error"
          :message="translations.clearCartGeneralError"
          level="error"
          isActiveByDefault
        />
      </template>
    </vue-popup>
  </div>
</template>

<script>
  import {
    mapGetters,
    createNamespacedHelpers,
  } from "vuex";
  import { TRANSLATIONS_NAMESPACE } from "../store/modules/translations";
  import { CART_NAMESPACE } from '../store/modules/cart/index';
  import {
    CART_ACTION__SHOW_ERROR,
    CART_ACTION__CLEAR_CART,
  } from '../store/modules/cart/actions'
  import VueAlert from '../components/alert/alert.vue';
  import { GTMEvents } from '../consts/gtm_events';
  import VuePopup from './../components/popup.vue';

  const { mapActions } = createNamespacedHelpers(CART_NAMESPACE);

  export default {
    name: 'VueClearCart',

    components: {
      VuePopup,
      VueAlert,
    },

    data() {
      return {
        isModalShown: false,
        isClearCartSuccessful: false,
        title: '',
        error: null,
      }
    },

    computed: {
      ...mapGetters(TRANSLATIONS_NAMESPACE, ['translations']),
    },

    methods: {
      ...mapActions({
        clearCart: CART_ACTION__CLEAR_CART,
        showError: CART_ACTION__SHOW_ERROR,
      }),

      showModal() {
        this.isModalShown = true;
      },

      closeModal($event) {
        if(this.isClearCartSuccessful) {
          $event.preventDefault();

          return window.location.reload();
        }
        this.error = null;
        this.isModalShown = false;
      },

      async clearCartHandler() {
        window.dataLayer.push({
          event: GTMEvents.CLEAR_CART_SUBMIT,
        });

        try {
          await this.clearCart();
          window.location.reload();
        } catch (error) {
          this.error = error;
          console.error('Failed while clearing cart', error);
        }
      },
    },

    render: function() {
      return this.$scopedSlots.default( {
        isModalShown: this.isModalShown,
        showModal: this.showModal,
        isClearCartSuccessful: this.isClearCartSuccessful,
      })
    },
  }
</script>
