import { DomLoadedCheck } from '../utils/dom-loaded-check';

const PRIVACY_CHECKBOX = 'input.my_addresses__privacy-checkbox';
const SUBMIT_BUTTON = '.my-addresses__submit-button';

function init() {
  let privacyInputs = document.querySelectorAll(PRIVACY_CHECKBOX);

  for (let item of privacyInputs) {
    if (!item) {return;}

    let submitButton = item.closest('form').querySelector(SUBMIT_BUTTON);

    item.addEventListener('click', () => {
      item.checked
        ? (submitButton.disabled = false)
        : (submitButton.disabled = true);
    });
  }
}

(() => {
  DomLoadedCheck(init);
})();
