import { createNamespacedHelpers, mapGetters } from 'vuex';

import { CART_NAMESPACE } from '../store/modules/cart/index';
import {
  CART_ACTION__UPDATE_LINE_ITEM,
  CART_ACTION__DELETE_FROM_CART,
  CART_ACTION__DISABLE_CHECKOUT,
  CART_ACTION__SHOW_ERROR,
} from '../store/modules/cart/actions';
import {
  TRANSLATIONS_NAMESPACE,
  getterTypes as translationGetterTypes,
} from '../store/modules/translations';
import {
  FEATURE_NAMESPACE,
  getterTypes,
} from '../store/modules/features';

import {
  VUE_EVENT_NAME__OPEN_ADD_TO_LIST,
  VUE_EVENT_NAME__ADD_PRODUCT_TO_LIST_CB,
  VUE_EVENT_NAME__UPDATE_DELIVERY_METHODS,
} from '../_global-constants';

import { addEventDialog } from './dialog';

const { mapActions } = createNamespacedHelpers(CART_NAMESPACE);
const INPUT_EVENT_DELAY = 1000;
const REGEX_INPUT_VALIDATION = /(,|\.)+$/;
const READONLY_ATTRIBUTE = 'readonly';
const READONLY_VALUE = 'readonly';

export default {
  name: 'VueOrderLine',

  render() {
    return this.$scopedSlots.default({
      requestStockDisabled: this.requestStockDisabled,
      updateTradePrice: this.updateTradePrice,
      updateQuantity: this.updateQuantity,
      deleteLineItem: this.deleteLineItem,
      moveItemToList: this.moveItemToList,
      requestQuantity: this.requestQuantity,
      isRationCustomerBoolean: this.isRationCustomerBoolean,
      availableQuantity: this.availableQuantity,
      lineItem: this.lineItem,
      isModalActive: this.isModalActive,
      showModal: this.showModal,
      isModalShown: this.isModalShown,
      hideModal: this.hideModal,
      addedQuantity: this.addedQuantity,
      isAlertShown: this.isAlertShown,
      onAlertClose: this.onAlertClose,
      disabledRequest: this.disabledRequest,
      areRationPointsShown: this.areRationPointsShown,
      rationPointsText: this.rationPointsText,
    });
  },

  props: {
    orderLine: {
      required: true,
    },

    isRationCustomer: {
      type: [String, Boolean],
      default: true,
    },

    isModalActive: {
      type: Boolean,
      default: false,
    },
  },

  provide() {
    const addedQuantityData = {
      updateAddedQuantity: this.updateAddedQuantity,
    }

    Object.defineProperty(addedQuantityData, 'addedQuantity', {
      enumerable: true,
      get: () => this.addedQuantity,
    })

    return {
      addedQuantityData,
    }
  },

  data() {
    return {
      lineItem: null,
      tradingPrice: null,
      quantity: null,
      isRationCustomerBoolean: String(this.isRationCustomer) !== "false",
      isModalShown: false,
      addedQuantity: 0,
      isAlertShown: false,
      requestStockDisabled: false,
    };
  },

  created() {
    this.lineItem = this.orderLine;
    this.tradingPrice = this.lineItem.tradingPrice;
    this.quantity = this.lineItem.quantity;
    this.addedQuantity = this.lineItem.quantity;
  },

  mounted() {
    let dialogTriggers = this.$el.querySelectorAll(
      '.show-dialog-' + this.lineItem.productId,
    );

    addEventDialog(dialogTriggers);
  },

  methods: {
    ...mapActions({
      updateLineItem: CART_ACTION__UPDATE_LINE_ITEM,
      deleteFromCart: CART_ACTION__DELETE_FROM_CART,
      showError: CART_ACTION__SHOW_ERROR,
      disableCartCheckout: CART_ACTION__DISABLE_CHECKOUT,
    }),

    showModal() {
      clearTimeout(this.timeoutQuantity);
      this.isModalShown = true;
    },

    hideModal() {
      this.isModalShown = false;
    },

    onAlertClose() {
      this.isAlertShown = false;
    },

    updateAddedQuantity(totalItemsOfAddedProductInCart) {
      this.addedQuantity = totalItemsOfAddedProductInCart;
    },

    // Used for Thymeleaf template key 'global.left-in-stock.vue'
    availableQuantity() {
      return this.lineItem.productVariantDetail.availableQuantity;
    },

    disabledRequest(status = true, lineItem = null) {
      this.requestStockDisabled = status

      if (lineItem) {
        this.disableCheckout(lineItem.id, status)
      }
    },

    disableCheckout(lineItemId, status) {
      this.disableCartCheckout({ lineItemId, status })
    },

    generateUpdateLineItemData(lineItem) {
      let data = {
        lineId: lineItem.id,
        sku: lineItem.productVariantDetail.sku,
        quantity: this.quantity,
      };

      if (this.lineItem.productVariantDetail.isTradeAllowed) {
        data.tradingPrice = this.tradingPrice;
      }

      return data;
    },

    updateQuantity({ target }, lineItem, endLoadingCallback = () => {}) {
      clearTimeout(this.timeoutQuantity);

      this.timeoutQuantity = setTimeout(async () => {
        this.quantity = target.value;

        if (target.name === 'requestedQty') {
          this.quantity = target.value.replace(',', '.');
        }

        if (this.quantity === '0') {
          return await this.deleteLineItem(lineItem);
        }

        if (!this.quantity || this.quantity === '') {
          return;
        }

        if (REGEX_INPUT_VALIDATION.test(this.quantity)) {
          return;
        }

        if (!target.checkValidity()) {
          return;
        }

        // Enable read-only mode of input field to prevent weird things
        target.readOnly = true;

        let sendingData = this.generateUpdateLineItemData(lineItem);
        let loader = this.$loading.show({
          active: true,
          container: this.$el,
        });

        target.setAttribute(READONLY_ATTRIBUTE, READONLY_VALUE);

        try {
          const result = await this.updateLineItem(sendingData);

          this.updateAddedQuantity(result.totalItemsOfAddedProductInCart)
          endLoadingCallback(true, result);
        } catch (error) {
          const response = error.response.bodyUsed
            ? error.response.clone()
            : error.response;

          if (response.status === 417) {
            try {
              const {level, message} = await response.json();

              this.lineItem.messages = [{
                level,
                content: message,
                id: new Date().getTime(),
              }];
              this.isAlertShown = true;
            } catch (jsonParsingError) {
              console.error(jsonParsingError);
            }
            endLoadingCallback(false);

            return true;
          } else {
            endLoadingCallback(false);
            this.showError(error);
          }
        } finally {
          target.removeAttribute(READONLY_ATTRIBUTE);
          this.$root.$emit(VUE_EVENT_NAME__UPDATE_DELIVERY_METHODS);
          loader.hide();
        }
      }, INPUT_EVENT_DELAY);
    },

    updateTradePrice(payload, lineItem) {
      const { event, value } = payload

      this.disabledRequest(false)

      this.tradingPrice = value;

      if (!this.tradingPrice || this.tradingPrice === '') {return;}
      if (REGEX_INPUT_VALIDATION.test(this.tradingPrice)) {return;}

      let sendingData = this.generateUpdateLineItemData(lineItem);
      let loader = this.$loading.show({
        active: true,
        container: this.$el,
      });

      event.target.setAttribute(READONLY_ATTRIBUTE, READONLY_VALUE);

      return this.updateLineItem(sendingData)
        .catch(error => {
          let response = error.response.bodyUsed
            ? error.response.clone()
            : error.response;

          if (response.status === 417) {
            response
              .json()
              .then(e => {
                this.lineItem.messages = [{
                  level: e.level,
                  content: e.message,
                  id: new Date().getTime(),
                }];
                this.isAlertShown = true;

                return true;
              })
              .catch(e => {
                console.error(e);

                return true;
              });
          } else {
            return this.showError(error);
          }
        })
        .finally(() => {
          event.target.removeAttribute(READONLY_ATTRIBUTE);
          loader.hide();
        });
    },

    moveItemToList(lineItem) {
      this.$root.$once(VUE_EVENT_NAME__ADD_PRODUCT_TO_LIST_CB, () => {
        this.deleteLineItem(lineItem);
      });

      setTimeout(() => {
        this.$root.$emit(VUE_EVENT_NAME__OPEN_ADD_TO_LIST, lineItem.productId);
      }, 0);
    },

    async deleteLineItem(lineItem) {
      let loader = this.$loading.show({
        active: true,
        container: this.$el,
      });

      try {
        await this.deleteFromCart({ lineId: lineItem.id })
      } catch(error) {
        this.showError(error);
      } finally {
        this.$root.$emit(VUE_EVENT_NAME__UPDATE_DELIVERY_METHODS);
        loader.hide();
      }
    },
  },

  computed: {
    ...mapGetters(TRANSLATIONS_NAMESPACE, {
      translations: translationGetterTypes.TRANSLATIONS,
    }),

    ...mapGetters(FEATURE_NAMESPACE, {
      isRationsV2Enabled: getterTypes.IS_RATIONS_V2_ENABLED,
    }),

    areRationPointsShown() {
      return this.lineItem.productRationPoints
      && this.isRationCustomerBoolean
      && this.isRationsV2Enabled;
    },

    rationPointsText() {
      return `${this.lineItem.productRationPoints} ${this.translations.rationPoints}`
    },
  },
};
