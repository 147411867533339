<template>
  <div class="wrapper">
    <div
      :class="['category-list', isLinkDarkUi ? 'link-ui-dark' : 'link-ui-grey']"
    >
      <aside>
        <label
          class="toggle--category-navigation is-toggle-label is-closing"
          for="close-button"
        >
          closeLabel
        </label>
      </aside>
      <ol
        ref="categoryListContainerRef"
        name="mainList"
        :class="[
          'is-unstyled has-no-indent has-no-margin',
          {
            'category-list__container': hasSubItems,
            'is-active-list': isActiveList,
            'overflow-hidden': isOverFlowHidden
          }
        ]"
      >
        <li class="is-hidden-desktop">
          <label
            v-text="BACK_LABEL"
            class="qa-back-btn is-link is-color-secondary arrow-left"
            for="close-button"
            :title="BACK_LABEL"
            @click="backBtnHandler"
          >global.back.label</label>
        </li>

        <template v-if="menu">
          <li
            v-for="(menuItem, index) in childMenuItems"
            :key="menuItem.name"
            class="category-list__item"
            @click.stop="clItemClickHandler(menuItem)"
          >
            <a
              v-if="isListEmpty(menuItem.hstSiteMenuItems)"
              v-text="menuItem.name"
              :title="menuItem.name"
              :href="getLink(menuItem)"
              :class="[
                'qa-navigate-to-category-link category-list__item--link',
                {
                  'is-active': selectedActiveItem == menuItem.name,
                  'is-current-page':
                    isListEmpty(menuItem.hstSiteMenuItems) &&
                    (menuItem.selected || menuItem.expanded)
                }
              ]"
            >
              menuItem.name in categoryList
            </a>
            <a
              v-else
              v-text="menuItem.name"
              :title="menuItem.name"
              :class="[
                'category-list__item--link',
                {
                  'is-active': selectedActiveItem == menuItem.name,
                  'has-children': !isListEmpty(menuItem.hstSiteMenuItems)
                }
              ]"
            >
              menuItem.name in categoryList
            </a>
            <ol
              v-if="!isListEmpty(menuItem.hstSiteMenuItems)"
              name="subList"
              :style="{ top: topSpacing(index) }"
              :class="[
                'is-unstyled has-no-indent has-no-margin',
                {
                  'category-list__sub-item-container': hasSubItems,
                  'is-collapsed': selectedActiveItem != menuItem.name
                }
              ]"
            >
              <li class="is-hidden-desktop">
                <a
                  v-text="BACK_LABEL"
                  :title="BACK_LABEL"
                  class="qa-back-btn is-link is-color-secondary arrow-left"
                  @click.stop="backBtnHandler(true)"
                >global.back.label</a>
              </li>
              <li
                v-if="hasHeadingItem"
                :class="{ 'category-list__sub-item--heading': hasSubItems }"
              >
                <span
                  v-text="menuItem.name"
                  class="font-weight-bold"
                ></span>
              </li>

              <li
                v-for="childMenuItem in menuItem.hstSiteMenuItems"
                :key="childMenuItem.name"
                :class="{ 'category-list__sub-item': hasSubItems }"
              >
                <a
                  v-text="childMenuItem.name"
                  :title="childMenuItem.name"
                  :href="getLink(childMenuItem)"
                  :class="[
                    'qa-navigate-to-category-link',
                    {
                      'category-list__sub-item--link': hasSubItems,
                      'is-current-page':
                        childMenuItem.hstSiteMapItemPath != null &&
                        (childMenuItem.selected || childMenuItem.expanded)
                    }
                  ]"
                >
                  childMenuItem.name in categoryList
                </a>
              </li>
            </ol>
          </li>
        </template>
      </ol>
    </div>
  </div>
</template>

<script>
  import { validateString } from '../../utils';

  const BACK_LABEL = defaultTranslations.backLabel;

  export default {
    name: 'VueCategoryNavigation',
    props: {
      menuData: {
        type: String,
        default: '',
        required: true,
      },
      isLinkDarkUi: {
        type: Boolean,
        default: false,
      },
      hasHeadingItem: {
        type: Boolean,
        default: false,
      },
      hasSubItems: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedActiveItem: '',
        isActiveList: false,
        isOverFlowHidden: false,
      };
    },
    computed: {
      menu() {
        return this.menuData && JSON.parse(this.menuData);
      },
      childMenuItems() {
        return this.menu?.hstSiteMenuItem?.[0].hstSiteMenuItems ?? [];
      },
    },
    created() {
      this.BACK_LABEL = BACK_LABEL;
    },
    methods: {
      isListEmpty(list) {
        return list?.length <= 0;
      },
      topSpacing(index) {
        return (-55 - (47 * index ))+ 'px';
      },
      getLink(item) {
        const { hstSiteMapItemPath, path, externalLink, channelDetail } = item;
        const catUrl =
          validateString(hstSiteMapItemPath) ??
          validateString(path) ??
          validateString(externalLink) ??
          '';

        if (!channelDetail) {
          return catUrl;
        }

        return channelDetail.url + '/' + catUrl;
      },

      backBtnHandler(hasParent) {
        if (hasParent) {
          this.selectedActiveItem = '';
          this.isActiveList = false;
          this.isOverFlowHidden = false;
        } else {
          const CATEGORY_NAVIGATION_TOGGLE_ID = 'category-navigation';

          document.getElementById(CATEGORY_NAVIGATION_TOGGLE_ID).click();
        }
      },

      clItemClickHandler(menuItem) {
        this.selectedActiveItem = menuItem.name;
        this.isActiveList = true;
        this.isOverFlowHidden = true;
        this.$refs.categoryListContainerRef.scrollTop = 0;
      },
    },
  };
</script>

