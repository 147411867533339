<template>
  <div
    class="search-bar-autosuggest"
    :class="[dropdownExpanded ? 'dropdown-expanded' : 'collapsed']"
  >
    <div
      v-click-outside="onClickOutside"
      class="search-bar"
    >
      <div
        :aria-expanded="dropdownExpanded"
        aria-haspopup="listbox"
        aria-owns="search-bar__dropdown"
        class="search-bar__input"
      >
        <input
          id="search-input"
          autocomplete="off"
          aria-autocomplete="list"
          aria-activedescendant
          aria-controls="search-bar__dropdown"
          :placeholder="placeholder"
          type="search"
          class="search-input"
          @focus="onInputFocus"
          @input="onInputChange($event.target.value)"
          @keyup.enter.prevent="executeSearch"
        />
      </div>
      <vue-barcode-scanner isInnerSearch />
      <div
        v-show="dropdownExpanded"
        id="search-bar__dropdown"
        class="search-bar__dropdown"
      >
        <div class="search-bar__dropdown--left">
          <div
            aria-labelledby="autosuggest"
            class="search-results"
          >
            <div
              v-for="suggestion in suggestions"
              :key="suggestion.name"
              class="result-section"
              :class="suggestion.class"
            >
              <div class="result-section__heading is-flex">
                <div class="title">
                  {{ suggestion.title }}
                </div>
              </div>
              <div class="result-section__content">
                <ul role="listbox">
                  <li
                    v-for="(item, index) in suggestion.data"
                    :id="`autosuggest__results-item--${index}`"
                    :key="item.id"
                    role="option"
                    :data-suggestion-index="index"
                    :data-section-name="suggestion.name"
                  >
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <a
                      v-html="item.name"
                      :href="item.url"
                      @click.prevent="suggestion.dataEvents.click(item)"
                    >
                      query
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer-action">
              <button
                class="button--primary"
                @click.prevent="executeSearch"
              >
                {{ translations.allSearchResults }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { escapeRegex } from '../utils/regexp-utils';
  import { TRANSLATIONS_NAMESPACE } from '../store/modules/translations';
  import { PRODUCT_NAMESPACE } from '../store/modules/product';
  import {
    HAS_DO_NOT_TRACK_DISABLED,
    VUE_URL__AUTO_SUGGEST,
    USER_CANCELLED_REQUEST_CODE,
  } from '../_global-constants';
  import { GTMEvents } from '../consts/gtm_events';
  import VueBarcodeScanner from './barcode/barcode-scanner/barcode-scanner.vue';

  const PRODUCTS_LIMIT = 12;
  const QUERIES_LIMIT = 2;
  const generateHighlightText = entry => {
    let highlightText = '';

    if (entry.name) {
      highlightText += entry.name;
    }
    if (entry.weight) {
      highlightText += ` | ${entry.weight}`;
    }
    if (entry.alcoholPercentage) {
      highlightText += ` | ${entry.alcoholPercentage}%`;
    }

    return highlightText;
  };
  const highlightText = (haystack, needle) => {
    const found = RegExp(`(${escapeRegex(needle)})`, 'gi');

    return haystack.replace(found, `<span>$1</span>`);
  };

  export default {
    name: 'VueProductAutosuggest',
    components: {
      VueBarcodeScanner,
    },
    props: {
      searchUrl: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        searchText: '',
        timeout: null,
        suggestionFetchController: null,
        debounceMilliseconds: 500,
        suggestions: [],
        dropdownVisible: false,
      };
    },
    computed: {
      ...mapGetters(TRANSLATIONS_NAMESPACE, ['translations']),
      dropdownExpanded() {
        return this.dropdownVisible && this.suggestions.length > 0;
      },
    },
    methods: {
      onClickOutside() {
        this.dropdownVisible = false;
      },
      onInputFocus() {
        this.dropdownVisible = true;
      },
      onInputChange(text) {
        if (Boolean(this.suggestionFetchController)) {
          this.suggestionFetchController.abort();
        }

        this.suggestions = [];
        this.searchText = text;

        if (text === null || text.length < 3) {
          return;
        }

        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.getSuggestions(text);
        }, this.debounceMilliseconds);
      },
      onSelected(selected) {
        if (selected) {
          if (HAS_DO_NOT_TRACK_DISABLED && window.dataLayer) {
            //push to Bloomreach pixel
            window.dataLayer.push({
              event: GTMEvents.SEARCH_SUGGESTIONS, // Bloomreach
              q: selected.name.replace(/<\/?[^>]*>/, ''), // Bloomreach
              aq: this.searchText, // Bloomreach
            });
          }

          window.location.href = selected.url;
        }
      },
      executeSearch() {
        const queryText = this.searchText ?? '';

        if (HAS_DO_NOT_TRACK_DISABLED && window.dataLayer) {
          //push to Bloomreach pixel
          window.dataLayer.push({
            event: GTMEvents.SEARCH_SUBMIT, // Bloomreach
            q: queryText.trim(), // Bloomreach
          });
        }

        // TODO more query param to html?
        window.location.href =
          this.searchUrl + '?query=' + encodeURIComponent(queryText.trim());
      },
      getSuggestions(text) {
        let url = VUE_URL__AUTO_SUGGEST + '?q=' + encodeURIComponent(text);

        this.suggestionFetchController = new AbortController();

        return this.fetchSuggestions({
          url,
          signal: this.suggestionFetchController.signal,
        })
          .then(this.transformResponse.bind(this, text))
          .then(suggestions => {
            this.suggestions = suggestions;
          })
          .catch(this.transformErrorResponse.bind(this))
          .finally(() => {
            this.suggestionFetchController = null;
          });
      },
      transformResponse(text, response) {
        let suggestions = [];
        let productsSuggestions = [];
        let queriesSuggestions = [];

        response.productsSuggestions.splice(0, PRODUCTS_LIMIT).forEach(entry => {
          entry.name = highlightText(generateHighlightText(entry), text);
          productsSuggestions.push(entry);
        });

        response.querySuggestions.splice(0, QUERIES_LIMIT).forEach(entry => {
          entry.name = highlightText(entry.name, text);
          queriesSuggestions.push(entry);
        });

        queriesSuggestions.length
          && suggestions.push({
            name: 'queries',
            title: this.translations.didYouMean,
            class: 'query-suggestion',
            position: 'left',
            data: queriesSuggestions,
            dataEvents: {
              click: this.onSelected.bind(this),
            },
          });

        productsSuggestions.length
          && suggestions.push({
            name: 'products',
            title: this.translations.productsFound,
            position: 'left',
            data: productsSuggestions,
            dataEvents: {
              click: this.onSelected.bind(this),
            },
          });

        return suggestions;
      },
      transformErrorResponse(error) {
        if (error.code === USER_CANCELLED_REQUEST_CODE) {
          return;
        }
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(errResp.message);

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(error.message || error);

        notification.show();

        return;
      },
      ...mapActions(PRODUCT_NAMESPACE, ['fetchSuggestions']),
    },
  };
</script>
