import { DomLoadedCheck } from '../utils/dom-loaded-check';

const IS_CLOSE_CLASS = '.target-desination__notification-is-close';
const TARGET_DESTINATION_NOTIFICATION_CONTAINER_CLASS =
  '.target-desination__notification';
const INPUT_HAMBURGER_CLASS = 'input.toggle--hamburger.is-toggle-trigger';
const TARGET_DESTINATION_TRIGGER_CLASS_OUTSIDE_OF_MAIN_NAVIGATION =
  '.toggle--target-destination.show-dialog.is-not-main-navigation';
const CLOSE_TARGET_DESTINATION_CLASS = '.dialog__close-icon.is-cross';
const GOING_BACK_CLASS = '.toggle--target-destination.is-going-back';
const CLOSE_HAMBURGER = 'is-close-hamburger';
const CLOSE_HAMBURGER_CLASS = `${CLOSE_TARGET_DESTINATION_CLASS}.${CLOSE_HAMBURGER}`;
const GOING_BACK_HAMBURGER_CLASS = `${GOING_BACK_CLASS}.${CLOSE_HAMBURGER}`;
const PAGE_ORDER_CONFIRMATION_CLASS = '.page--order-confirmation';
const TARGET_DESTINATION_FORM_CLASS = '.target-destination-form';
let inputHamburgerSelector;

function createHiddenInputField() {
  var input = document.createElement('input');

  input.setAttribute('type', 'hidden');
  input.setAttribute('name', 'successPageId');
  input.setAttribute('value', 'root');

  return input;
}

function openHamburgerMenu() {
  if (!inputHamburgerSelector.checked) {
    return true;
  }
}

function closeHamburgerMenuAfterClickAndResetClass(selector) {
  selector.addEventListener('click', () => {
    if (!selector.classList.contains(CLOSE_HAMBURGER)) {return;}
    const IS_CLOSE_HAMBURGER_SELECTOR = document.querySelectorAll(
      `.${CLOSE_HAMBURGER}`,
    );

    if (inputHamburgerSelector) {
      inputHamburgerSelector.checked = false;
    }

    [].forEach.call(IS_CLOSE_HAMBURGER_SELECTOR, item => {
      item.classList.remove(CLOSE_HAMBURGER);
    });
  });
}

function addEventMobileTargetDestination(
  targetDestinationTriggerElementsOutsideMainNavigation,
) {
  /**Toggle target destination for mobile*/
  const closeTargetDestinationSelector = document.querySelector(
    CLOSE_TARGET_DESTINATION_CLASS,
  );
  const goingBackSelector = document.querySelector(GOING_BACK_CLASS);

  [].forEach.call(
    targetDestinationTriggerElementsOutsideMainNavigation,
    toggleTargetDestinationElement => {
      toggleTargetDestinationElement.addEventListener('click', e => {
        e.stopPropagation();
        if (inputHamburgerSelector) {
          inputHamburgerSelector.checked = openHamburgerMenu();
        }

        if (
          !closeTargetDestinationSelector.classList.contains(CLOSE_HAMBURGER)
          && !goingBackSelector.classList.contains(CLOSE_HAMBURGER)
        ) {
          closeTargetDestinationSelector.classList.add(CLOSE_HAMBURGER);
          goingBackSelector.classList.add(CLOSE_HAMBURGER);

          const closButtoneHamburgerWithAddedClass = document.querySelector(
            CLOSE_HAMBURGER_CLASS,
          );
          const backButtoneHamburgerWithAddedClass = document.querySelector(
            GOING_BACK_HAMBURGER_CLASS,
          );

          closeHamburgerMenuAfterClickAndResetClass(
            closButtoneHamburgerWithAddedClass,
          );
          closeHamburgerMenuAfterClickAndResetClass(
            backButtoneHamburgerWithAddedClass,
          );
        }
      });
    },
  );
}

function init() {
  const isCloseIconSelectorTargetDestinationNotification = document.querySelector(
    IS_CLOSE_CLASS,
  );
  const targetDestinationContainerSelector = document.querySelector(
    TARGET_DESTINATION_NOTIFICATION_CONTAINER_CLASS,
  );
  const targetDestinationTriggerElementsOutsideMainNavigation = document.querySelectorAll(
    TARGET_DESTINATION_TRIGGER_CLASS_OUTSIDE_OF_MAIN_NAVIGATION,
  );
  const pageOrderConfirmationQuerySelector = document.querySelector(
    PAGE_ORDER_CONFIRMATION_CLASS,
  );
  const targetDestinationFormSelector = document.querySelectorAll(
    TARGET_DESTINATION_FORM_CLASS,
  );

  inputHamburgerSelector = document.querySelector(INPUT_HAMBURGER_CLASS);

  if (!targetDestinationContainerSelector) {return;}

  if (pageOrderConfirmationQuerySelector) {
    [].forEach.call(targetDestinationFormSelector, element => {
      const hiddenInput = createHiddenInputField();

      element.appendChild(hiddenInput);
    });
  }

  addEventMobileTargetDestination(
    targetDestinationTriggerElementsOutsideMainNavigation,
  );

  /** Close the mobile notification for target destination*/
  isCloseIconSelectorTargetDestinationNotification.addEventListener(
    'click',
    () => {
      if (!targetDestinationContainerSelector.classList.contains('is-hidden')) {
        targetDestinationContainerSelector.classList.add('is-hidden');
      }
    },
  );
}

(() => {
  DomLoadedCheck(init);
})();

export { addEventMobileTargetDestination };
