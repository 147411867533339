<template>
  <span class="component--order-bulk-upload">
    <a class="is-color-primary">
      <svg
        class="icon-link"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
      >
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#icon--upload"
        ></use>
      </svg>
      <span
        class="link"
        @click="showModal"
      >
        {{ translations.bulkUploadTitle }}
      </span>
    </a>

    <vue-popup
      v-if="isModalShown"
      :title="translations.bulkUploadTitle"
      @close="$event => closeModal($event)"
    >
      <template #content>
        <template v-if="!isUploadSuccessfull">
          <p class="space-mt-5">
            {{ translations.bulkUploadIntro }}
          </p>

          <p class="font-small-body-copy text-grey space-mb-3">
            {{ translations.bulkUploadInfo }}
          </p>

          <vue-alert
            v-if="errors.length > 0"
            :message="errors"
            level="error"
            isActiveByDefault
            canClose
          />

          <vue-alert
            v-if="warnings.length > 0"
            :message="warnings"
            level="warning"
            isActiveByDefault
            canClose
          />

          <vue-file-input
            :uploadButtonValue="translations.bulkUploadButtonUpload"
            name="bulk-file-upload"
            acceptedFiles="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            required
            fizeSizeInBytes
            :maxFileSize="bulkUploadFileMaxUploadSize"
            :isLoadingState="isLoading"
            @remove="removeFile"
            @change="onUpload"
          />

          <div class="bottom-items flex-column">
            <button
              class="button is-primary has-no-margin text-uppercase"
              :disabled="!isFileValid || isSendingFile"
              @click="onNextClick"
            >
              {{ translations.bulkUploadButtonNext }}
            </button>
          </div>
        </template>
        <template v-else>
          <div v-if="serverMessages.length">
            <p
              v-for="(message, index) in serverMessages"
              :key="index"
              class="space-mt-5 successfull-upload-label"
            >
              {{ message }}
            </p>
          </div>
          <button
            class="button is-primary has-no-margin text-uppercase space-mt-3"
            @click="$event => closeModal($event)"
          >
            {{ translations.bulkUploadGoToCart }}
          </button>
        </template>
      </template>
    </vue-popup>
  </span>
</template>

<script>
  import XLSX from 'xlsx';
  import { mapActions, mapGetters } from 'vuex';
  import { BULK_UPLOAD_NAMESPACE } from '../store/modules/bulk-upload';
  import { TRANSLATIONS_NAMESPACE } from '../store/modules/translations';
  import VuePopup from './popup.vue';
  import VueFileInput from './file-input/file-input.vue';
  import VueAlert from './alert/alert.vue';

  const getLastColIndex = (colLabel) => {
    const COLUMN_HORIZANTAL = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

    return COLUMN_HORIZANTAL.findIndex(col => col === colLabel) + 1
  }

  export default {
    name: "VueOrderBulkUpload",

    components: { VuePopup, VueFileInput, VueAlert },
    props: {
      bulkUploadFileMaxUploadSize: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        isModalShown: false,
        isUploadSuccessfull: false,
        isFileValid: false,
        file: null,
        errors: [],
        warnings: [],
        isLoading: false,
        parsedFile: null,
        serverMessages: [],
        isSendingFile: false,
      }
    },

    computed: {
      ...mapGetters(BULK_UPLOAD_NAMESPACE, ['templateConfig']),
      ...mapGetters(TRANSLATIONS_NAMESPACE, ['translations']),
    },

    mounted() {
      this.getTemplateConfig();
    },

    methods: {
      ...mapActions(BULK_UPLOAD_NAMESPACE, [
        'getTemplateConfig',
        'sendValidationRequest',
        'sendValidData',
      ]),

      showModal() {
        this.isModalShown = true;
      },

      closeModal($event) {
        if(this.isUploadSuccessfull) {
          $event.preventDefault();

          return window.location.reload();
        }

        this.removeFile();
        this.isModalShown = false;
        this.isUploadSuccessfull = false;
        this.isFileValid = false;
      },

      clearValidationState() {
        this.errors = [];
        this.warnings = [];
        this.isFileValid = false;
      },

      isValidNumber(value) {
        return Number.isInteger(value) && value > 0;
      },

      isValidPrice(value) {
        if (value === null) {
          return true;
        }

        return value.trim() === '' || Number(value) > 0;
      },

      checkForSeparators(value) {
        return value.includes('.', ',')
          ? null
          : Number(value)
      },

      // the functionality below works for REGULAR and TRADING users.
      // TRADING users have additional specialPrice column
      onUpload(files) {
        this.clearValidationState();
        this.isFileValid = false;
        this.file = files[0];

        if (!this.file) {
          return;
        }

        this.isLoading = true;
        const reader = new FileReader();

        reader.onload = (e) => {
          const { target } = e;

          try {
            if (!this.templateConfig) {
              return this.errors.push(this.translations.bulkUploadMessageGeneralError);
            }

            const parsedXlsxFile = this.getParsedXlsxFile(target.result);

            if (parsedXlsxFile && !this.errors.length) {
              this.validateFileOnServer(parsedXlsxFile);
            }
          } catch (error) {
            this.errors.push(this.translations.bulkUploadMessageFileError);
          } finally {
            this.isLoading = false;
          }
        }

        reader.readAsBinaryString(this.file);
      },

      collectConfigValues(parsedValues) {
        // define config of the spreadsheet with the BE config
        // below are the titles of config properties
        const sku = 'sku';
        const bottleQuantity = 'bottleQuantity';
        const specialPrice = 'specialPrice';

        return {
          SKU_TITLE: parsedValues[this.returnCellValue(sku)]?.v,
          QTY_TITLE: parsedValues[this.returnCellValue(bottleQuantity)]?.v,
          SPECIAL_PRICE_TITLE: parsedValues[this.returnCellValue(specialPrice)]?.v,
          LINES_HEADER_WHITESPACE: this.templateConfig.startRow,
        };
      },

      returnCellValue(configTitle) {
        if (!this.templateConfig.columnMappings[configTitle]) {
          return null;
        }

        // minus one is because config starts with "one"
        // and library works with array and starts with "zero"
        return XLSX.utils.encode_cell({
          c: this.templateConfig.columnMappings[configTitle] - 1,
          r: this.templateConfig.headerRow - 1,
        });
      },

      validValuesSeparator(data, separationItem, validatorCallback) {
        return data.reduce((result, item) => {
          validatorCallback(item[separationItem])
            ? result[1].push(item)
            : result[0].push(item);

          return result;

        }, [[],[]])
      },

      createErrorMessages(validData, preparedDataWithQty, type) {
        if (!validData.length) {
          return;
        }

        const lineNumbers = validData.map(item => item.lineIndex);
        let errorMessage = '';
        let errorText;
        let errorLimitText;

        if (type === 'quantity') {
          errorText = this.translations.bulkUploadMessageQuantityError;
          errorLimitText = this.translations.bulkUploadMessageQuantityErrorLimit;
        } else {
          errorText = this.translations.bulkUploadMessagePriceError;
          errorLimitText = this.translations.bulkUploadMessagePriceErrorLimit;
        }

        if (lineNumbers.length > 5) {
          const trimmedData = lineNumbers.slice(0, 5);

          errorMessage = errorLimitText.replace('{0}', trimmedData.join(', '));
        } else {
          errorMessage = errorText.replace('{0}', lineNumbers.join(', '));
        }

        if (type === 'price') {
          return this.warnings.push(errorMessage);
        }

        if (validData.length < preparedDataWithQty.length && type === 'quantity') {
          this.warnings.push(errorMessage);
        } else {
          return this.errors.push(errorMessage);
        }
      },

      getParsedXlsxFile(initialFile) {
        // The values below are received with the xlsx library
        const parsedFile = XLSX.read(initialFile, { type: 'binary', cellHTML: false });
        const fileName = parsedFile.SheetNames[0];
        const parsedValues = parsedFile.Sheets[fileName];
        const configValues = this.collectConfigValues(parsedValues);
        // "range" is added to skip first lines of the file with the decoration header
        const data = XLSX.utils.sheet_to_json(
          parsedValues,
          {
            defval: undefined,
            range: this.templateConfig.headerRow - 1,
            raw: false,
          },
        );
        // Check if the no of column matches the config
        // Get alphabetically ordered Columns
        const parsedKeys = Object.keys(parsedValues)
          ?.filter(key => key[0] != '!') // Remove Meta Values
          ?.map(value => value[0]) // Get only the Columns label and remove row label from cell
          ?.sort()
        const lastColValue = parsedKeys[parsedKeys.length - 1]
        const totalColSize = getLastColIndex(lastColValue)
        const lastColumnIndex = Math.max.apply(null, Object.values(this.templateConfig.columnMappings))

        if (totalColSize !== lastColumnIndex) {
          return this.errors.push(this.translations.fileColumnValidationError);
        }

        const dataWithQty = data.filter(item => item[configValues.QTY_TITLE]);

        if (dataWithQty.length === 0) {
          return this.errors.push(this.translations.bulkUploadMessageFileError);
        }

        // prepare data for BE request
        const preparedDataWithQty = dataWithQty.map(item => {
          const preparedData = {
            sku: item[configValues.SKU_TITLE],
            requestedQty: this.checkForSeparators(item[configValues.QTY_TITLE]),
            lineIndex: data.indexOf(item) + configValues.LINES_HEADER_WHITESPACE,
          };

          if (configValues.SPECIAL_PRICE_TITLE) {
            preparedData.price = item[configValues.SPECIAL_PRICE_TITLE]?.replace(/ /g,'') || null;
          }

          return preparedData;
        });
        let validData;
        // check Data for invalid QUANTITY values and create messages
        const [dataWithInvalidQty, dataWithValidQty] = this.validValuesSeparator(
          preparedDataWithQty,
          'requestedQty',
          this.isValidNumber,
        );

        validData = dataWithValidQty;
        this.createErrorMessages(dataWithInvalidQty, preparedDataWithQty, 'quantity');

        // check Data for invalid PRICE values and create messages
        if (configValues.SPECIAL_PRICE_TITLE) {
          const [dataWithInvalidPrice, dataWithValidPrice] = this.validValuesSeparator(
            preparedDataWithQty,
            'price',
            this.isValidPrice,
          );

          if (dataWithInvalidPrice.length) {
            this.createErrorMessages(dataWithInvalidPrice, preparedDataWithQty, 'price');
          }

          // need to send only valid prices to BE
          validData = dataWithValidPrice.filter((priceItem) => {
            if (!this.isValidNumber(priceItem.requestedQty)) {
              return false;
            }

            return dataWithValidQty.some((qtyItem) => {
              return priceItem.sku === qtyItem.sku;
            });
          });
        }

        // sends valid prepared values
        if (validData.length) {
          return {
            lines: [
              ...validData,
            ],
          }
        }
      },

      async validateFileOnServer(data) {
        try {
          const response = await this.sendValidationRequest(data);

          if (response.validationErrors.length > 0) {
            response.validationErrors.forEach(error => this.warnings.push(error))
          }

          this.isFileValid = true;

          return this.parsedFile = data;
        } catch(error) {
          this.errors.push(this.translations.bulkUploadMessageGeneralError);
        }
      },

      async onNextClick() {
        try {
          this.isSendingFile = true;
          const response = await this.sendValidData(this.parsedFile);

          this.serverMessages = response.updateInfos;
          this.isUploadSuccessfull = true;
        } catch {
          this.errors.push(this.translations.bulkUploadMessageGeneralError);
        } finally {
          this.isSendingFile = false;
        }
      },

      removeFile(file) {
        this.file = null;
        this.isFileValid = false;
        this.clearValidationState();
      },
    },
  };
</script>
