import {
  VUE_URL__ORDER_ACTIONS,
  VUE_URL__SHOPPING_LISTS,
  VUE_URL__ADD_LIST_TO_CART,
  VUE_OPEN_ORDER_ACTION_DIALOG,
} from '../_global-constants';
import {http} from "../utils/http-requests";

const regExpForSpecialSymbols = /[?¿¡"`‘“”«»#%&@^=~$*+±§.,:;|‒–—―‐‹›<>{}⟨⟩/[\]()\\]/gi;
const sanitizeOfValue = (value, regExp) => value.replace(regExp, '');

export default {
  name: 'VueAction',

  render() {
    return this.$scopedSlots.default({
      sendForm: this.sendForm,
      action: this.action,
      updateAction: this.updateAction,
      updateMessageText: this.updateMessageText,
      updateSendCopy: this.updateSendCopy,
      isConfirmationStep: this.isConfirmationStep,
      email: this.email,
      closeForm: this.closeForm,
      sendCopy: this.sendCopy,
      messageText: this.messageText,
      removeList: this.removeList,
      buyList: this.buyList,
      changeList: this.changeList,
      listName: this.listName,
      isDisabled: this.isDisabled,
      isListNameEmpty: this.isListNameEmpty,
      reorder: this.reorder,
      updateListName: this.updateListName,
      isListAlreadyExists: this.isListAlreadyExists,
      listAlreadyExistsErrorMessage: this.listAlreadyExistsErrorMessage,
    });
  },

  props: {
    actionId: {
      required: true,
    },
    cartLink: {
      type: String,
      default: '',
    },
  },

  mounted() {
    const listName = this.$root.$refs?.listName?.value;

    this.copyListName = listName;
    this.listName = listName;
    this.$root.$on(VUE_OPEN_ORDER_ACTION_DIALOG, this.loadAction);
  },

  data() {
    return {
      action: '',
      messageText: '',
      sendCopy: false,
      isConfirmationStep: false,
      email: '',
      listName: '',
      isDisabled: false,
      isListAlreadyExists: false,
      listAlreadyExistsErrorMessage: '',
      copyListName: '',
    };
  },

  computed: {
    isListNameEmpty() {
      return this.listName ? this.listName.trim().length <= 0 : false;
    },
  },

  methods: {
    updateAction(e) {
      this.action = e.target.value;
    },

    updateMessageText(e) {
      this.messageText = e.target.value;
    },

    updateSendCopy(e) {
      this.sendCopy = e.target.checked;
    },

    loadAction: function(action) {
      this.action = action;
      this.messageText = '';
      this.sendCopy = false;
      this.isConfirmationStep = false;
      this.email = '';
    },

    closeForm() {
      let dialog = this.$root.$refs.dialogOrderAction;
      let dialogRemove = this.$root.$refs.dialogOrderActionRemove;
      let dialogBuy = this.$root.$refs.dialogOrderActionBuy;
      let dialogReorder = this.$root.$refs.dialogOrderActionReorder;
      let dialogRename = this.$root.$refs.dialogOrderActionRename;

      if (dialogRemove && dialogRemove.open) {
        dialogRemove.close();
        document.body.style.overflowY = 'auto';
      }

      if (dialogBuy && dialogBuy.open) {
        dialogBuy.close();
        document.body.style.overflowY = 'auto';
      }

      if (dialog && dialog.open) {
        dialog.close();
        document.body.style.overflowY = 'auto';
      }

      if (dialogRename && dialogRename.open) {
        dialogRename.close();
        document.body.style.overflowY = 'auto';
        this.$root.$refs.listName.value = this.copyListName;
        this.listName = this.copyListName;
        this.isListAlreadyExists = false;
        this.listAlreadyExistsErrorMessage = '';
      }

      if (dialogReorder && dialogReorder.open) {
        dialogReorder.close();
        document.body.style.overflowY = 'auto';
      }
    },

    async sendForm() {
      let loader = this.$loading.show({
        active: true,
        container: this.$el,
      });
      let jsonRequest = {
        orderId: this.actionId,
        orderAction: this.action,
        comments: this.messageText,
        sendCopy: this.sendCopy,
      };

      try {
        let response = await http.post(VUE_URL__ORDER_ACTIONS, jsonRequest);

        this.email = response.email;
        this.isConfirmationStep = true;

        return true;
      }
      catch (error) {
        return error;
      }
      finally {
        loader.hide();
        this.isDisabled = false;
        setTimeout(() => {
          this.closeForm();
        }, 3000);
      }
    },

    async removeList() {
      this.$loading.show({
        active: true,
        container: this.$el,
      });

      let url = VUE_URL__SHOPPING_LISTS + '/' + encodeURIComponent(this.actionId);

      try {
        let response = await http.delete(url);

        if (response) {
          this.closeForm();
          let {href} = window.location;

          window.location.href = href.substring(0, href.lastIndexOf('/'));

          return true;
        }
      } catch (error) {
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message,
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(
          error.message || error,
        );

        notification.show();

        return;
      }
    },

    async buyList() {
      this.isDisabled = true;
      const jsonRequest = {
        id: this.actionId,
      };
      const loader = this.$loading.show({
        active: true,
        container: this.$el,
      });

      try {
        const response = await http.post(VUE_URL__ADD_LIST_TO_CART, jsonRequest);

        if (response) {
          this.isDisabled = false;
          this.closeForm();
          window.location.href = window.location.origin + this.cartLink;

          return true;
        }
      }
      catch (error) {
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message,
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(
          error.message || error,
        );

        notification.show();

        return;
      }
      finally {
        loader.hide();
        this.closeForm();
        this.isDisabled = false;
      }
    },

    async changeList(newName) {
      let newCleanName = sanitizeOfValue(newName, regExpForSpecialSymbols);
      let jsonRequest = {
        name: newCleanName,
      };
      let loader = this.$loading.show({
        active: true,
        container: this.$el,
      });
      let url = VUE_URL__SHOPPING_LISTS + '/' + encodeURIComponent(this.actionId);

      try {
        let response = await http.patch(url, jsonRequest);

        if (response) {
          this.copyListName = '';
          this.closeForm();

          const pageTitle = document.querySelector('h1.page-title__head');

          pageTitle.textContent = newCleanName;

          return true;
        }

      }
      catch (error) {
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              this.isListAlreadyExists = true;
              this.listAlreadyExistsErrorMessage = errResp.message;

              return true;
            })
            .catch(e => {
              console.error(e);
              this.isListAlreadyExists = true;
              this.listAlreadyExistsErrorMessage = e;

              return true;
            });
        }

        return;
      }
      finally {
        loader.hide();
      }
    },

    async reorder() {
      this.isDisabled = true;
      let jsonRequest = {
        orderId: this.actionId,
        orderAction: this.action,
      };

      this.$loading.show({
        active: true,
        container: this.$el,
      });
      try {
        let response = await http.post(VUE_URL__ORDER_ACTIONS, jsonRequest)

        this.isDisabled = false;
        this.closeForm();
        window.location = response.redirectUrl;

        return true;
      }
      catch (error) {
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message,
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(
          error.message || error,
        );

        notification.show();

        return;
      }
    },

    updateListName(event) {
      let { value } = event.target;

      value = sanitizeOfValue(value, regExpForSpecialSymbols);
      this.$root.$refs.listName.value = value;
      this.listName = value;
    },
  },
};
