import {
  VUE_URL__DELIVERY_METHODS,
  VUE_EVENT_NAME__UPDATE_DELIVERY_METHODS,
} from '../_global-constants';
import {http} from "../utils/http-requests";

let savedDeliveryMethod = sessionStorage.getItem('savedDeliveryMethodKey');

export default {
  name: 'VueDeliveryMethods',

  props: {
    deliveryMethods: {
      required: true,
    },
  },

  data() {
    return {
      methods: JSON.parse(this.deliveryMethods),
    };
  },

  render() {
    return this.$scopedSlots.default({
      methods: this.methods,
      setSavedDeliveryMethod: this.setSavedDeliveryMethod,
    });
  },

  mounted() {
    this.initSessionCleanerOnAddressChange();
    this.checkSavedDeliveryMethod();
  },

  methods: {
    initSessionCleanerOnAddressChange() {
      // need to remove any saved information before changing the address
      const addressSelectorButton = document.querySelector('#address-selector-button');

      if(addressSelectorButton) {
        addressSelectorButton.addEventListener('click', () => {
          sessionStorage.removeItem('savedDeliveryMethodKey');
          sessionStorage.removeItem('shippingMode');
        });
      }
    },

    setSavedDeliveryMethod(deliveryMethod) {
      sessionStorage.setItem('savedDeliveryMethodKey', deliveryMethod);
      sessionStorage.setItem('shippingMode', 'delivery');
    },

    checkSavedDeliveryMethod() {
      // Preselect delivery method if it was saved before (for MULTI_DELIVERY_METHODS type of calendar)
      if (savedDeliveryMethod) {
        const deliveryMethodKey = this.$el.querySelector(
          `input[type="radio"][name="deliveryMethodKey"][value="${savedDeliveryMethod}"]`,
        );

        deliveryMethodKey.checked = true;
      } else {
        const deliveryMethodKey = this.$el.querySelector('input:checked');

        if (deliveryMethodKey) {
          deliveryMethodKey.checked = false;
        }
      }
    },

    async updateMethods() {
      let loader = this.$loading.show({
        active: true,
        container: this.$el,
      });

      try {
        let response = await http.get(VUE_URL__DELIVERY_METHODS)

        this.methods = response.deliveryMethods;

        return response;
      }
      catch (error) {
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message,
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(
          error.message || error,
        );

        notification.show();

        return;
      }
      finally {
        loader.hide();
      }
    },
  },

  created() {
    this.$root.$on(VUE_EVENT_NAME__UPDATE_DELIVERY_METHODS, this.updateMethods);
  },
};
