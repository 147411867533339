<template functional>
  <li
    class="glide__slide glide__slide__hero-banner"
    :data-link="props.dataLink"
    :data-index="props.dataIndex"
    :data-glide-autoplay="props.dataGlideAutoplay"
    v-on="listeners"
  >
    <div
      v-if="props.carouselItem.showOverlay"
      class="glide__slide__hero-banner__overlay"
      :style="{
        opacity: 0.6,
        background: `linear-gradient(90deg, ${props.carouselItem.overlayColor1}, ${props.carouselItem.overlayColor2})`
      }"
    >
    </div>
    <div class="glide__slide__hero-banner__content">
      <h1
        v-if="props.carouselItem.title"
        class="font-hero space-m-3 glide__hero-banner__content__item"
        :class="[
          (props.carouselItem.titleTextColor != 'default') ? `text-${props.carouselItem.titleTextColor}` : '',
          (props.carouselItem.titlePosition != 'left') ? `is-text-${props.carouselItem.titlePosition}` : ''
        ]"
      >
        {{ props.carouselItem.title }}
      </h1>
      <p
        v-if="props.carouselItem.content"
        class="font-regular-body-copy space-m-3 glide__hero-banner__content__item"
        :class="[
          (props.carouselItem.contentTextColor != 'default') ? `text-${props.carouselItem.contentTextColor}` : '',
          (props.carouselItem.contentPosition != 'left') ? `is-text-${props.carouselItem.contentPosition}` : ''
        ]"
      >
        {{ props.carouselItem.content }}
      </p>
      <div class="glide__hero-banner__content-wrapper">
        <a
          v-if="
            props.carouselItem.showButton
              && props.carouselItem.buttonInternalLink
              && props.carouselItem.buttonExternalLink == ''
          "
          :href="props.carouselItem.buttonInternalLink"
          class="button glide__hero-banner__content__item intrn"
          :class="[
            (props.carouselItem.buttonPosition != 'left') ? `is-item-${props.carouselItem.buttonPosition}` : '',
            (props.carouselItem.invertButtonColor) ? 'is-secondary' : 'is-primary',
            props.carouselItem.buttonColor
          ]"
        >
          {{ props.carouselItem.buttonText }}
        </a>
        <a
          v-if="props.carouselItem.showButton && props.carouselItem.buttonExternalLink != ''"
          :href="'https://' + props.carouselItem.buttonExternalLink"
          target="_blank"
          class="button glide__hero-banner__content__item hide-external-link-icon"
          :class="[
            (props.carouselItem.buttonPosition != 'left') ? `is-item-${props.carouselItem.buttonPosition}` : '',
            (props.carouselItem.invertButtonColor) ? 'is-secondary' : 'is-primary',
            props.carouselItem.buttonColor
          ]"
        >
          {{ props.carouselItem.buttonText }}
        </a>
        <a
          v-if="
            props.carouselItem.linkText != ''
              && props.carouselItem.linkInternalLink
              && props.carouselItem.linkExternalLink == ''
          "
          :href="props.carouselItem.linkInternalLink"
          class="is-link text-uppercase arrow-right glide__hero-banner__content__item"
          :class="[
            (props.carouselItem.linkTextColor != 'default') ? 'text-' + props.carouselItem.linkTextColor : '',
            (props.carouselItem.linkPosition != 'left') ? 'is-text-' + props.carouselItem.linkPosition : ''
          ]"
        >
          {{ props.carouselItem.linkText }}
        </a>
        <a
          v-if="props.carouselItem.linkText != '' && props.carouselItem.linkExternalLink != ''"
          class="is-link text-uppercase arrow-right glide__hero-banner__content__item hide-external-link-icon"
          :href="'https://' + props.carouselItem.linkExternalLink"
          target="_blank"
          :classappend="[
            (props.carouselItem.linkTextColor != 'default') ? 'text-' + props.carouselItem.linkTextColor : '',
            (props.carouselItem.linkPosition != 'left') ? 'is-text-' + props.carouselItem.linkPosition : ''
          ]"
        >
          {{ props.carouselItem.linkText }}
        </a>
      </div>
    </div>
    <div>
      <picture
        v-if="!props.video"
        id="image_hero_banner_carousel"
        class="glide__hero-banner__picture"
      >
        <img
          :src="props.images.desktop.src"
          alt="banner"
          class="is-hidden-mobile is-hidden-tablet-only"
        />
        <img
          :src="props.images.tablet.src"
          alt="banner"
          class="is-hidden-mobile is-hidden-desktop"
        />
        <img
          :src="props.images.mobile.src"
          alt="banner"
          class="is-hidden-tablet-only is-hidden-desktop"
        />
      </picture>
      <div
        v-else
        class="glide__hero-banner__picture"
      >
        <video
          :src="props.video"
          muted
          loop
          autoplay
          playsinline
        >
          Video Not supported
        </video>
      </div>
    </div>
  </li>
</template>

<script>
  export default {
    functional: true,
  }
</script>