import Vue from 'vue';
import Vuex from 'vuex';

import cartModule, { CART_NAMESPACE } from './modules/cart';
import hierarchyModule, { HIERARCHY_NAMESPACE } from './modules/facet-hierarchy';
import invoicesModule, { INVOICES_NAMESPACE } from './modules/invoices';
import spolModule, { SPOL_NAMESPACE } from './modules/spol';
import bulkUploadModule, { BULK_UPLOAD_NAMESPACE } from './modules/bulk-upload';
import translationsModule, { TRANSLATIONS_NAMESPACE } from './modules/translations';
import productModule, { PRODUCT_NAMESPACE } from './modules/product';
import featureModule, { FEATURE_NAMESPACE } from './modules/features';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    [CART_NAMESPACE]: cartModule,
    [HIERARCHY_NAMESPACE]: hierarchyModule,
    [INVOICES_NAMESPACE]: invoicesModule,
    [SPOL_NAMESPACE]: spolModule,
    [BULK_UPLOAD_NAMESPACE]: bulkUploadModule,
    [TRANSLATIONS_NAMESPACE]: translationsModule,
    [PRODUCT_NAMESPACE]: productModule,
    [FEATURE_NAMESPACE]: featureModule,
  },
});
