import mutationTypes from "./mutationTypes";

const mutations = {
  [mutationTypes.CART_MUTATION__SET_CART](state, newCart) {
    state.isInitialState = false;
    state.data = newCart;
  },
  [mutationTypes.CART_MUTATION__CLEAR_DISCOUNT_CODE](state) {
    state.discountCode.isValid = null;
    state.discountCode.code = null;
    state.discountCode.message = '';
  },
  [mutationTypes.CART_MUTATION__SET_DISCOUNT_CODE](state, { isValid, message, code }) {
    state.discountCode.isValid = isValid;
    state.discountCode.code = code;
    state.discountCode.message = message;
  },
  [mutationTypes.CART_MUTATION__UPDATE_VALIDITY](state, { lineItemId, status }) {
    if (status) {
      state.invalidLineItems.add(lineItemId)
    } else {
      state.invalidLineItems.delete(lineItemId)
    }

    state.hasInvalidLineItems = Boolean(state.invalidLineItems.size)
  },
};

export default mutations;
