<template>
  <div
    class="component--popup"
    role="dialog"
    aria-modal="true"
    @click="onClose"
  >
    <div class="content-wrapper">
      <div
        class="content-container"
        @click.stop=""
      >
        <label
          v-if="hasCloseBtn"
          for="close-button"
          class="close-icon is-cross"
          @click="onClose"
        >
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
          >
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#icon--close"
            ></use>
          </svg>
        </label>
        <h2
          v-if="title"
          class="title"
        >
          {{ title }}
        </h2>

        <div class="content">
          <slot name="content">
            {{ content }}
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VuePopup',

    props: {
      title: {
        type: String,
        default: '',
      },

      content: {
        type: String,
        default: '',
      },

      hasCloseBtn: {
        type: Boolean,
        default: true,
      },
    },

    mounted() {
      document.addEventListener('keydown', this.onEscapeKeydown);
      document.body.style.overflowY = 'hidden';
      document.body.classList.add('has-dialog-activated');
    },

    beforeDestroy() {
      document.removeEventListener('keydown', this.onEscapeKeydown);
      document.body.classList.remove('has-dialog-activated');
      document.body.style.overflowY = '';
    },

    methods: {
      onClose($event) {
        document.body.classList.remove('has-dialog-activated');
        this.$emit('close', $event);
      },

      onEscapeKeydown($event) {
        const escapeKeyCode = 27;

        if ($event.keyCode === escapeKeyCode) {
          this.onClose($event);
        }
      },
    },
  };
</script>
