export default {
  name: 'VueInputPassword',
  render() {
    return this.$scopedSlots.default({
      value: this.value,
      changePropOnBlur: this.changePropOnBlur,
      repeatValue: this.repeatValue,
      valuesAreMatching: this.valuesAreMatching,
      pattern: this.pattern,
      isAllowedValidation: this.isAllowedValidation,
      allowValidation: this.allowValidation,
      hasOneUppercase: this.hasOneUppercase,
      hasOneLowerCase: this.hasOneLowerCase,
      hasOneDigit: this.hasOneDigit,
      hasEightCharacters: this.hasEightCharacters,
      updateValue: this.updateValue,
      showRepeatPasswordError: this.showRepeatPasswordError,
    });
  },
  props: ['inputValue', 'regexPattern'],
  data() {
    return {
      value: this.inputValue || '',
      repeatValue: '',
      showRepeatPasswordError: false,
      pattern: this.regexPattern || '',
      isAllowedValidation: false,
      hasOneUppercaseRegex: /.*[A-Z].*/,
      hasOneLowerCaseRegex: /.*[a-z].*/,
      hasOneDigitRegex: /.*\d.*/,
      hasEightCharactersRegex: /.{8,}/,
    };
  },
  computed: {
    valuesAreMatching() {
      return this.value === this.repeatValue;
    },
    hasOneUppercase() {
      return this.hasOneUppercaseRegex.test(this.value);
    },
    hasOneLowerCase() {
      return this.hasOneLowerCaseRegex.test(this.value);
    },
    hasOneDigit() {
      return this.hasOneDigitRegex.test(this.value);
    },
    hasEightCharacters() {
      return this.hasEightCharactersRegex.test(this.value);
    },
  },
  methods: {
    changePropOnBlur(callback, prop) {
      this[prop] = !callback;
    },
    allowValidation() {
      const inputSelector = document.querySelector(
        '.password-forgotten__input-field',
      );

      this.isAllowedValidation = true;
      if (inputSelector.classList.contains('has-error')) {
        inputSelector.classList.remove('has-error');
      }
    },
    updateValue(e, type) {
      switch (type) {
        case 'repeat':
          this.repeatValue = e.target.value;
          break;
        default:
          this.value = e.target.value;
      }
    },
  },
};
