<template>
  <vue-slidedown-panel
    v-if="isDiscountEnabled && isCartPage"
    class="fragment--discount"
    :title="translations.discountTitle"
  >
    <div>
      <div class="space-mt-2 space-mb-2">
        {{ translations.discountDescription }}
      </div>
      <vue-alert
        v-if="isAlertShown"
        class="space-mt-2 space-mb-2"
        :message="discountCode.message"
        :level="alertLevel"
        :canClose="false"
        isActiveByDefault
      />
      <div class="field-list input-wrapper">
        <label class="has-float-label">
          <input
            v-model.trim="code"
            placeholder=" "
            :disabled="isInputDisabled"
            type="text"
            @input="clearDiscountCode"
          />
          <span>
            {{ translations.discountInputPlaceholder }}
          </span>
        </label>
        <button
          :disabled="isButtonDisabled"
          class="button is-primary has-no-margin"
          @click.prevent="addDiscount(code)"
        >
          {{ translations.discountButton }}
        </button>
      </div>
    </div>
  </vue-slidedown-panel>
</template>

<script>
  import { createNamespacedHelpers, mapGetters } from 'vuex';
  import { CART_NAMESPACE } from '../../store/modules/cart/index';
  import { TRANSLATIONS_NAMESPACE, getterTypes as translationGetters } from '../../store/modules/translations/index';
  import {
    CART_ACTION__ADD_DISCOUNT_TO_CART,
    CART_ACTION__CLEAR_DISCOUNT_CODE,
  } from '../../store/modules/cart/actions';
  import { CART_GETTER__DISCOUNT_CODE } from '../../store/modules/cart/getters';

  import VueSlidedownPanel from '../slidedown-panel.vue';
  import VueAlert from '../alert/alert.vue';
  import { FEATURE_NAMESPACE, getterTypes } from '../../store/modules/features';

  const { mapActions, mapGetters: mapCartGetters } = createNamespacedHelpers(CART_NAMESPACE);

  export default {
    name: "VueDiscount",

    components: {
      VueSlidedownPanel,
      VueAlert,
    },

    data() {
      return {
        code: '',
        isCartPage: false,
      }
    },

    computed: {
      ...mapGetters(TRANSLATIONS_NAMESPACE, [translationGetters.TRANSLATIONS]),
      ...mapGetters(FEATURE_NAMESPACE, {
        isDiscountEnabled: getterTypes.IS_DISCOUNT_ENABLED,
      }),
      ...mapCartGetters({
        discountCode: CART_GETTER__DISCOUNT_CODE,
      }),

      isAlertShown() {
        return this.discountCode.message !== '';
      },

      alertLevel() {
        return this.discountCode.isValid ? 'success' : 'error';
      },

      isButtonDisabled() {
        return this.code.length === 0 || this.isInputDisabled;
      },

      isInputDisabled() {
        return this.discountCode.code !== null && this.discountCode.isValid;
      },
    },

    mounted() {
      this.isCartPage = !!document.querySelector('.page--cart');
      if (this.discountCode.code) {
        this.code = this.discountCode.code;
      }
    },

    methods: {
      ...mapActions({
        addDiscount: CART_ACTION__ADD_DISCOUNT_TO_CART,
        clearDiscountCode: CART_ACTION__CLEAR_DISCOUNT_CODE,
      }),
    },
  }
  </script>