// Panel that can used to hide and show things using v-if / v-show depending on events
export default {
  name: 'VuePanel',
  render() {
    return this.$scopedSlots.default({
      isShowing: this.isShowing,
    });
  },
  data() {
    return {
      isShowing: this.isShowing,
    };
  },
  props: {
    showInitally: Boolean,
    hideOnEvent: String,
    showOnEvent: String,
  },
  methods: {
    hide() {
      this.isShowing = false;
    },
    show() {
      this.isShowing = true;
    },
  },
  created() {
    this.isShowing = this.showInitally;
    if (this.hideOnEvent) {
      this.$root.$on(this.hideOnEvent, this.hide);
    }
    if (this.showOnEvent) {
      this.$root.$on(this.showOnEvent, this.show);
    }
  },
};
