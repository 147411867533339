<template>
  <vue-alert
    v-bind="{ ...$props, ...$attrs }"
    :interactiveChangeKey="interactiveKey"
    :isIconVisible="false"
    :isCompact="currentCompact"
    :canClose="currentCanClose"
    :isFloating="currentIsFloating"
    :message="showedMessage"
    :isActive.sync="isActive"
    level="error"
    :style="styleObject"
    @close="hide"
  />
</template>

<script>

  import { EventBus, AppEvents } from '../utils/event_bus';
  import VueAlert from './alert/alert.vue';

  export default {

    name: 'VueProductErrorAlert',

    components: {
      VueAlert,
    },

    props: {

      message: {
        required: true,
        type: [Array, String],
      },

      isCompact: {
        default: false,
        required: false,
        type: Boolean,
      },

      isActiveByDefault: {
        default: false,
        required: false,
        type: Boolean,
      },

      canClose: {
        default: true,
        required: false,
        type: Boolean,
      },

      isAutoCompact: {
        default: false,
        required: false,
        type: Boolean,
      },

      interactiveChangeKey: {
        type: [Number, String],
        default: '',
      },
    },

    data() {
      return {
        interactiveKey: this.interactiveChangeKey,
        visibilityState: 'visible',
        isActive: false,
        showedMessage: this.message,
        currentCompact: this.isCompact,
        currentCanClose: this.canClose,
        currentIsFloating: this.isFloating,
      };
    },

    computed: {
      shouldCalculateMessageHeight() {
        return this.isAutoCompact && !Array.isArray(this.message);
      },

      styleObject() {
        return {
          visibility: this.visibilityState,
          ...(this.currentCompact && { backgroundColor: 'transparent' }),
        }
      },
    },

    destroyed() {
      EventBus.$off(
        AppEvents.CHANGE_PRODUCT_VIEW,
        this.handleProductTileLineChange,
      );
    },

    async mounted() {
      if (this.isActiveByDefault) {
        this.showedMessage = this.message;
      }
      EventBus.$on(
        AppEvents.CHANGE_PRODUCT_VIEW,
        this.handleProductTileLineChange,
      );

      this.visibilityState = 'hidden';
      await this.configureDynamicMessage(true);
      this.visibilityState = 'visible';
    },

    methods: {
      hide() {
        this.isActive = false;
        this.$emit('close');
      },

      show() {
        this.isActive = true;
      },

      handleProductTileLineChange() {
        if (this.message) {
          this.configureDynamicMessage();
        }
      },

      async configureDynamicMessage(shouldWait) {
        if (this.message) {
          this.show();
        } else {
          this.hide();

          return;
        }

        if (!this.shouldCalculateMessageHeight) {
          return;
        }

        const calculateTextHeight = async text => {
          this.showedMessage = text;
          await this.$nextTick();

          return this.$el.querySelector('.notification__message').clientHeight;
        };
        const oneLineTextHeight = await calculateTextHeight('One Line Text :}');

        if (shouldWait) {
          await new Promise(res => {
            setTimeout(res, 100);
          });
        }
        const currentHeight = await calculateTextHeight(this.message);

        if (currentHeight > oneLineTextHeight) {
          // more than one line
          this.currentCompact = false;
          this.currentCanClose = true;
          this.currentIsFloating = true;
        } else {
          this.currentCompact = true;
          this.currentCanClose = false;
          this.currentIsFloating = false;
        }
      },
    },

    watch: {
      message(newMessage, oldMessage) {
        if (newMessage == oldMessage || this.interactiveChangeKey) {
          return;
        }
        this.configureDynamicMessage();
      },
      interactiveChangeKey(newValue, oldValue) {
        if (newValue == oldValue) {
          return;
        }
        this.configureDynamicMessage();
      },
    },
  };
</script>
