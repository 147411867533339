import 'regenerator-runtime/runtime';

// Vue
import Vue from 'vue';

// Store
import Vuex from 'vuex';

// 3rd party
import { VueAutosuggest } from 'vue-autosuggest';
import VueTooltip from 'v-tooltip';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  AisInstantSearch,
  AisSearchBox,
  AisInfiniteHits,
  AisRefinementList,
  AisHighlight,
  AisCurrentRefinements,
  AisClearRefinements,
  AisRatingMenu,
  AisNumericMenu,
  AisToggleRefinement,
  AisRangeInput,
  AisSortBy,
  AisStateResults,
  AisPagination,
} from 'vue-instantsearch';
import Loading from 'vue-loading-overlay';

import store from './store';

// Polyfills
import 'whatwg-fetch'; // window.fetch()
import 'element-closest'; // Element.closest() and Element.matches()
import 'dialog-polyfill'; // <dialog>
import './utils/placeholder-shown-polyfill';

// BNS utils
import { globalNotification } from './utils/ui-utils';
import { isMobile } from './utils';

// Containers
import VueInstantSearch from './containers/instant-search';
import VuePanel from './containers/panel';
import './containers/header';

// Components
import VueCartIcon from './components/cart-icon/cart-icon.vue';
import VueSearchBar from './components/search-bar';
import './fragments/product-carousel';
import './components/header-navigation';
import './components/inpage-navigation';
import './components/teaser';
import './components/saved-items';
import './components/payment';
import VueFilter from './components/filter/filter.vue';
import VueHierarchy from './components/hierarchy/hierarchy.vue';
import VueEForm from './components/eform';
import VueRequestUserChanges from './components/requestUserChanges';
import VueFileInput from './components/file-input/file-input.vue';
import VueDownloadInvoices from './components/download-invoices/download-invoices.vue';
import VueDownloadSpol from './components/download-spol/download-spol.vue';
import VueProductAutosuggest from './components/product-autosuggest.vue'
import VueOrderBulkUpload from './components/order-bulk-upload.vue';
import VueClearCart from './fragments/clear-cart.vue';
import VueRequestAndOrderPopup from './components/request-and-order-popup.vue';
import VueCategoryNavigation from './components/category-navigation/category-navigation.vue';
import VueBadge from './components/badge/badge.vue';
import VueIcon from './components/icon/icon.vue';
import VueRadioButton from './components/radio-button/radio-button.vue';

// Vue components
import * as VueBaseComponents from './vue-base-components';
import * as VueAdditionalComponents from './vue-additional-components';

// Fragments
import VueProductTile from './fragments/product-tile';
import VueOrderLine from './fragments/order-line';
import VueCart from './fragments/cart';
import VueCartTotals from './fragments/cart-totals';
import VueDeliveryOrPickup from './fragments/delivery-or-pickup';
import VueAddToList from './fragments/add-to-list-popup';
import VueFilterSelections from './fragments/filter-selections';
import VueAccordion from './fragments/accordion';
import VueOrderTable from './fragments/order-table';
import VueUsersTable from './fragments/users-table';
import VueAction from './fragments/action';
import VueActionButton from './fragments/action-button';
import VueMyAddresses from './fragments/my-addresses';
import VueNotification from './fragments/notification';
import VueDeliveryPickupDates from './fragments/delivery-pickup-dates';
import VueInputPassword from './fragments/input-component';
import VueOrderButton from './fragments/order-button/order-button.vue';
import VueDeliveryMethods from './fragments/delivery-methods';
import VueQuickOrder from './components/quick-order';
import VuePagination from './fragments/pagination';
import VuePayment from './fragments/payment';
import VueRations from './fragments/rations';
import './fragments/cookie-banner';
import './fragments/dialog';
import './fragments/list-tile-switch';
import './fragments/place-order-and-toc';
import './fragments/password-reveal';
import './fragments/product-image';
import './fragments/my-addresses-privacy-checkbox';
import './fragments/personal-details';
import './utils/captcha-validate';
import './utils/custom-directives';
import './fragments/go-to-top-button';
import './components/target-destination';
import './fragments/form-submit';
import { getDataFromDataLayer, addNonBlacklistedDataToDataLayer } from './utils/gtm-utils';

Vue.use(Vuex);
Vue.use(VueTooltip);
Vue.use(Loading, {
  isFullPage: false,
  loader: 'dots',
});

const AllVueComponents = {
  VueAutosuggest,
  VueProductAutosuggest,
  VueProductTile,
  VueOrderLine,
  VueCart,
  VueInstantSearch,
  VueAddToList,
  VueClearCart,
  VueCartIcon,
  VueCartTotals,
  VueDeliveryOrPickup,
  VueSearchBar,
  VueFilter,
  VueHierarchy,
  VueEForm,
  VueRequestUserChanges,
  VueDownloadInvoices,
  VueDownloadSpol,
  VueOrderBulkUpload,
  VueFileInput,
  VueFilterSelections,
  VueAccordion,
  VueOrderTable,
  VueUsersTable,
  VuePanel,
  VueAction,
  VueActionButton,
  VueMyAddresses,
  VueTooltip,
  VueOrderButton,
  VueDeliveryMethods,
  VueQuickOrder,
  VuePagination,
  VuePayment,
  VueRations,
  VueRequestAndOrderPopup,
  AisInstantSearch,
  AisSearchBox,
  AisInfiniteHits,
  AisRefinementList,
  AisHighlight,
  AisCurrentRefinements,
  AisClearRefinements,
  AisRatingMenu,
  AisNumericMenu,
  AisToggleRefinement,
  AisRangeInput,
  AisSortBy,
  AisStateResults,
  VueNotification,
  AisPagination,
  VueDeliveryPickupDates,
  VueInputPassword,
  VueCategoryNavigation,
  VueBadge,
  VueIcon,
  VueRadioButton,
  ...VueBaseComponents,
  ...VueAdditionalComponents,
};
// Create instance for bloomreach CMS
const { editMode } = document.body.dataset;


// Only Use this block for edit Mode
if (editMode === 'true') {
  const allVueElems = document.getElementsByClassName('vue-preview')

  // Initialize page with vue instances of for all vue components
  for (let elem of allVueElems) {
    new Vue({
      el: elem,
      store,
      components: {
        ...AllVueComponents,
      },
    })
  }

  // Create a new vue instance for newly added dom node on runtime
  const observer = new MutationObserver((mutations) => {
    // THe mutations is emitted multiple times so getting last mutation
    // this piece of code is still experimantal
    mutations[mutations.length - 1]?.addedNodes?.forEach((elem) => {
      if(elem?.classList?.contains('vue-preview')) {
        new Vue({
          el: elem,
          store,
          components: {
            ...AllVueComponents,
          },
        })
      }
    })

  });

  // Observe for change in document body
  observer.observe(document.body, {
    characterDataOldValue: true,
    subtree: true,
    childList: true,
    characterData: true,
  });
}

// Init Vue
let vueInstance = new Vue({
  el: '#vue-app',
  store,
  components: {
    ...AllVueComponents,
  },
});
// @TODO: Make this dynamic on Webpack compile?
// eslint-disable-next-line no-underscore-dangle, no-unused-vars
const __DEBUG_MODE__ = true;

window.BNS = Object.assign(
  {},
  window.BNS,
  {
    vueInstance,
    globalNotification,
    getDataFromDataLayer,
    addNonBlacklistedDataToDataLayer,
  },
);

if (isMobile()) {
  const setAppHeight = () => {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
  }

  window.addEventListener('resize', setAppHeight);
  setAppHeight();
}

try {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: window.BNS.azureConnectionString,
    },
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();
} catch (error) {
  console.error('Azure monitoring is not enabled: ', error);
}

if (!__DEBUG_MODE__) {
  // Disable notification in dev-tools
  Vue.config.productionTip = false;

  // Disable Vue-Devtools to work
  Vue.config.devtools = false;
}
