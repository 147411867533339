import { VUE_EVENT_NAME__RERENDER_PAGINATION } from '../_global-constants';

export default {
  name: 'VuePagination',

  props: {
    data: {
      required: false,
      type: Object,
    },
    handler: {
      required: false,
      type: Function,
    },
    eventNameSuffix: {
      required: false,
      type: String,
      default: '',
    },
  },

  data() {
    return {
      pagination: this.data,
    };
  },

  computed: {
    isVisible() {
      return this.pagination && this.pagination.pages && this.pagination.pages.length > 0;
    },
  },

  methods: {
    isNotClickable(page) {
      return (
        (this.pagination.currentPage === 1 && page.isFirst)
        || (this.pagination.currentPage === 1 && page.isPrevious)
        || (this.pagination.currentPage === this.pagination.totalPages
          && page.isNext)
        || (this.pagination.currentPage === this.pagination.totalPages
          && page.isLast)
      );
    },

    goTo(page) {
      if (!page) {return false;}
      if (this.isNotClickable(page)) {return false;}

      if (this.handler) {
        return this.handler(page.href);
      } else {
        window.location.href = page.href;
      }
    },

    rerender(newData) {
      this.pagination = newData;
    },
  },

  mounted() {
    this.$root.$on(
      VUE_EVENT_NAME__RERENDER_PAGINATION + this.eventNameSuffix,
      this.rerender,
    );
  },

  render() {
    return this.$scopedSlots.default({
      ...this.pagination,
      isVisible: this.isVisible,
      goTo: this.goTo,
    });
  },
};
