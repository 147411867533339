export function compareStrings(str1, str2) {
  return str1.toLowerCase() === str2.toLowerCase();
}

export function isMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some(item => navigator.userAgent.match(item));
}

export function downloadFile(fileBlob, fileName = 'file.txt') {
  const a = document.createElement('a');

  a.href = URL.createObjectURL(fileBlob);
  a.setAttribute('download', fileName);
  a.click();
  URL.revokeObjectURL(a.href);
}

export const fillPlaceholderWithValue = (placeholder, value) => {
  return placeholder.replace('{0}', value);
}


export const validateString=(s)=>{
  if(Boolean(s)){
    return s;
  }

  return null;
}
export const sleep =(duration)=>{
  return new Promise((res)=>setTimeout(res, duration))
}