<template>
  <label class="has-float-label">
    <textarea
      :value="value"
      :placeholder="placeholder"
      :aria-label="label"
      :class="['placeholder-shown', additionalClass]"
      v-bind="$attrs"
      v-on="listeners"
    ></textarea>
    <span :data-optional-label="optionalLabel"> {{ label }} </span>
    <slot></slot>
  </label>
</template>

<script>
  export default {
    name: 'VueTextarea',

    props: {
      value: {
        type: String,
        default: '',
        required:false,
      },

      label: {
        type: String,
        default: 'Comments',
      },

      optionalLabel: {
        type: String,
        default: '',
      },

      additionalClass: {
        type: String,
        default: '',
      },

      placeholder: {
        type: String,
        default: ' ',
      },
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
        };
      },
    },
    methods: {
      onInput(event) {
        const { value } = event.target;

        this.$emit('input', event, value);
      },
    },
  };
</script>