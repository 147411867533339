var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment--notification",class:[
    _vm.levelCssClass,
    {
      'is-active': _vm.currentIsActive,
      'is-compact': _vm.isCompact,
      'is-global': _vm.isGlobal
    }
  ]},[(_vm.isIconVisible)?_c('div',{staticClass:"notification__icon"},[_c('svg',{attrs:{"role":"img","xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16"}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":_vm.iconReference}})])]):_vm._e(),_vm._v(" "),(Array.isArray(_vm.message))?_c('div',_vm._l((_vm.message),function(text){return _c('div',{key:text,staticClass:"notification__message"},[_vm._v("\n      "+_vm._s(text)+"\n    ")])}),0):_c('div',{ref:"message_container",staticClass:"notification__message",domProps:{"innerHTML":_vm._s(_vm.message)}},[_vm._v("\n    My message\n  ")]),_vm._v(" "),(_vm.canClose)?_c('div',{staticClass:"notification__close",on:{"click":_vm.hide}},[_c('svg',{attrs:{"role":"img","xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16"}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":"#icon--close"}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }