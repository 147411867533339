import {
  VUE_URL__ORDERS,
  VUE_URL__RESERVATIONS,
  VUE_EVENT_NAME__RERENDER_PAGINATION,
} from '../_global-constants';
import {http} from "../utils/http-requests";


export default {
  name: 'VueOrderTable',
  render() {
    return this.$scopedSlots.default({
      isMobile: this.isMobile,
      orders: this.orders,
      getStatusStyle: this.getStatusStyle,
      searchOrder: this.searchOrder,
      sortItems: this.sortItems,
      sortOrder: this.sortOrder,
      callAPI: this.callAPI,
      pagination: this.pagination,
      orderstate: this.orderstate,
    });
  },
  props: {
    ordertype: {
      required: true,
    },
    orderstate: {
      required: false,
      default: '',
    },
    sortitems: {
      type: Array,
      required: true,
    },
  },
  computed: {
    orderType: function() {
      return this.ordertype;
    },
  },
  data: function() {
    return {
      isMobile: false,
      orders: [],
      sort: 'createdAt DESC',
      searchText: '',
      timeout: null,
      debounceMilliseconds: 500,
      sortItems: this.sortitems,
    };
  },
  mounted: function() {
    this.getOrders();
    this.$nextTick(function() {
      window.addEventListener('resize', this.onResize);
      this.onResize();
    });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 1000;
    },

    searchOrder(e) {
      this.searchText = e.target.value;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getOrders();
      }, this.debounceMilliseconds);
    },

    sortOrder: function(e) {
      this.sort = e.target.value;
      this.getOrders();
    },

    getOrders: function() {
      let url = '';

      if (this.orderType === 'reservations') {
        url = VUE_URL__RESERVATIONS + '?type=reservation';
      } else if (this.orderType === 'quotes') {
        url = VUE_URL__ORDERS + '?type=quotation';
      } else {
        url = VUE_URL__ORDERS + '?type=order';
      }

      url += '&sort=' + this.sort;

      if (this.searchText.length > 0) {
        url += '&q=' + encodeURIComponent(this.searchText);
      }

      if (this.orderstate) {
        url += '&state=' + this.orderstate;
      }

      this.callAPI(url);
    },

    callAPI: async function(url) {
      let loader = this.$loading.show({
        active: true,
        container: this.$el,
      });

      try {
        let response = await http.get(url);

        this.orders = response.orders;
        this.pagination = response.pagination;

        let eventNameSuffix = '';

        if (this.orderstate) {
          eventNameSuffix = this.orderstate;
        }

        this.$root.$emit(
          VUE_EVENT_NAME__RERENDER_PAGINATION + eventNameSuffix,
          response.pagination,
        );

        return true;
      }
      catch (error) {
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message,
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(
          error.message || error,
        );

        notification.show();

        return;
      }
      finally {
        loader.hide();
      }
    },

    getStatusStyle: function(order) {
      if (!order || !order.hasOwnProperty('state')) {return;}

      if (order.state === null) {return '';}

      if (!order.state.hasOwnProperty('key')) {return '';}

      if (order.state.key.toLowerCase() === 'done') {
        return 'is-success';
      }

      return '';
    },
  },
};
