import actions from './actions';
import mutations from './mutations';
import state from './state';
import getters from './getters';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state,
};

export const PRODUCT_NAMESPACE = 'PRODUCT_NAMESPACE';

export { default as actionTypes } from './actionTypes'