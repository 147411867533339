// Vue
import Vue from 'vue';

Vue.directive('smooth-scroll', (el, binding) => {
  const destination = document.querySelector(`.${binding.value}`);

  if (!destination) {
    return
  };

  el.addEventListener('click', (e) => {
    e.preventDefault();

    destination.scrollIntoView({
      behavior: 'smooth',
    });
  })
});

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      event.stopImmediatePropagation();
      if (!(el == event.target || el.contains(event.target))) {
        return binding.value.call(vnode.context, event);
      }
    };

    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Vue.directive('scroll-to-invalid-field', {
  bind(form) {
    const inputs = form.querySelectorAll('input, select');
    const submitButton = form.querySelector('button[type="submit"], input[type="submit"]');
    const triggerValidationMessage = () => {
      const submitElement = document.createElement('button');

      submitElement.setAttribute('type', 'submit');

      form.appendChild(submitElement);
      submitElement.click();
      submitElement.remove();
    };

    if (inputs.length === 0) {
      return
    };

    form.scrollToInvalidField = event => {
      if (form.checkValidity()) {
        return;
      }
      if (event.isTrusted) {
        event.preventDefault();
        const formInputs = form.querySelectorAll('input, select');
        const firstInvalidInput = Array.from(formInputs).find(input => !input.checkValidity());

        firstInvalidInput.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
        setTimeout(triggerValidationMessage, 200);
      }
    }

    form.scrollToInvalidFieldOnEnter = event => {
      if (event.key === 'Enter') {
        form.scrollToInvalidField(event);
      }
    }

    submitButton.addEventListener('click', form.scrollToInvalidField);
    inputs.forEach(input => input.addEventListener('keydown', form.scrollToInvalidFieldOnEnter));
  },
  /*
    Form can contain conditional fields.
    We need to add listeners again, when new field appears.
  */
  componentUpdated(form) {
    const inputs = form.querySelectorAll('input, select');

    inputs.forEach(input => {
      input.removeEventListener('keydown', form.scrollToInvalidFieldOnEnter);
      input.addEventListener('keydown', form.scrollToInvalidFieldOnEnter);
    });
  },
  unbind(form) {
    const inputs = form.querySelectorAll('input, select');
    const submitButton = form.querySelector('button[type="submit"], input[type="submit"]');

    submitButton.removeEventListener('click', form.scrollToInvalidField);
    inputs.forEach(input => input.removeEventListener('keydown', form.scrollToInvalidFieldOnEnter));
  },
});
