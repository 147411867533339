import getterTypes from "./getterTypes";

const getters = {
  [getterTypes.TRANSLATIONS]: state => {
    if (state.translations && Object.keys(state.translations).length > 0) {
      return state.translations;
    }

    return defaultTranslations;
  },
  [getterTypes.DEFAULT_TRANSLATIONS_ADDED]: state => state.defaultTranslationsAdded,
}

export default getters;