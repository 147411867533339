import { escapeRegex } from '../utils/regexp-utils';
import {http} from "../utils/http-requests";
import {
  HAS_DO_NOT_TRACK_DISABLED,
  VUE_URL__AUTO_SUGGEST,
  USER_CANCELLED_REQUEST_CODE,
} from '../_global-constants';
import { GTMEvents } from '../consts/gtm_events';

export default {
  name: 'VueSearchBar',
  props: {
    searchUrl: {
      type: String,
      required: true,
    },
  },
  render() {
    return this.$scopedSlots.default({
      onInputChange: this.onInputChange,
      suggestions: this.suggestions,
      getSuggestionValue: this.getSuggestionValue,
      sectionConfigs: this.sectionConfigs,
      executeSearch: this.executeSearch,
    });
  },
  data() {
    return {
      searchText: '',
      timeout: null,
      suggestionFetchController: null,
      debounceMilliseconds: 500,
      sectionConfigs: {
        default: {
          onSelected: this.executeSearch,
        },
        products: {
          limit: 8,
          onSelected: this.onSelected,
        },
        categories: {
          limit: 2,
          onSelected: this.onSelected,
        },
      },
      suggestions: [],
    };
  },
  methods: {
    getSuggestions: async function(text) {
      let url = VUE_URL__AUTO_SUGGEST + '?q=' + encodeURIComponent(text);

      this.suggestionFetchController = new AbortController();
      let options = {signal: this.suggestionFetchController.signal}

      try {
        let response = await http.get(url, options);
        let productsSuggestions = [];
        let categoriesSuggestions = [];

        response.productsSuggestions.forEach(entry => {
          entry.name = this.highlightText( this.generateHighlightText(entry), text);
          productsSuggestions.push(entry);
        });

        response.querySuggestions.forEach(entry => {
          entry.name = this.highlightText(entry.name, text);
          categoriesSuggestions.push(entry);
        });

        this.suggestions.push({
          name: 'products',
          data: productsSuggestions,
        });

        this.suggestions.push({
          name: 'categories',
          data: categoriesSuggestions,
        });

        return true;

      }
      catch (error) {
        if (error.code === USER_CANCELLED_REQUEST_CODE) {return;}
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message,
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(
          error.message || error,
        );

        notification.show();

        return;
      }
      finally {
        this.suggestionFetchController = null;
      }
    },
    generateHighlightText(entry) {
      let highlightText = '';

      if (entry.name)
      {highlightText += entry.name;}
      if (entry.weight)
      {highlightText += ` | ${entry.weight}`;}
      if (entry.alcoholPercentage)
      {highlightText += ` | ${entry.alcoholPercentage}%`;}

      return highlightText;
    },
    highlightText(haystack, needle) {
      let found = RegExp(`(${escapeRegex(needle)})`, 'gi');

      return haystack.replace(found, `<span>$1</span>`);
    },
    onInputChange(text) {
      this.suggestions = [];
      this.searchText = text;

      if (text === null || text.length < 3) {return;}

      clearTimeout(this.timeout);

      if (Boolean(this.suggestionFetchController)) {
        this.suggestionFetchController.abort();
      }

      this.timeout = setTimeout(() => {
        this.getSuggestions(text);
      }, this.debounceMilliseconds);
    },
    getSuggestionValue() {
      //let found = RegExp('</?span>', 'g');
      //return suggestion.item.name.replace(found, '');
      return this.searchText;
    },
    onSelected(selected) {
      if (selected) {
        if (HAS_DO_NOT_TRACK_DISABLED && window.dataLayer) {
          //push to Bloomreach pixel
          window.dataLayer.push({
            event: GTMEvents.SEARCH_SUGGESTIONS, // Bloomreach
            q: selected.item.name.replace(/<\/?[^>]*>/, ''), // Bloomreach
            aq: this.searchText, // Bloomreach
          });
        }

        window.location.href = selected.item.url;
      }
    },
    executeSearch() {
      const queryText = this.searchText ?? '';

      if (HAS_DO_NOT_TRACK_DISABLED && window.dataLayer) {
        //push to Bloomreach pixel
        window.dataLayer.push({
          event: GTMEvents.SEARCH_SUBMIT, // Bloomreach
          q: queryText.trim(), // Bloomreach
        });
      }

      // TODO more query param to html?
      window.location.href =
        this.searchUrl + '?query=' + encodeURIComponent(queryText.trim());
    },
  },
};
