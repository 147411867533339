import { DomLoadedCheck } from '../utils/dom-loaded-check';

const ALL_INPUT_FIELD_QUERY =
  '.form-validation__fill-at-least-one-input input:not([type="hidden"]):not([type="submit"])';
const SUBMIT_BUTTON_QUERY =
  '.form-validation__fill-at-least-one-input input[type="submit"]';
const FRAGMENT_CAPTCHA_CLASS = '.fragment--recaptcha';

function init() {
  const ALL_INPUT_FIELD_SELECTOR = document.querySelectorAll(
    ALL_INPUT_FIELD_QUERY,
  );
  const SUBMIT_BUTTON_SELECTOR = document.querySelector(SUBMIT_BUTTON_QUERY);
  const FRAGMENT_CAPTCHA_SELECTOR = document.querySelector(
    FRAGMENT_CAPTCHA_CLASS,
  );

  function addScrollToFirstInvalidFieldListener() {
    const submitButtons = document.querySelectorAll('input[type="submit"]');

    if (submitButtons.length > 0) {
      submitButtons.forEach(button => {
        button.addEventListener('click', () => {
          const firstInvalidInput = document.querySelector('input:invalid');
          const header = document.querySelector('header');

          if (firstInvalidInput) {
            const inputTopOffset = firstInvalidInput.getBoundingClientRect().y -
              document.body.getBoundingClientRect().y;

            window.scrollTo({
              top: inputTopOffset - header.offsetHeight,
            });
          }
        });
      })
    }
  }

  addScrollToFirstInvalidFieldListener();

  /** The logic in this file contains: if nonne of the input field is selected the form button should be disabled.
   * But if captcha is present then captcha logic will take over and captcha input to be selected has priority over
   * other input field.*/

  if (FRAGMENT_CAPTCHA_SELECTOR) {return;}
  if (ALL_INPUT_FIELD_SELECTOR.length < 1) {return;}

  function areAllInputFieldsEmpty(inputFields) {
    let count = 0;

    [].forEach.call(inputFields, item => {
      if (item.value === '') {
        count += 1;
      }
    });

    return count === ALL_INPUT_FIELD_SELECTOR.length;
  }

  function setSubmitButtonStateBasedOnInputEvents(inputFields, submitButton) {
    [].forEach.call(inputFields, item => {
      item.addEventListener('input', () => {
        if (!areAllInputFieldsEmpty(inputFields)) {
          submitButton.disabled = false;
        } else {
          submitButton.disabled = true;
        }
      });
    });
  }

  if (areAllInputFieldsEmpty(ALL_INPUT_FIELD_SELECTOR)) {
    SUBMIT_BUTTON_SELECTOR.disabled = true;
  }

  setSubmitButtonStateBasedOnInputEvents(
    ALL_INPUT_FIELD_SELECTOR,
    SUBMIT_BUTTON_SELECTOR,
  );
}

(() => {
  DomLoadedCheck(init);
})();
