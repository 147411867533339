import mutationTypes from "./mutationTypes";

const mutations = {
  [mutationTypes.UPDATE_TRANSLATIONS](state, newTranslations) {
    state.translations = Object.assign({}, state.translations, newTranslations);
  },
  [mutationTypes.DEFAULT_TRANSLATIONS_ADDED](state, status) {
    state.defaultTranslationsAdded = status;
  },
};

export default mutations;
