export const GTMEvents = {
  SEARCH_SUGGESTIONS :  'search-suggestions',
  SEARCH_SUBMIT: 'search-submit',
  REQUEST_STOCK : 'request-stock',
  OUT_OF_STOCK_REQUEST : 'out-of-stock-request',
  CLEAR_CART_SUBMIT : 'clear-cart-submit',
  ORDER_AND_REQUEST : 'order-and-request',
  UNSUBSCRIBE_STOCK_NOTIFICATION : 'unsubscribe-stock-notification',
  REQUEST_AND_NOTIFY : 'request-and-notify',
  SEARCH_BARCODE: 'search_barcode',
  BARCODE_SUCCESSFUL_SCAN_SINGLE : 'barcode_successful_scan_single',
  BARCODE_FAILED_SCAN_GENERAL_ERROR : 'barcode_failed_scan_general_error',
  BARCODE_FAILED_SCAN_MULTIPLE_SRP: 'barcode_successful_scan_multiple_srp',
}