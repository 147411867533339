const IS_BARCODE_ENABLED = 'barcode-scanning';
const IS_DISCOUNT_ENABLED = 'discounts';
const IS_SPOL_ENABLED = 'spol';
const IS_RATIONS_V2_ENABLED = 'rations-v2';

export {
  IS_BARCODE_ENABLED,
  IS_DISCOUNT_ENABLED,
  IS_SPOL_ENABLED,
  IS_RATIONS_V2_ENABLED,
}
