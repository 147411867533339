function getApiUrlPrefix() {
  let dataAttribute = 'data-api-url';

  return document.body.hasAttribute(dataAttribute)
    ? document.body.getAttribute(dataAttribute)
    : '/api';
}

function getContextUrlPrefix() {
  let dataAttribute = 'data-context-url';

  return document.body.hasAttribute(dataAttribute)
    ? document.body.getAttribute(dataAttribute)
    : '/';
}

/**
 * Vue urls
 */
export const VUE_URL__ADD_TO_CART = getApiUrlPrefix() + '/carts/mycart/line';
export const VUE_URL__ADD_DISCOUNT_TO_CART = getApiUrlPrefix() + '/carts/mycart/add-discount-code';
export const VUE_URL__CART_BULK_UPLOAD_CONFIG = getApiUrlPrefix() + '/reports/spol/config';
export const VUE_URL__CART_BULK_UPLOAD_VALIDATE = getApiUrlPrefix() + '/carts/mycart/lines?isValidationOnly=true';
export const VUE_URL__CART_BULK_UPLOAD = getApiUrlPrefix() + '/carts/mycart/lines?isValidationOnly=false';
export const VUE_URL__ADD_LIST_TO_CART =
  getApiUrlPrefix() + '/carts/mycart/add-shopping-list';
export const VUE_URL__CART_LOAD = getApiUrlPrefix() + '/carts/mycart';
export const VUE_URL__SHIPPING_METHOD =
  getApiUrlPrefix() + '/carts/mycart/shipping-method';

export const VUE_URL__CLEAR_CART =
  getApiUrlPrefix() + '/carts/mycart/clear';

export const VUE_URL__SUBSCRIBE_STOCK_NOTIFICATIONS =
  getApiUrlPrefix() + '/customers/mystocknotifications';
export const VUE_URL__UNSUBSCRIBE_STOCK_NOTIFICATIONS = getApiUrlPrefix() + '/customers/mystocknotifications/';

export const VUE_URL__USER_LIST =
  getApiUrlPrefix() + '/customers/user-list';

export const VUE_URL__SHOPPING_LISTS =
  getApiUrlPrefix() + '/shoppinglists/mylists';

export const VUE_URL__ORDERS = getApiUrlPrefix() + '/orders/myorders';

/* @TODO: Add correct api for reservations */
export const VUE_URL__RESERVATIONS = getApiUrlPrefix() + '/orders/myorders';
export const VUE_URL__GET_INVOICES = orderId =>
  `${getApiUrlPrefix()}/orders/myorders/${orderId}/invoices`;
export const VUE_URL__GET_INVOICE = (orderId, invoiceId) =>
  `${getApiUrlPrefix()}/orders/myorders/${orderId}/invoices/${invoiceId}`;

// Download SPOL (Stock and Price Overview List)
export const VUE_URL__GET_SPOL = getApiUrlPrefix() + '/reports/spol';
export const VUE_URL__GET_SPOL_AVAILABILITY = getApiUrlPrefix() + '/reports/spol/export-availability';

// Request stock
export const VUE_URL__REQUEST_STOCK = getApiUrlPrefix() + '/products/inventory/stock-request';

// Get products By barcode
export const VUE_URL__BARCODE_SEARCH = getApiUrlPrefix() + '/products/barcode-search';

//Payment
export const URL__PAYMENT_METHODS =
  getApiUrlPrefix() + '/payments/payment-methods';
export const URL__PAYMENT = getApiUrlPrefix() + '/payments/payment';
export const URL__PAYMENT_PAGE = getContextUrlPrefix() + '/payment-methods';

// Search API
export const VUE_URL__SEARCH_QUERY =
  getApiUrlPrefix() + '/search/products/query';
export const VUE_URL__SEARCH_FACET =
  getApiUrlPrefix() + '/search/products/facet';
export const VUE_URL__AUTO_SUGGEST =
  getApiUrlPrefix() + '/search/products/autosuggest';
export const VUE_URL__QUICK_ORDER_SUGGEST =
  getApiUrlPrefix() + '/search/quick-order/autosuggest';
export const VUE_URL__QUICK_ORDER_IMPORT =
  getApiUrlPrefix() + '/search/quick-order/import';

export const VUE_URL__SEARCH_LISTS_QUERY =
  getApiUrlPrefix() + '/search/my-lists-entries/query';

export const VUE_URL__ORDER_ACTIONS =
  getApiUrlPrefix() + '/orders/myorders/actions';

export const VUE_URL__DELIVERY_METHODS =
  getApiUrlPrefix() + '/shippingmethods/myshippingmethods';

// Search FE
export const VUE_URL_CTX__ROOT = getContextUrlPrefix();

// Products
export const VUE_URL__PRODUCT = getApiUrlPrefix() + '/products';

/**
 * Vue event names
 */
export const VUE_EVENT_NAME__SHOW_FILTERS = 'showFilters';

export const VUE_EVENT_NAME__OPEN_ADD_TO_LIST = 'openAddToList';
export const VUE_EVENT_NAME__OPEN_ADD_TO_LIST_CB = 'openAddToList-cb';
export const VUE_OPEN_ORDER_ACTION_DIALOG = 'openOrderActionDialog';

export const VUE_EVENT_NAME__ADD_PRODUCT_TO_LIST_CB = 'addProductToList-cb';

export const VUE_EVENT_NAME__UPDATE_DELIVERY_METHODS = 'updateDeliveryMethods';

export const VUE_EVENT_NAME__RERENDER_PAGINATION = 'rerenderPagination';

/**
 * Tracking
 */
export const HAS_DO_NOT_TRACK_DISABLED = !(
  window.navigator.hasOwnProperty('doNotTrack') && !!window.navigator.doNotTrack
);

/**
 * Screen size
 */
export const screenSizeBreakPoint = 1000;

export const algoliaRequiredType = "disjunctive";

/**
 * Delay for debouce
 */
export const SEARCH_DELAY = 1000;

export const UNIT_OF_MEASUREMENT = {
  CONTENT: 'I',
  PACKAGE: 'C',
  KILOGRAM: 'NT',
}

export const QUANTITY_TYPE = {
  CONTENT: 'Content',
  CASE: 'Case',
  LAYER: 'Layer',
  PALLET: 'Pallet',
}


/**
 * FETCH API ERROR CODE
 */
export const USER_CANCELLED_REQUEST_CODE = 20;

export const SEARCH_BY_BARCODE_MULTIPLE_SKU_URL = '/products-list?skuID=';