const state = {
  isInitialState: true,
  invalidLineItems: new Set(),
  hasInvalidLineItems: false,
  discountCode: {
    code: null,
    isValid: null,
    message: '',
  },
  data: {
    lineItems: [],
  },
};

export default state;