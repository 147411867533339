<template>
  <svg
    :class="iconClass"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :xlink:href="iconReference"
    ></use>
  </svg>
</template>

<script>
  export default {
    name: "VueIcon",
    props: {
      'iconName': {
        type: String,
        required: true,
      },
      'iconClass': {
        type: String,
        default: '',
      },
    },
    computed: {
      iconReference() {
        return `#icon--${this.iconName.toLowerCase()}`
      },
    },
  }
</script>
