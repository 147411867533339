<script>
/* This component does nothing. Really.
   The only thing it is used for (atm) is to add `:key` on it,
   because sometimes it is impossible to pass dynamic props via Thymol syntax.
   It does not provide any markup – so it does not affect layout.
 */
  export default {
    name: 'VueEmptyComponent',
    render() {
      return this.$scopedSlots.default({});
    },
  };
</script>
