import actions from './actions';
import mutations from './mutations';
import state from './state';
import getters from './getters';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state,
};

export const TRANSLATIONS_NAMESPACE = 'TRANSLATIONS_NAMESPACE';

export { default as mutationTypes } from './mutationTypes';
export { default as actionTypes } from './actionTypes';
export { default as getterTypes } from './getterTypes';
