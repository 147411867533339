import { DomLoadedCheck } from '../utils/dom-loaded-check';

const TERMS_AND_CONDITIONS_CHECKBOX = 'input.place-order-and-toc__toc-input';
const SUBMIT_BUTTON = '.message-button';
const FORM = '.review-form';

function init() {
  const termsAndConditionsInput = document.querySelector(TERMS_AND_CONDITIONS_CHECKBOX);
  const form = document.querySelector(FORM);
  const submitButton = document.querySelector(SUBMIT_BUTTON);

  if (!termsAndConditionsInput || !submitButton || !form) {
    return;
  }

  const inputs = form.querySelectorAll('input, select');

  inputs.forEach(input => {
    input.addEventListener('input', () => {
      const isFormValid = form.checkValidity();

      submitButton.disabled = !isFormValid || !termsAndConditionsInput.checked;
    })
  })

  submitButton.addEventListener('click', () => {
    if (form.checkValidity()) {
      submitButton.disabled = true;
      form.submit();
    }
  });
}

(() => {
  DomLoadedCheck(init);
})();
