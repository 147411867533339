import { createNamespacedHelpers } from 'vuex';

import {
  // HAS_DO_NOT_TRACK_DISABLED,
  VUE_URL__QUICK_ORDER_SUGGEST,
  VUE_URL__QUICK_ORDER_IMPORT,
  VUE_EVENT_NAME__UPDATE_DELIVERY_METHODS,
} from '../_global-constants';

import {
  FEATURE_NAMESPACE,
  getterTypes,
} from '../store/modules/features';

import { CART_NAMESPACE } from '../store/modules/cart/index';

import {
  CART_ACTION__ADD_TO_CART,
  CART_ACTION__INIT_CART,
  CART_ACTION__SHOW_ERROR,
} from '../store/modules/cart/actions';

import { openDialogEvent } from '../fragments/dialog';
import {http} from "../utils/http-requests";

const { mapActions } = createNamespacedHelpers(CART_NAMESPACE);
const { mapGetters } = createNamespacedHelpers(FEATURE_NAMESPACE);
const HAS_ERROR_CLASS_NAME = 'has-error';

export default {
  name: 'VueQuickOrder',

  props: {
    searchUrl: {
      type: String,
      required: true,
    },
  },

  render() {
    return this.$scopedSlots.default({
      onInputChange: this.onInputChange,
      suggestions: this.suggestions,
      getSuggestionValue: this.getSuggestionValue,
      sectionConfigs: this.sectionConfigs,
      quickOrderHandler: this.quickOrderHandler,
      selectedSuggestion: this.selectedSuggestion,
      fileImport: this.fileImport,
      downloadForm: this.downloadForm,
      closeForm: this.closeForm,
      productsNumber: this.productsNumber,
      changeProductsNumber: this.changeProductsNumber,
      isInvalid: this.isInvalid,
      isSpolEnabled: this.isSpolEnabled,
    });
  },

  data() {
    return {
      searchText: '',
      timeout: null,
      debounceMilliseconds: 500,
      sectionConfigs: {
        default: {
          onSelected: this.executeSearch,
        },
        products: {
          limit: 8,
          onSelected: this.onSelected,
        },
      },
      selectedSuggestion: null,
      productsNumber: this.selectedSuggestion ? this.selectedSuggestion.masterVariant.orderEntryRules.step : '0',
      suggestions: [],
      downloadLink: '',
    };
  },

  computed: {
    ...mapGetters({
      isSpolEnabled: getterTypes.IS_SPOL_ENABLED,
    }),
    isInvalid() {
      if (this.selectedSuggestion && Number(this.productsNumber) !== 0) {
        const { availableQuantity, totalItemsInCart, maxOrderQuantity } = this.selectedSuggestion.masterVariant;
        const min = Math.min(availableQuantity, maxOrderQuantity || Infinity);

        return min - Number(totalItemsInCart) < this.productsNumber;
      }

      return true;
    },
  },

  methods: {
    ...mapActions({
      addCart: CART_ACTION__ADD_TO_CART,
      initCart: CART_ACTION__INIT_CART,
      showError: CART_ACTION__SHOW_ERROR,
    }),

    changeProductsNumber({ target: { value } }) {
      this.productsNumber = value;
    },

    getSearchField() {
      return document.getElementById('quick-order__input');
    },

    getQuantityField() {
      return this.$el.querySelector(
        '.quick-order__quantity-field input[name="requestedQty"]',
      );
    },

    getQuantity() {
      let quantityElement = this.getQuantityField();

      return quantityElement.value || 1;
    },

    getSuggestions: async function(text) {
      let url = VUE_URL__QUICK_ORDER_SUGGEST + '?q=' + encodeURIComponent(text);

      try {
        let response = await http.get(url);

        this.suggestions.push({
          name: 'products',
          data: response.productsSuggestions,
        });

        return true;
      }
      catch(error) {
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message,
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(
          error.message || error,
        );

        notification.show();

        return true;
      }
    },

    onInputChange(text) {
      this.suggestions = [];
      this.selectedSuggestion = null;
      this.searchText = text;

      let requestedQtyField = this.getQuantityField();

      requestedQtyField.classList.remove(HAS_ERROR_CLASS_NAME);

      if (text === null || text.length < 3) {return;}

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getSuggestions(text);
      }, this.debounceMilliseconds);
    },

    generateLabel(productData) {
      let label = productData.name;

      if (!productData.hasOwnProperty('masterVariant')) {return label;}

      if (productData.masterVariant.primaryAttribute && productData.masterVariant.primaryAttribute.length) {
        label += `, ${productData.masterVariant.primaryAttribute}`;
      }

      if (productData.masterVariant.secondaryAttribute && productData.masterVariant.secondaryAttribute.length) {
        label += `, ${productData.masterVariant.secondaryAttribute}`;
      }

      if (productData.masterVariant.price && productData.masterVariant.price.length) {
        label += `, ${productData.masterVariant.price}`;
      }

      return label;
    },

    getSuggestionValue(suggestion) {
      this.searchText = this.generateLabel(suggestion.item);

      return this.searchText;
    },

    onSelected(selected) {
      if (selected) {
        this.productsNumber = 1;
        this.selectedSuggestion = selected.item;
        this.searchText = this.generateLabel(selected.item.name);

        setTimeout(() => {
          let requestedQtyField = this.getQuantityField();

          // requestedQtyField.focus();
          requestedQtyField.select();
        }, 0);

        return true;
      }
    },

    executeSearch(item, originalInput) {
      if (originalInput === null) {
        /* eslint-disable-next-line no-param-reassign */
        originalInput = '';
      }

      // if (HAS_DO_NOT_TRACK_DISABLED && window.dataLayer) {
      //   //push to Bloomreach pixel
      //   window.dataLayer.push({
      //     event: 'search-submit', // Bloomreach
      //     q: originalInput.trim() // Bloomreach
      //   });
      // }

      let searchField = this.getSearchField();

      searchField.select();
    },

    addToCartCallback({ isSuccess }) {
      if (isSuccess) {
        this.suggestions = [];
        this.selectedSuggestion = null;
        this.$root.$emit(VUE_EVENT_NAME__UPDATE_DELIVERY_METHODS);

        setTimeout(() => {
          this.$el.reset();

          const searchField = this.getSearchField();

          searchField.focus();

          this.$root.$refs.quickOrderAutoSuggest.searchInput = '';
          this.productsNumber = 0;
        }, 0);
      } else {
        let requestedQtyField = this.getQuantityField();

        requestedQtyField.classList.add(HAS_ERROR_CLASS_NAME);
        requestedQtyField.select();
      }

      return true;
    },

    quickOrderHandler() {
      if (!this.selectedSuggestion) {return false;}

      let loader = this.$loading.show({
        active: true,
        container: this.$root.$refs.quickOrder,
      });

      return this.addCart({
        sku: this.selectedSuggestion.masterVariant.sku,
        quantity: this.getQuantity(),
      })
        .then(this.addToCartCallback)
        .catch(this.showError)
        .finally(() => {
          loader.hide();
        });
    },

    async fileImport(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length)
      {return;}

      let loader = this.$loading.show({
        active: true,
        container: this.$el,
      });
      let formData = new FormData();

      formData.append('file', files[0]);

      try {
        let response = await http.post(VUE_URL__QUICK_ORDER_IMPORT, formData);

        this.downloadLink = response.downloadLink;
        if (response.failedItems > 0) {
          openDialogEvent(
            e,
            this.$el.querySelector('.show-dialog-csv'),
          );
        }

        return this.initCart(true)
      }
      catch (error) {
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message,
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(
          error.message || error,
        );

        notification.show();

        return true;
      }
      finally {
        loader.hide();
        e.target.value='';
      }
    },

    closeForm() {
      let dialog = this.$root.$refs.dialogCSVFail;

      if (dialog && dialog.open) {
        dialog.close();
        document.body.style.overflowY = 'auto';
      }
    },

    downloadForm() {
      location.href = this.downloadLink;
    },
  },
};
