<template>
  <div v-if="isBarcodeFeatureEnabled">
    <vue-alert
      v-if="showPermissionDeniedError"
      :message="translations.barcodeCameraAccessBlock"
      level="error"
      isActiveByDefault
      isGlobal
      canClose
      class="barcode-alert"
      @close="closeErrorAlert"
    />
    <vue-barcode-button
      :isInnerSearch="isInnerSearch"
      @click="showBarcodeModal"
    />
    <vue-popup
      v-if="permissionStatusMsg"
      :title="translations.barcodeCameraAccessRequest"
      :hasCloseBtn="false"
    >
      <template #content>
        {{ permissionStatusMsg }}
      </template>
    </vue-popup>

    <vue-barcode-modal
      v-if="hasModalShown"
      @scan="onScan"
      @stopScan="hideBarcodeModal"
      @error="onError"
      @messageUpdate="v => permissionStatusMsg = v"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import {
    TRANSLATIONS_NAMESPACE,
    getterTypes as translationGetterTypes,
  } from '../../../store/modules/translations';
  import {
    FEATURE_NAMESPACE,
    getterTypes,
  } from '../../../store/modules/features';
  import VueAlert from '../../alert/alert.vue';
  import VueBarcodeButton from '../barcode-button/barcode-button.vue';
  import VueBarcodeModal from '../barcode-modal/barcode-modal.vue';
  import VuePopup from '../../popup.vue';
  import { PRODUCT_NAMESPACE, actionTypes } from '../../../store/modules/product';
  import { GTMEvents } from '../../../consts/gtm_events';
  import { SEARCH_BY_BARCODE_MULTIPLE_SKU_URL } from '../../../_global-constants';

  const ERROR_FOR_CAMERA_DENIED = 'NotAllowedError';

  export default {
    name: 'VueBarcodeScanner',

    components: {
      VueAlert,
      VueBarcodeButton,
      VueBarcodeModal,
      VuePopup,
    },

    props: {
      isInnerSearch: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        hasModalShown: false,
        showPermissionDeniedError: false,
        loader: null,
        permissionStatusMsg: '',
      };
    },

    computed: {
      ...mapGetters(TRANSLATIONS_NAMESPACE, {
        translations: translationGetterTypes.TRANSLATIONS,
      }),

      ...mapGetters(FEATURE_NAMESPACE, {
        isBarcodeFeatureEnabled: getterTypes.IS_BARCODE_ENABLED,
      }),
    },

    methods: {
      ...mapActions(PRODUCT_NAMESPACE, {
        fetchSKUsByBarcodeScan: actionTypes.FETCH_SKUS_BY_BARCODESCAN,
      }),

      closeErrorAlert() {
        this.showPermissionDeniedError = false;
        this.hasModalShown = false;
      },

      showBarcodeModal() {
        window.dataLayer.push({
          event: GTMEvents.SEARCH_BARCODE,
        });

        this.hasModalShown = true;
      },

      hideBarcodeModal() {
        this.hasModalShown = false;
      },

      async onScan(decodedText) {
        this.loader = this.$loading.show({ active: true, zIndex: 999 });

        if (!this.validateBarcode(decodedText)) {
          this.handleBarcodeScanErrors(this.translations.barcodeScanFailed, 'error');

          return false
        }
        try {
          const { skus } = await this.fetchSKUsByBarcodeScan(decodedText);
          const rootLink = window.BNS.rootLink == '/' ? '' : window.BNS.rootLink;
          let url;

          switch (skus.length) {
            case 0:
              this.handleBarcodeScanErrors(this.translations.barcodeScanNoResult,'warning');
              break;
            case 1:
              window.dataLayer.push({
                event: GTMEvents.BARCODE_SUCCESSFUL_SCAN_SINGLE,
              });
              url = location.origin + rootLink + '/products/' + skus[0];
              window.location.href = url;
              break;
            default:
              window.dataLayer.push({
                event: GTMEvents.BARCODE_FAILED_SCAN_MULTIPLE_SRP,
              });
              url =
                location.origin +
                rootLink +
                SEARCH_BY_BARCODE_MULTIPLE_SKU_URL +
                skus.join(',');
              window.location.href = url;
              break;
          }
        } catch (error) {
          this.handleBarcodeScanErrors(this.translations.barcodeScanFailed, 'error');
        }
      },

      handleBarcodeScanErrors(msg, errLevel) {
        //hide the loader ONLY on error, in case of success redirect will happen anyway
        this.loader?.hide();

        window.dataLayer.push({
          event: GTMEvents.BARCODE_FAILED_SCAN_GENERAL_ERROR,
        });

        window.BNS.globalNotification(msg, errLevel).show();
      },

      onError(error) {
        if (error.toString().includes(ERROR_FOR_CAMERA_DENIED)) {
          this.showPermissionDeniedError = true;
        }
      },

      validateBarcode(barcode) {
        return /^\d{8,}$/g.test(barcode);
      },
    },
  };
</script>
