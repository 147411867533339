import { DomLoadedCheck } from '../utils/dom-loaded-check';

const CAPTCHA_ERROR_QUERY = '.recaptcha__error';
const BUTTON_QUERY = '.has-recaptcha';
const FRAGMENT_CAPTCHA_CLASS = '.fragment--recaptcha';
const E_FORM_SUBMIT_BUTTON_ID = 'form-submit-button';
const PAGE_FORGOTTEN_PASSWORD_CLASS = '.page--password-forgotten';
const RE_CAPTCHA_FORM_ELEMENT = 'recaptcha-response';

function init() {
  const CAPTCHA_ERROR_SELECTOR = document.querySelector(CAPTCHA_ERROR_QUERY);
  const SUBMIT_BUTTON_SELECTOR = document.querySelector(BUTTON_QUERY);
  const FRAGMENT_CAPTCHA_SELECTOR = document.querySelector(
    FRAGMENT_CAPTCHA_CLASS,
  );
  const eFormSubmitButtonSelector = document.getElementById(
    E_FORM_SUBMIT_BUTTON_ID,
  );
  const pagePasswordForgotten = document.querySelector(
    PAGE_FORGOTTEN_PASSWORD_CLASS,
  );
  const reCaptchaFormElement = document.getElementById(
    RE_CAPTCHA_FORM_ELEMENT,
  );

  if (!FRAGMENT_CAPTCHA_SELECTOR && pagePasswordForgotten) {
    SUBMIT_BUTTON_SELECTOR.disabled = false;
  }

  if (!FRAGMENT_CAPTCHA_SELECTOR) {return;}

  if (eFormSubmitButtonSelector) {
    eFormSubmitButtonSelector.disabled = true;
  }

  function showError() {
    if (SUBMIT_BUTTON_SELECTOR.disabled || eFormSubmitButtonSelector.disabled) {
      return;
    }

    if (SUBMIT_BUTTON_SELECTOR) {
      SUBMIT_BUTTON_SELECTOR.disabled = true;
    }

    if (eFormSubmitButtonSelector) {
      eFormSubmitButtonSelector.disabled = true;
    }
  }

  window.cptchCllbck = () => {
    const response = grecaptcha.getResponse();

    if (response && SUBMIT_BUTTON_SELECTOR) {
      SUBMIT_BUTTON_SELECTOR.disabled = false;
      if (CAPTCHA_ERROR_SELECTOR) {
        CAPTCHA_ERROR_SELECTOR.classList.add('is-hidden');
      }
      if (reCaptchaFormElement) {
        reCaptchaFormElement.value = response;
      }
    }

    if (response && eFormSubmitButtonSelector) {
      eFormSubmitButtonSelector.disabled = false;
      if (CAPTCHA_ERROR_SELECTOR) {
        CAPTCHA_ERROR_SELECTOR.classList.add('is-hidden');
      }
      if (reCaptchaFormElement) {
        reCaptchaFormElement.value = response;
      }
    }
  };

  window.cptchRrr = showError;
  window.cptchXprd = showError;
}

(() => {
  DomLoadedCheck(init);
})();
