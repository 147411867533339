import {
  VUE_URL__SHOPPING_LISTS,
  VUE_EVENT_NAME__OPEN_ADD_TO_LIST,
  VUE_EVENT_NAME__ADD_PRODUCT_TO_LIST_CB,
} from '../_global-constants';

import { http } from '../utils/http-requests';

const regExpForSpecialSymbols = /[?¿¡"`‘“”«»#%&@^=~$*+±§.,:;|‒–—―‐‹›<>{}⟨⟩/[\]()\\]/gi;
const sanitizeOfValue = (value, regExp) => value.replace(regExp, '');

export default {
  name: 'VueAddToList',
  render() {
    return this.$scopedSlots.default({
      addNewList: this.addNewList,
      addProductToList: this.addProductToList,
      goToCreateList: this.goToCreateList,
      goToLists: this.goToLists,
      customerLists: this.customerListsJson,
      isListView: this.isListView,
      isMaxListCountReached: this.isMaxListCountReached,
      isMaxProductCountReached: this.isMaxProductCountReached,
      currentPath: location.href,
      closeDialog: this.closeDialog,
      listName: this.listName,
      updateListName: this.updateListName,
      isListNameInvalid: this.isListNameInvalid,
      isAddtoListFailed: this.isAddtoListFailed,
      addToListError: this.addToListError,
      isListAlreadyExists: this.isListAlreadyExists,
    });
  },

  props: {
    origin: {
      required: true,
    },
    maxNumberOfLists: {
      required: true,
    },
    page: {
      required: false,
    },
  },

  data: function() {
    return {
      customerListsJson: [],
      isListView: true,
      maxListCount: this.maxNumberOfLists,
      isMaxProductCountReached: false,
      productId: 0,
      maxProductCount: 250,
      listName: '',
      isAddtoListFailed: false,
      addToListError: '',
      isListAlreadyExists: false,
    };
  },

  watch: {
    listAlreadyExists(isExist) {
      this.isListAlreadyExists = isExist;
    },
  },

  computed: {
    listNameTrimmed() {
      return this.listName.toLowerCase().trim();
    },

    listAlreadyExists() {
      return this.customerListsNames.has(this.listNameTrimmed);
    },

    customerListsNames() {
      const listNames = new Set();

      this.customerListsJson.forEach(({ name }) => listNames.add(name.toLowerCase()))

      return listNames;
    },

    isListNameInvalid() {
      return this.isListAlreadyExists || !Boolean(this.listNameTrimmed);
    },

    isMaxListCountReached() {
      return this.customerListsJson.length >= this.maxListCount;
    },
  },

  mounted: function() {
    this.$root.$on(VUE_EVENT_NAME__OPEN_ADD_TO_LIST, this.openAddToList);

    this.getLists();
    this.isAddtoListFailed = false;
  },

  methods: {
    openAddToList: function(productId) {
      this.isListView = true;
      document.body.style.overflowY = 'hidden';
      this.productId = productId;
      this.getLists();
    },

    goToCreateList: function() {
      const input = this.$root.$refs.listName

      this.isListView = false;
      this.$nextTick(function() {
        input.focus();
      });
    },

    goToLists: function() {
      this.listName = '';
      this.isListView = true;
    },

    getLists: async function() {
      let url = VUE_URL__SHOPPING_LISTS + '?type=';
      let types = ['custom'];

      if (this.origin === 'add-to-list-popup') {
        types.push('favorites');
      }

      url += types.join(',');

      let loader = this.$loading.show({
        active: true,
        container: this.$el,
      });

      try {
        let response = await http.get(url);

        this.customerListsJson = response;
      }
      catch(error) {
        if (error.hasOwnProperty('response')) {

          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message,
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(
          error.message || error,
        );

        notification.show();

        return;

      }
      finally {
        await loader.hide();
      }
    },

    addNewList: async function() {
      let listName = this.$root.$refs.listName.value;

      if (this.isListNameInvalid) {
        return;
      }
      let cleanListName = sanitizeOfValue(listName, regExpForSpecialSymbols);
      let jsonRequest = {
        name: cleanListName,
      };
      let loader = this.$loading.show({
        active: true,
        container: this.$el,
      });

      try {
        let response = await http.post(VUE_URL__SHOPPING_LISTS, jsonRequest);
        let newItem = {
          id: response.id,
          name: response.name,
          lineItems: response.lineItems,
          relativeUrl: response.relativeUrl,
        };

        this.customerListsJson.push(newItem);
        this.listName = '';

        if (this.origin === 'add-to-list-popup') {
          return this.addProductToList(newItem.id, 0);
        } else {
          this.isListView = true;

          return true;
        }

      } catch (error) {
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message,
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(
          error.message || error,
        );

        notification.show();

        return;
      }
      finally {
        loader.hide();
      }
    },

    addProductToList: async function(listId, listItemCount) {
      if (listItemCount && listItemCount >= this.maxProductCount) {
        this.isMaxProductCountReached = true;

        return;
      }

      if (this.productId && listId) {
        let jsonRequest = {
          variantId: 1,
          productId: this.productId,
        };
        let url = VUE_URL__SHOPPING_LISTS + '/' + listId + '/entry';
        let loader = this.$loading.show({
          active: true,
          container: this.$el,
        });

        try {
          await http.post(url, jsonRequest);

          await this.$root.$emit(VUE_EVENT_NAME__ADD_PRODUCT_TO_LIST_CB);

          this.closeDialog();

          return true;
        }
        catch (error) {
          if (error.hasOwnProperty('response')) {
            error.response
              .json()
              .then(errResp => {
                this.isAddtoListFailed = true;
                this.addToListError = errResp.message;

                return true;
              })
              .catch(e => {
                console.error(e);

                return true;
              });
          } else {
            this.isAddtoListFailed = true;
            this.addToListError = error.message || error
          }

          return;
        }
        finally {
          loader.hide();
        }
      }
    },

    closeDialog: function() {

      let dialog = this.$root.$refs.dialogList;

      dialog && dialog.close();
      this.isMaxProductCountReached = false;
      this.isAddtoListFailed = false;
      this.$root.$refs.listName.value = '';
      this.listName = '';
      this.isListAlreadyExists = false;
      document.body.style.overflowY = 'auto';
    },

    updateListName(event) {
      let { value } = event.target;

      this.listName = value;
      this.$root.$refs.listName.value = sanitizeOfValue(value, regExpForSpecialSymbols);
    },
  },
};
