<template>
  <div
    class="fragment--slidedown-panel"
  >
    <div
      :class="[
        'header',
        'font-h3',
        'text-uppercase',
        { opened: isContentVisible }
      ]"
      @click="onClick"
    >
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div v-show="isContentVisible">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VueSlidedownPanel',

    props: {
      title: {
        type: String,
        default: '',
      },

      isOpened: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        isContentVisible: this.isOpened,
      };
    },

    methods: {
      onClick() {
        this.isContentVisible = !this.isContentVisible;
      },
    },

    watch: {
      isOpened(newVal) {
        this.isContentVisible = newVal;
      },
    },
  };
</script>