import { createNamespacedHelpers, mapGetters } from 'vuex';

import { CART_NAMESPACE } from '../../store/modules/cart/index';
import {
  CART_ACTION__ADD_TO_CART,
  CART_ACTION__SHOW_ERROR,
} from '../../store/modules/cart/actions';
import {http} from "../../utils/http-requests";

import { fillPlaceholderWithValue } from '../../utils';
import { calculateQuantityWithMfa } from '../../utils/calculate-quantity-with-mfa';

import { debounce } from '../../utils/debounce';
import { openDialogEvent } from '../dialog';
import { PRODUCT_NAMESPACE } from '../../store/modules/product/index';
import { TRANSLATIONS_NAMESPACE } from '../../store/modules/translations/index';

import {
  VUE_EVENT_NAME__OPEN_ADD_TO_LIST,
  VUE_URL__SHOPPING_LISTS,
  VUE_EVENT_NAME__ADD_PRODUCT_TO_LIST_CB,
  VUE_URL__PRODUCT,
  UNIT_OF_MEASUREMENT,
  QUANTITY_TYPE,
} from '../../_global-constants';
import { GTMEvents } from '../../consts/gtm_events';

const ERROR_KEYS = {
  QUANTITY: 'quantity',
  TRADING_QUANTITY: 'trading_quantity',
};
const { mapActions: mapActionsCart } = createNamespacedHelpers(CART_NAMESPACE);
const { mapActions: mapActionsProduct} = createNamespacedHelpers(PRODUCT_NAMESPACE);

export default {
  name: 'VueProductTile',

  render() {
    return this.$scopedSlots.default({
      addToCart: this.addToCart,
      translateQuantity: this.translateQuantity,
      onSelect: this.onSelect,
      updateQuantity: this.updateQuantity,
      updateTradingPrice: this.updateTradingPrice,
      trackLoginToOrder: this.trackLoginToOrder,
      qty: this.qty,
      quantityTypes: this.quantityTypes,
      inputStep: this.inputStep,
      currentQuantityType: this.currentQuantityType,
      tradingPrice: this.tradingPrice,
      addedQuantity: this.addedQuantity,
      requestQuote: this.requestQuote,
      subscribeStockNotifications: this.subscribeStockNotifications,
      isSubscribedStockNotifications: this.isSubscribedStockNotifications,
      isSubscribedStockNotificationsTransition: this.isSubscribedStockNotificationsTransition,
      subscribeStockNotificationsMessageSuccess: this.subscribeStockNotificationsMessageSuccess,
      isApproved: this.isApproved,
      isLoading: this.isLoading,
      isDisabled: this.isDisabled,
      isTradingOffer: this.isTradingOffer,
      product: this.productDataVariant,
      openAddToList: this.openAddToList,
      removeFromList: this.removeFromList,
      isRemoved: this.isRemoved,
      isFavourite: this.isFavourite,
      isTradingOpen: this.isTradingOpen,
      updateTradingState: this.updateTradingState,
      showErrors: this.showErrors,
      errorKeys: ERROR_KEYS,
      errorMessage: this.errorMessage,
      loggedIn: this.user,
      productVariants: this.productVariants,
      showMoreVariants: this.showMoreVariants,
      hideMoreVariants: this.hideMoreVariants,
      isMoreVariantOpen: this.isMoreVariantOpen,
      selectVariant: this.selectVariant,
      isRationCustomerBoolean: this.isRationCustomerBoolean,
      isOrderAndRequestAvailable: this.isOrderAndRequestAvailable,
      orderAndRequest: this.orderAndRequest,
      isModalActive: this.isModalActive,
      showModal: this.showModal,
      isModalShown: this.isModalShown,
      hideModal: this.hideModal,
      requestStockAndNotify: this.requestStockAndNotify,
      handleSubscription: this.handleSubscription,
      isRequestStockDisabled: this.isRequestStockDisabled,
      isInputAvailable: this.isInputAvailable,
      isStockNotificationHidden: this.isStockNotificationHidden,
      shownLifecycleLabels: this.shownLifecycleLabels,
      expectedStockArrivalMessage: this.expectedStockArrivalMessage,
      listName: this.listName,
      updateListName: this.updateListName,
      updateErrorMessage: this.updateErrorMessage,
      showRequestNotifyPopup: this.showRequestNotifyPopup,
      hideNotifyModal: this.hideNotifyModal,
      disabledRequest: this.disabledRequest,
      isIncorrectTradingPrice: this.isIncorrectTradingPrice,
    });
  },

  provide() {
    const addedQuantityData = {
      updateAddedQuantity: this.updateAddedQuantity,
    }

    Object.defineProperty(addedQuantityData, "addedQuantity", {
      enumerable: true,
      get: () => this.addedQuantity,
    })

    return {
      addedQuantityData,
    }
  },

  props: {
    initialProduct: {
      required: true,
    },

    ssr: {
      type: Boolean,
      default: true,
    },

    user: {
      type: Boolean,
      default: true,
    },

    shoppingListId: {
      required: false,
    },

    isRationCustomer: {
      type: [String, Boolean],
      default: true,
    },

    isRequestOutOfStockQuantityAvailable: {
      type: String,
      default: 'false',
    },

    isModalActive: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(TRANSLATIONS_NAMESPACE, ['translations']),

    productDataVariant: function() {
      if (typeof this.product === 'string') {
        return JSON.parse(this.product);
      }

      return this.product;
    },

    inputStep() {
      return this.currentQuantityType === QUANTITY_TYPE.CONTENT
        ? this.productDataVariant.masterVariant.orderEntryRules.step
        : 1;
    },

    isOrderAndRequestAvailable() {
      return this.isRequestOutOfStockQuantityAvailable === 'true';
    },

    shownLifecycleLabels() {
      return this.productDataVariant.masterVariant.lifecycleLabels.slice(0,2);
    },

    currentProductUom() {
      return this.productDataVariant.masterVariant.orderUOM;
    },

    quantityTypes() {
      if (this.currentProductUom === UNIT_OF_MEASUREMENT.CONTENT) {
        return this.quantityTypesForContent;
      }

      if (this.currentProductUom === UNIT_OF_MEASUREMENT.PACKAGE) {
        return this.quantityTypesForPackage;
      }

      if (this.currentProductUom === UNIT_OF_MEASUREMENT.KILOGRAM) {
        return this.quantityTypesForKilogram;
      }
    },

    quantityTypesForContent() {
      let resultTypes = [];

      resultTypes.push(QUANTITY_TYPE.CONTENT);

      if (this.isCaseAvaliableForContent) {
        resultTypes.push(QUANTITY_TYPE.CASE);
      }

      if (this.isLayerAvaliableForContent) {
        resultTypes.push(QUANTITY_TYPE.LAYER);
      }

      if (this.isPalletAvaliableForContent) {
        resultTypes.push(QUANTITY_TYPE.PALLET);
      }

      return resultTypes;
    },

    quantityTypesForPackage() {
      let resultTypes = [];

      resultTypes.push(QUANTITY_TYPE.CASE);

      if (this.isLayerAvaliableForPackage) {
        resultTypes.push(QUANTITY_TYPE.LAYER);
      }

      if (this.isPalletAvaliableForPackage) {
        resultTypes.push(QUANTITY_TYPE.PALLET);
      }

      return resultTypes;
    },

    quantityTypesForKilogram() {
      let resultTypes = [];

      resultTypes.push(QUANTITY_TYPE.CONTENT);

      if (this.isCaseAvaliableForKilogram) {
        resultTypes.push(QUANTITY_TYPE.CASE);
      }

      if (this.isLayerAvaliableForKilogram) {
        resultTypes.push(QUANTITY_TYPE.LAYER);
      }

      if (this.isPalletAvaliableForKilogram) {
        resultTypes.push(QUANTITY_TYPE.PALLET);
      }

      return resultTypes;
    },

    isCaseAvaliableForContent() {
      return Boolean(
        this.productDataVariant.masterVariant.colloAmount
        && this.productDataVariant.masterVariant.unitAmount,
      );
    },

    isLayerAvaliableForContent() {
      return Boolean(
        this.productDataVariant.masterVariant.colloAmount
        && this.productDataVariant.masterVariant.unitAmount
        && this.productDataVariant.masterVariant.quantityPerFlow,
      );
    },

    isPalletAvaliableForContent() {
      return Boolean(
        this.productDataVariant.masterVariant.colloAmount
        && this.productDataVariant.masterVariant.unitAmount
        && this.productDataVariant.masterVariant.quantityPerFlow
        && this.productDataVariant.masterVariant.quantityFlows,
      );
    },

    isLayerAvaliableForPackage() {
      return Boolean(this.productDataVariant.masterVariant.quantityPerFlow);
    },

    isPalletAvaliableForPackage() {
      return Boolean(
        this.productDataVariant.masterVariant.quantityPerFlow
        && this.productDataVariant.masterVariant.quantityFlows,
      );
    },

    isCaseAvaliableForKilogram() {
      return Boolean(this.productDataVariant.masterVariant.weight);
    },

    isLayerAvaliableForKilogram() {
      return Boolean(
        this.productDataVariant.masterVariant.weight
        && this.productDataVariant.masterVariant.quantityPerFlow,
      );
    },

    isPalletAvaliableForKilogram() {
      return Boolean(
        this.productDataVariant.masterVariant.weight
        && this.productDataVariant.masterVariant.quantityPerFlow
        && this.productDataVariant.masterVariant.quantityFlows,
      );
    },

    multiplyContent() {
      if (this.currentQuantityType === QUANTITY_TYPE.CONTENT) {
        return this.qty;
      }

      if (this.currentQuantityType === QUANTITY_TYPE.CASE) {
        return this.multiplyContentByCase;
      }

      if (this.currentQuantityType === QUANTITY_TYPE.LAYER) {
        return this.multiplyContentByLayer;
      }

      if (this.currentQuantityType === QUANTITY_TYPE.PALLET) {
        return this.multiplyContentByPallet;
      }
    },

    multiplyContentByCase() {
      return this.qty *
        this.productDataVariant.masterVariant.colloAmount *
        this.productDataVariant.masterVariant.unitAmount;
    },

    multiplyContentByLayer() {
      return this.multiplyContentByCase * this.productDataVariant.masterVariant.quantityPerFlow;
    },

    multiplyContentByPallet() {
      return this.multiplyContentByLayer * this.productDataVariant.masterVariant.quantityFlows;
    },

    multiplyPackage() {
      if (this.currentQuantityType === QUANTITY_TYPE.CASE) {
        return this.qty;
      }

      if (this.currentQuantityType === QUANTITY_TYPE.LAYER) {
        return this.multiplyPackageByLayer;
      }

      if (this.currentQuantityType === QUANTITY_TYPE.PALLET) {
        return this.multiplyPackageByPallet;
      }
    },

    multiplyPackageByLayer() {
      return this.qty * this.productDataVariant.masterVariant.quantityPerFlow;
    },

    multiplyPackageByPallet() {
      return this.multiplyPackageByLayer * this.productDataVariant.masterVariant.quantityFlows;
    },

    multiplyKilogram() {
      if (this.currentQuantityType === QUANTITY_TYPE.CONTENT) {
        return this.qty;
      }

      if (this.currentQuantityType === QUANTITY_TYPE.CASE) {
        return this.multiplyKilogramByCase;
      }

      if (this.currentQuantityType === QUANTITY_TYPE.LAYER) {
        return this.multiplyKilogramByLayer;
      }

      if (this.currentQuantityType === QUANTITY_TYPE.PALLET) {
        return this.multiplyKilogramByPallet;
      }
    },

    multiplyKilogramByCase() {
      return this.qty * this.productDataVariant.masterVariant.weight;
    },

    multiplyKilogramByLayer() {
      return this.multiplyKilogramByCase * this.productDataVariant.masterVariant.quantityPerFlow;
    },

    multiplyKilogramByPallet() {
      return this.multiplyKilogramByLayer * this.productDataVariant.masterVariant.quantityFlows;
    },

    expectedStockArrivalMessage() {
      return defaultTranslations.expectedStockArrivalMessage
        .replace('{0}', this.productDataVariant.masterVariant.expectedStockArrivalInDays);
    },
  },

  data() {
    return {
      qty: 1,
      currentQuantityType: null,
      tradingPrice: null,
      addedQuantity: 0,
      product: this.initialProduct,
      isSubscribedStockNotifications: false,
      isApproved: false,
      isLoading: false,
      isDisabled: false,
      isTradingOffer: false,
      isSubscribedStockNotificationsTransition: false,
      subscribeStockNotificationsMessageSuccess: '',
      isRemoved: false,
      isFavourite: false,
      isTradingOpen: false,
      showErrors: false,
      errorMessage: {
        [ERROR_KEYS.QUANTITY]: '',
        [ERROR_KEYS.TRADING_QUANTITY]: '',
      },
      productVariants: [],
      isMoreVariantOpen: false,
      isRationCustomerBoolean: String(this.isRationCustomer) !== "false",
      isModalShown: false,
      isRequestStockDisabled: false,
      isInputAvailable: true,
      isStockNotificationHidden: true,
      listName: '',
      showRequestNotifyPopup: false,
      quantityChanged: false,
      isIncorrectTradingPrice: false,
    };
  },

  methods: {
    ...mapActionsCart({
      addCart: CART_ACTION__ADD_TO_CART,
      showError: CART_ACTION__SHOW_ERROR,
    }),
    ...mapActionsProduct(['requestStock', 'removeSubscription', 'addSubscription']),

    showModal() {
      this.isModalShown = true;

      window.dataLayer.push({
        event: GTMEvents.ORDER_AND_REQUEST,
      });
    },

    hideModal() {
      this.isModalShown = false;
    },

    hideNotifyModal() {
      this.showRequestNotifyPopup = false;
    },

    trackLoginToOrder(e) {
      let trackData = { event: 'login-to-order' };

      if (window.BrTrk) {
        e.preventDefault();
        trackData = {
          ...trackData,
          eventCallback: () => {
            window.location = e.target.href;
          },
        };
      }

      window.BNS.addNonBlacklistedDataToDataLayer(trackData);
    },

    updateTradingState(e) {
      this.isTradingOpen = e.target.checked;
    },

    updateDisability() {
      const qty = Number(this.getQty());
      const { availableQuantity, maxOrderQuantity } = this.productDataVariant.masterVariant;
      const isQtyAbsent = qty === 0;
      const addedQty = Number(this.addedQuantity);
      const availableQtyNumber = Number(availableQuantity);
      const maxOrderQtyNumber = Number(maxOrderQuantity);
      const isQtyGreaterThanMaxOrderQty = (qty > maxOrderQtyNumber - addedQty) && maxOrderQuantity !== 0;
      const isQtyGreaterThanAvailableQuantity = qty > availableQtyNumber - addedQty;

      this.isDisabled = (
        isQtyAbsent
        || isQtyGreaterThanMaxOrderQty
        || isQtyGreaterThanAvailableQuantity
      );

      if (this.isOrderAndRequestAvailable) {
        qty === 0
          ? (this.isRequestStockDisabled = true)
          : (this.isRequestStockDisabled = false);
      }
    },

    translateQuantity(type) {
      return this.translations?.type || type;
    },

    onSelect({ target: { value } }) {
      this.currentQuantityType = value;
      if (this.currentQuantityType !== QUANTITY_TYPE.CONTENT) {
        this.qty = Math.floor(this.qty);
      }
    },
    updateErrorMessage(val, key) {
      this.errorMessage[key] = val ?? '';
    },

    updateQuantity({ target: { value } }) {
      this.quantityChanged = true;
      this.qty = value;
      this.updateDisability();

      if (this.shoppingListId) {
        this.updateQuantityInShoppingList();
      }
      this.showErrors = true;
    },

    updateTradingPrice(e) {
      this.tradingPrice = e.target.value;
    },

    disabledRequest(status = true) {
      this.isIncorrectTradingPrice = status
    },

    getQty() {
      if (this.currentProductUom === UNIT_OF_MEASUREMENT.CONTENT) {
        return this.multiplyContent;
      }

      if (this.currentProductUom === UNIT_OF_MEASUREMENT.PACKAGE) {
        return this.multiplyPackage;
      }

      if (this.currentProductUom === UNIT_OF_MEASUREMENT.KILOGRAM) {
        return this.multiplyKilogram;
      }

      return this.qty;
    },

    updateAddedQuantity(totalItemsOfAddedProductInCart) {
      this.addedQuantity = totalItemsOfAddedProductInCart;
    },

    addToCartCallback({ totalItemsOfAddedProductInCart, isSuccess }) {
      if (isSuccess) {
        this.isApproved = true;
        this.addedQuantity = totalItemsOfAddedProductInCart;
        this.isDisabled = false;
        this.isLoading = false;

        this.qty = 1;
        this.showErrors = false;

        this.updateDisability();

        setTimeout(() => {
          this.isApproved = false;
          this.isTradingOpen = false;
        }, 2000);
      } else {
        this.isDisabled = false;
        this.isLoading = false;
      }
    },

    addToCart() {
      let quantity = this.getQty();

      if (!quantity) {return;}

      if (this.isTradingOffer && !this.tradingPrice || this.tradingPrice === '0') {return;}

      this.isDisabled = true;
      this.isLoading = true;

      let productToAdd = {
        sku: this.productDataVariant.masterVariant.sku,
        quantity,
        tradingPrice: this.tradingPrice,
      };

      // add to the vuex store
      this.addCart(productToAdd)
        .then(this.addToCartCallback)
        .catch(this.showError);
    },

    requestQuote() {
      this.isTradingOffer = true;

      this.addToCart();
    },

    handleSubscription() {
      if(this.isSubscribedStockNotifications) {
        window.dataLayer.push({
          event: GTMEvents.UNSUBSCRIBE_STOCK_NOTIFICATION,
        });

        return this.unsubscribeFromStockNotifications();
      }

      if (this.isOrderAndRequestAvailable) {
        return this.requestStockAndNotify();
      }

      return this.subscribeStockNotifications();
    },

    requestStockAndNotify() {
      const { sku, multiplicationFactor, orderUOM } = this.productDataVariant.masterVariant;
      const quantity = calculateQuantityWithMfa(this.qty, multiplicationFactor, orderUOM);
      let requestData = {
        skuID: sku,
        quantity,
      };

      this.subscribeStockNotificationsMessageSuccess = fillPlaceholderWithValue(
        defaultTranslations.outOfStockRequestSuccess,
        quantity,
      );

      if (this.productDataVariant.masterVariant.excludedFromStockNotifications) {
        this.isInputAvailable = false;
        this.isRequestStockDisabled = true;
        window.dataLayer.push({
          event: GTMEvents.REQUEST_AND_NOTIFY,
        });
        this.requestStock(requestData);
      } else {
        if (!this.quantityChanged) {
          this.showRequestNotifyPopup = true;
        } else {
          this.subscribeStockNotifications();
          window.dataLayer.push({
            event: GTMEvents.REQUEST_AND_NOTIFY,
          });
          this.requestStock(requestData);
        }
      }
    },

    async subscribeStockNotifications() {
      this.isSubscribedStockNotificationsTransition = true;
      const { sku } = this.productDataVariant.masterVariant;

      try {
        const response = await this.addSubscription(sku);

        if (response.level === 'ERROR') {
          this.isSubscribedStockNotificationsTransition = false;
          this.isSubscribedStockNotifications = false;
          throw new Error(response.message);
        }

        this.isSubscribedStockNotifications = true;
        this.isInputAvailable = false;
        this.isStockNotificationHidden = false;

        setTimeout(() => {
          this.isStockNotificationHidden = true;
          this.isSubscribedStockNotificationsTransition = false;
        }, 3000)


        if (this.isOrderAndRequestAvailable) {
          let notification = window.BNS.globalNotification(this.subscribeStockNotificationsMessageSuccess, 'success');

          notification.show();
        }

        return true;
      } catch (error) {
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message, 'error',
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notificationText;

        if (this.productDataVariant.masterVariant.excludedFromStockNotifications) {
          notificationText = defaultTranslations.excludedFromStockNotificationsError;
        } else {
          notificationText = defaultTranslations.requestAndNotifyError;
        }

        const notification = window.BNS.globalNotification(
          notificationText,
        );

        notification.show();
      }
    },

    async unsubscribeFromStockNotifications() {
      this.isSubscribedStockNotificationsTransition = true;

      const {sku} = this.productDataVariant.masterVariant;

      try {
        await this.removeSubscription(sku);

        this.isSubscribedStockNotifications = false;
        this.isStockNotificationHidden = false;
        this.isRequestStockDisabled = false;
        this.qty = 1

        if (!this.product?.masterVariant?.isOrderable && !this.isOrderAndRequestAvailable) {
          this.isInputAvailable = false;
        } else {
          this.isInputAvailable = true;
        }

        await new Promise((resolve) => setTimeout(() => {
          this.isStockNotificationHidden = true;
          resolve();
        }, 3000));

        this.isSubscribedStockNotificationsTransition = false;

        return true;
      } catch (error) {
        let notification = window.BNS.globalNotification(
          defaultTranslations.unsubscribeFromStockNotificationsError,
        );

        notification.show();
        console.error(error.message);
        this.isSubscribedStockNotificationsTransition = false;
      }
    },

    openAddToList(event) {
      openDialogEvent(
        event,
        this.$el.querySelector('.show-dialog-' + this.productDataVariant.id),
      );
      this.$root.$emit(
        VUE_EVENT_NAME__OPEN_ADD_TO_LIST,
        this.productDataVariant.id,
      );
      this.$root.$once(
        VUE_EVENT_NAME__ADD_PRODUCT_TO_LIST_CB,
        this.addProductToListCallback,
      );
    },

    addProductToListCallback() {
      this.isFavourite = true;
    },

    async removeFromList(listId) {
      if (this.productDataVariant.id && listId) {
        let url =
          VUE_URL__SHOPPING_LISTS +
          '/' +
          listId +
          '/product/' +
          this.productDataVariant.id;

        try {
          let response = await http.delete(url);

          if (response) {
            this.isRemoved = true;

            return true;
          }
        }
        catch (error) {
          if (error.hasOwnProperty('response')) {
            error.response
              .json()
              .then(errResp => {
                let notification = window.BNS.globalNotification(
                  errResp.message,
                );

                notification.show();

                return true;
              })
              .catch(e => {
                console.error(e);

                return true;
              });
          }

          let notification = window.BNS.globalNotification(
            error.message || error,
          );

          notification.show();

          return;
        }
      }
    },

    showMoreVariants() {
      this.isMoreVariantOpen = true;
    },

    hideMoreVariants() {
      this.isMoreVariantOpen = false;
    },

    async selectVariant(productVariant) {
      if (
        productVariant.inStock
          && productVariant.sku !== this.productDataVariant.masterVariant.sku
      ) {
        let url = VUE_URL__PRODUCT + '/' + productVariant.sku;

        try {
          let response = await http.get(url);
          const product = JSON.parse(JSON.stringify(response));

          this.$set(this, 'product', product);
          this.hideMoreVariants();
          this.updateDisability();
          this.isInputAvailable = !this.isDisabled;
          this.addedQuantity = this.product.masterVariant.totalItemsInCart;

          return true;
        }
        catch (error) {
          if (error.hasOwnProperty('response')) {
            error.response
              .json()
              .then(errResp => {
                let notification = window.BNS.globalNotification(
                  errResp.message,
                );

                notification.show();

                return true;
              })
              .catch(e => {
                console.error(e);

                return true;
              });
          }

          let notification = window.BNS.globalNotification(
            error.message || error,
          );

          notification.show();

          return;
        }
      }
    },

    updateQuantityInShoppingList: debounce(async function() {
      if (this.qty?.trim().length === 0) {
        return;
      }
      const url =
        VUE_URL__SHOPPING_LISTS +
        '/' +
        this.shoppingListId +
        '/product/' +
        this.productDataVariant.id +
        '/quantity';
      const loader = this.$loading.show({
        active: true,
        container: this.$el,
        // sometimes https://github.com/ankurk91/vue-loading-overlay/issues/26 may happen
        enforceFocus: false,
      });
      let updateQuantityData = {
        requestedQty: this.qty,
      };

      try {
        await http.put (url, updateQuantityData);

        return true;
      }
      catch (error) {
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message,
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(
          error.message || error,
        );

        notification.show();

        return;
      }
      finally {
        loader.hide();
        this.updateDisability();
      }
    }),

    updateListName(newName) {
      let cleanListName = sanitizeOfValue(newName, regExpForSpecialSymbols);

      this.listName = cleanListName;
    },
  },

  mounted() {
    this.currentQuantityType = this.quantityTypes[0];
    const quantityTypesSelect = this.$el.querySelector('select[name="quantityOption"]');

    if (quantityTypesSelect) {
      window.addEventListener('load', () => quantityTypesSelect.value = this.currentQuantityType);
    }

    if (
      this.productDataVariant.variantMatrix
        && this.productDataVariant.variantMatrix.features
        && this.productDataVariant.variantMatrix.features.length > 0
    ) {
      let isVariantFound = false;
      const self = this;

      this.productDataVariant.variantMatrix.features.forEach(feature => {
        if (isVariantFound === false && feature.values.length > 1) {
          self.productVariants = feature.values;
          isVariantFound = true;
        }
      });
    }

    if (this.productDataVariant.masterVariant.tradingPrice) {
      this.tradingPrice = this.productDataVariant.masterVariant.tradingPrice;
    }

    if (this.productDataVariant.masterVariant.totalItemsInCart > 0) {
      this.addedQuantity = this.productDataVariant.masterVariant.totalItemsInCart;
    }

    if (this.productDataVariant.masterVariant.isSubscribedStockNotifications) {
      this.isSubscribedStockNotifications = true;
    }

    if (this.productDataVariant.isFavourite) {
      this.isFavourite = true;
    }

    if (this.shoppingListId && this.productDataVariant.requestedQty) {
      this.qty = this.productDataVariant.requestedQty;
    }

    if (this.isOrderAndRequestAvailable
      && this.isSubscribedStockNotifications
      && !this.productDataVariant.masterVariant.isOrderable
    ) {
      this.isInputAvailable = false;
    }

    if (!this.isOrderAndRequestAvailable
      && this.isSubscribedStockNotifications
      && !this.productDataVariant.masterVariant.isOrderable
    ) {
      this.isInputAvailable = false;
    }

    if (!this.isOrderAndRequestAvailable
      && !this.isSubscribedStockNotifications
      && !this.productDataVariant.masterVariant.isOrderable
    ) {
      this.isInputAvailable = false;
    }

    this.updateDisability();
  },
};
