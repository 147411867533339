import Glide from '@glidejs/glide';
import { DomLoadedCheck } from '../utils/dom-loaded-check';

const SLIDER_TEASER = '.glide--teaser';

function init() {
  const teasers = document.querySelectorAll(SLIDER_TEASER);

  if (teasers.length < 1) {
    return;
  }

  [].forEach.call(teasers, (teaser, i) => {
    const distinctClassName = `glide--teaser-${i}`;

    teaser.classList.add(distinctClassName);
    const glideTeaser = new Glide(`.${distinctClassName}`, {
      type: 'slider',
      startAt: 0,
      perView: 2.5,
      breakpoints: {
        // breakpoint: mobile until tablet
        767: {
          peek: 0,
          perView: 1.25,
        },
      },
    });

    glideTeaser.on('run.after', function() {
      // eslint-disable-next-line no-underscore-dangle
      let isStart = glideTeaser._c.Run.isStart();
      // eslint-disable-next-line no-underscore-dangle
      let isEnd = glideTeaser._c.Run.isEnd();
      let hiddenClass = 'is-hidden';
      let arrowLeft = teaser.querySelector('.glide__arrow--left');
      let arrowRight = teaser.querySelector('.glide__arrow--right');

      if (isStart) {
        arrowLeft.classList.add(hiddenClass);
        arrowRight.classList.remove(hiddenClass);
      } else if (isEnd) {
        arrowRight.classList.add(hiddenClass);
        arrowLeft.classList.remove(hiddenClass);
      } else {
        arrowLeft.classList.remove(hiddenClass);
        arrowRight.classList.remove(hiddenClass);
      }
    });

    glideTeaser.mount();
  });
}

(() => {
  DomLoadedCheck(init);
})();
