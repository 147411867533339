<template>
  <input
    ref="numericInputElem"
    :value="value"
    v-bind="{ ...$props, ...$attrs }"
    :type="hasArrows ? 'number' : 'text'"
    :label="label"
    :min="minValue"
    :max="maxValue"
    :step="step"
    :readonly="readOnly"
    :maxlength="String(maxValue).length"
    :minlength="String(minValue).length"
    pattern="^[0-9]+[.,]?[0-9]*$"
    @keydown="onKeydown"
    v-on="$listeners"
  />
</template>

<script>
  export default {
    name: 'VueNumericInput',
    props: {
      value: {
        type: [Number, String],
        default: 1,
      },

      label: {
        type: String,
        default: '',
      },

      minValue: {
        type: [Number, String],
        default: 0,
      },

      maxValue: {
        type: [Number, String],
        default: Infinity,
      },

      readOnly: {
        type: Boolean,
        default: false,
      },

      step: {
        type: [Number, String],
        default: 1,
      },

      hasArrows: {
        type: Boolean,
        default: true,
      },
    },

    methods: {
      invalidate() {
        this.$refs?.numericInputElem?.setCustomValidity(false)
      },
      resetValidation() {
        this.$refs?.numericInputElem?.setCustomValidity('')
      },
      onKeydown(event) {
        const digits = Array.from({ length: 10 }, (v, i) => i.toString());
        const fractionKeys = Number(this.step) < 1 ? [',', '.'] : [];
        const specialKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter', 'ArrowUp', 'ArrowDown'];
        const allowedKeys = [...digits, ...specialKeys, ...fractionKeys];
        const isInvalidNumber = !allowedKeys.some(key => key === event.key);

        if (isInvalidNumber) {
          event.preventDefault();
        }
      },
    },
  };
</script>
