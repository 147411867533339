var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{class:['category-list', _vm.isLinkDarkUi ? 'link-ui-dark' : 'link-ui-grey']},[_vm._m(0),_vm._v(" "),_c('ol',{ref:"categoryListContainerRef",class:[
        'is-unstyled has-no-indent has-no-margin',
        {
          'category-list__container': _vm.hasSubItems,
          'is-active-list': _vm.isActiveList,
          'overflow-hidden': _vm.isOverFlowHidden
        }
      ],attrs:{"name":"mainList"}},[_c('li',{staticClass:"is-hidden-desktop"},[_c('label',{staticClass:"qa-back-btn is-link is-color-secondary arrow-left",attrs:{"for":"close-button","title":_vm.BACK_LABEL},domProps:{"textContent":_vm._s(_vm.BACK_LABEL)},on:{"click":_vm.backBtnHandler}},[_vm._v("global.back.label")])]),_vm._v(" "),(_vm.menu)?_vm._l((_vm.childMenuItems),function(menuItem,index){return _c('li',{key:menuItem.name,staticClass:"category-list__item",on:{"click":function($event){$event.stopPropagation();return _vm.clItemClickHandler(menuItem)}}},[(_vm.isListEmpty(menuItem.hstSiteMenuItems))?_c('a',{class:[
              'qa-navigate-to-category-link category-list__item--link',
              {
                'is-active': _vm.selectedActiveItem == menuItem.name,
                'is-current-page':
                  _vm.isListEmpty(menuItem.hstSiteMenuItems) &&
                  (menuItem.selected || menuItem.expanded)
              }
            ],attrs:{"title":menuItem.name,"href":_vm.getLink(menuItem)},domProps:{"textContent":_vm._s(menuItem.name)}},[_vm._v("\n            menuItem.name in categoryList\n          ")]):_c('a',{class:[
              'category-list__item--link',
              {
                'is-active': _vm.selectedActiveItem == menuItem.name,
                'has-children': !_vm.isListEmpty(menuItem.hstSiteMenuItems)
              }
            ],attrs:{"title":menuItem.name},domProps:{"textContent":_vm._s(menuItem.name)}},[_vm._v("\n            menuItem.name in categoryList\n          ")]),_vm._v(" "),(!_vm.isListEmpty(menuItem.hstSiteMenuItems))?_c('ol',{class:[
              'is-unstyled has-no-indent has-no-margin',
              {
                'category-list__sub-item-container': _vm.hasSubItems,
                'is-collapsed': _vm.selectedActiveItem != menuItem.name
              }
            ],style:({ top: _vm.topSpacing(index) }),attrs:{"name":"subList"}},[_c('li',{staticClass:"is-hidden-desktop"},[_c('a',{staticClass:"qa-back-btn is-link is-color-secondary arrow-left",attrs:{"title":_vm.BACK_LABEL},domProps:{"textContent":_vm._s(_vm.BACK_LABEL)},on:{"click":function($event){$event.stopPropagation();return _vm.backBtnHandler(true)}}},[_vm._v("global.back.label")])]),_vm._v(" "),(_vm.hasHeadingItem)?_c('li',{class:{ 'category-list__sub-item--heading': _vm.hasSubItems }},[_c('span',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(menuItem.name)}})]):_vm._e(),_vm._v(" "),_vm._l((menuItem.hstSiteMenuItems),function(childMenuItem){return _c('li',{key:childMenuItem.name,class:{ 'category-list__sub-item': _vm.hasSubItems }},[_c('a',{class:[
                  'qa-navigate-to-category-link',
                  {
                    'category-list__sub-item--link': _vm.hasSubItems,
                    'is-current-page':
                      childMenuItem.hstSiteMapItemPath != null &&
                      (childMenuItem.selected || childMenuItem.expanded)
                  }
                ],attrs:{"title":childMenuItem.name,"href":_vm.getLink(childMenuItem)},domProps:{"textContent":_vm._s(childMenuItem.name)}},[_vm._v("\n                childMenuItem.name in categoryList\n              ")])])})],2):_vm._e()])}):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('aside',[_c('label',{staticClass:"toggle--category-navigation is-toggle-label is-closing",attrs:{"for":"close-button"}},[_vm._v("\n        closeLabel\n      ")])])
}]

export { render, staticRenderFns }