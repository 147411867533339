import Glide from '@glidejs/glide';
import { DomLoadedCheck } from '../utils/dom-loaded-check';

const SAVED_ITEMS_CONTAINER_CLASS = '.glide--saved-items';

function init() {
  const SAVED_ITEMS_CONTAINER_SELECTOR = document.querySelector(
    SAVED_ITEMS_CONTAINER_CLASS,
  );

  if (!SAVED_ITEMS_CONTAINER_SELECTOR) {return;}

  const glideSavedItems = new Glide('.glide--saved-items', {
    type: 'slider',
    startAt: 0,
    perView: 6,
    gap: 14,
    breakpoints: {
      // breakpoint: mobile until tablet
      767: {
        perView: 3.5,
      },
    },
  });

  glideSavedItems.mount();
}

(() => {
  DomLoadedCheck(init);
})();
