<template>
  <svg
    :class="['icon-link', { 'inner-search': isInnerSearch }]"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    @click="barcodeClickHandler"
  >
    <use
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xlink:href="#icon--barcode"
    ></use>
  </svg>
</template>

<script>
  export default {
    name: 'VueBarcodeButton',

    props: {
      isInnerSearch: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      barcodeClickHandler() {
        this.$emit('click');
      },
    },
  };
</script>