export default {
  name: 'VueEForm',

  data() {
    return {
      conditions: [],
    }
  },

  mounted() {
    this.collectConditions();
    window.addEventListener('load', this.hideConditionalFields);
  },

  methods: {
    addCondition(formField, condition) {
      const conditionIndex = this.conditions.findIndex(({ field, fieldValue }) => {
        return field === condition.condname && fieldValue === condition.condvalue;
      });

      if (conditionIndex > -1) {
        this.conditions[conditionIndex].dependents.push(formField);
      } else {
        this.conditions.push({ field: condition.condname, fieldValue: condition.condvalue, dependents: [formField] });
      }
    },

    hideConditionalFields() {
      this.conditions.forEach(condition => {
        this.updateDependentFieldsVisibility(condition, this.$el[condition.field].value)
      });
    },

    collectConditions() {
      const formFields = this.getFormFields();

      formFields.forEach(formField => {
        const condition = formField.getAttribute('data-condition');

        if (condition) {
          this.addCondition(formField, JSON.parse(condition));
        }
      });
    },

    getFormFields() {
      return this.$el.querySelectorAll('[data-field-name]');
    },

    onFormFieldChange({ target }, value = target.value) {
      const field = target.closest('[data-field-name]');

      if (field) {
        const fieldName = field.getAttribute('data-field-name');

        this.conditions.filter(({ field }) => field === fieldName)
          .forEach(condition => this.updateDependentFieldsVisibility(condition, value))
      }
    },

    updateDependentFieldsVisibility(condition, value) {
      if (condition.fieldValue === value) {
        this.showDependentFields(condition.dependents);
      } else {
        this.hideDependentFields(condition.dependents);
      }
    },

    hideDependentFields(dependentFields) {
      dependentFields.forEach(field => {
        field.style.display = 'none';
        const inputs = field.querySelectorAll('input, select');

        inputs.forEach(input => input.disabled = true);
      });
    },

    showDependentFields(dependentFields) {
      dependentFields.forEach(field => {
        field.style.display = 'block';
        const inputs = field.querySelectorAll('input, select');

        inputs.forEach(input => input.disabled = false);
      });
    },
  },

  render() {
    return this.$scopedSlots.default({
      onFormFieldChange: this.onFormFieldChange,
    });
  },
};
