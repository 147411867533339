let savedMode = sessionStorage.getItem('shippingMode');
let savedTime = sessionStorage.getItem('selectedShippingTime');
let savedLaterDate = sessionStorage.getItem('selectedLaterDate');
let savedDate = JSON.parse(sessionStorage.getItem('selectedShippingDate'));

export default {
  name: 'VueDeliveryPickupDates',

  render() {
    return this.$scopedSlots.default({
      selectedDate: this.selectedDate,
      selectedTime: this.selectedTime,
      selectDate: this.selectDate,
      selectTime: this.selectTime,
      selectLaterDate: this.selectLaterDate,
      availableDates: this.availableDatesData,
      availableLaterDates: this.availableLaterDatesData,
      timeSlots: this.timeSlots,
    });
  },

  props: {
    availableDates: {
      required: true,
    },

    availableLaterDates: {
      required: true,
    },
  },

  data() {
    return {
      selectedDate: '',
      selectedTime: '',
      timeSlots: [],
    };
  },

  computed: {
    availableDatesData() {
      return JSON.parse(this.availableDates);
    },

    availableLaterDatesData() {
      return JSON.parse(this.availableLaterDates);
    },
  },

  mounted() {
    if (savedDate) {
      this.checkSavedSelectedDate();

      return;
    }

    for (let day of this.availableDatesData) {
      if (day.available) {
        this.updateSelectedDate(day.id, day.timeSlots);

        return;
      }
    }

    if (this.selectedDate === '') {
      for (let week of this.availableLaterDatesData) {
        for (let day of week.days) {
          if (day.available) {
            this.updateSelectedDate(day.id, day.timeSlots);

            return;
          }
        }
      }
    }
  },

  methods: {
    selectLaterDate(e, mode) {
      const selectedDate = e.target.value;

      if (selectedDate === '0') {
        return;
      }

      sessionStorage.setItem('selectedLaterDate', selectedDate);
      this.selectMode(mode);

      // Reset already selected date before applying a new date
      let selectedFormDateInputDelivery = this.$el.querySelector(
        'input[type="radio"][name="selectedDeliveryDate"]:checked',
      );

      if (selectedFormDateInputDelivery)
      {selectedFormDateInputDelivery.checked = false;}

      let selectedFormDateInputPickup = this.$el.querySelector(
        'input[type="radio"][name="selectedPickupDate"]:checked',
      );

      if (selectedFormDateInputPickup)
      {selectedFormDateInputPickup.checked = false;}

      let selectedOption = e.target[e.target.selectedIndex];
      let timeSlots = selectedOption.getAttribute('data-time-slots');
      let timeSlotsArr = null;

      if (timeSlots) {
        timeSlotsArr = timeSlots.split(',');
      }

      this.updateSelectedDate(selectedDate, timeSlotsArr);
    },

    selectTime(e) {
      this.selectedTime = e.target.value;
      sessionStorage.setItem('selectedShippingTime', this.selectedTime);
    },

    selectDate(date, available, timeSlots, mode) {
      // Reset already selected date before applying a new date
      sessionStorage.removeItem('selectedLaterDate');
      this.selectMode(mode);
      let selectedFormDateSelect = this.$el.querySelector('select[name="selectedDeliveryDate"]:not(:empty)');

      if (selectedFormDateSelect) {
        selectedFormDateSelect.selectedIndex = -1;
      }

      if (available) {
        this.updateSelectedDate(date, timeSlots);
      }
    },

    updateSelectedDate(date, timeSlots) {
      this.selectedDate = date;
      this.timeSlots = timeSlots;
      sessionStorage.setItem('selectedShippingDate', JSON.stringify({ date, timeSlots }));

      if (this.timeSlots && this.timeSlots.length > 0) {
        this.selectedTime = this.timeSlots[0];
      }
    },

    checkSavedSelectedDate() {
      if (savedLaterDate) {
        const selectedDeliveryDate = this.$el.querySelector('select[name="selectedDeliveryDate"]');
        const selectedPickupDate = this.$el.querySelector('select[name="selectedPickupDate"]');

        // Preselect delivery date if it was saved before
        if (savedMode === 'delivery' && selectedDeliveryDate) {
          this.setSavedLaterDate(selectedDeliveryDate);
        }

        // Preselect pickup date if it was saved before
        if (savedMode === 'pickup' && selectedPickupDate) {
          this.setSavedLaterDate(selectedPickupDate);
        }
      } else {
        // Preselect date and time if it was saved before
        this.selectedDate = savedDate.date;
        const selectDateInput = document.getElementById(`${savedMode}-${this.selectedDate}`);

        this.updateSelectedDate(savedDate.date, savedDate.timeSlots);
        if (selectDateInput) {
          selectDateInput.checked = true;
        }
      }

      if (savedTime) {
        this.selectedTime = savedTime;
        const selectTimeInput = this.$el.querySelector('select[name="selectedPickupTime"]');

        if (selectTimeInput) {
          selectTimeInput.checked = true;
        }
      }
    },

    setSavedLaterDate(el) {
      el.checked = true;
      el.value = savedLaterDate;
      el.dispatchEvent(new Event('change'));
    },

    selectMode(mode) {
      sessionStorage.setItem('shippingMode', mode);
    },
  },
};
