import { DomLoadedCheck } from '../utils/dom-loaded-check';

const PERSONAL_DETAILS_CONDITIONS_CHECKBOX = 'input.accept-privacy';
const SUBMIT_BUTTON = '.personal-details__send-button';

function init() {
  let changePersonalDetailsInput = document.querySelector(
    PERSONAL_DETAILS_CONDITIONS_CHECKBOX,
  );

  if (!changePersonalDetailsInput) {return;}

  let submitButton = document.querySelector(SUBMIT_BUTTON);

  if (!submitButton) {return;}

  changePersonalDetailsInput.addEventListener('click', () => {
    changePersonalDetailsInput.checked
      ? (submitButton.disabled = false)
      : (submitButton.disabled = true);
  });
}

(() => {
  DomLoadedCheck(init);
})();
