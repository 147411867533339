import state from "./state";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

export const FEATURE_NAMESPACE = 'FEATURE_NAMESPACE'


export { default as actionTypes } from './actionTypes'
export { default as mutationTypes } from './mutationTypes'
export { default as getterTypes } from './getterTypes'