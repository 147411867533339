import deepmerge from 'deepmerge';

export function getDataFromDataLayer(input) {
  if (!window.hasOwnProperty('dataLayer')) {return undefined;}

  const ROOT_OBJECT_NAME = 'gtm';
  const get = (obj, path) => {
    const travel = regexp =>
      String.prototype.split
        .call(path, regexp)
        .filter(Boolean)
        .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);

    return travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  };
  let filteredRootData = window.dataLayer.filter(function(x){
    return x.hasOwnProperty(ROOT_OBJECT_NAME);
  }).reverse();

  if (!filteredRootData.length) {return window.dataLayer;}

  if (!input) {return filteredRootData[0][ROOT_OBJECT_NAME];}

  return get(filteredRootData[0][ROOT_OBJECT_NAME], input);
}

export function addNonBlacklistedDataToDataLayer(data) {
  if (!data) {return;}
  if (
    window.navigator.hasOwnProperty('doNotTrack')
    && window.navigator.doNotTrack === 'yes'
  ) {
    return;
  }
  if (!window.hasOwnProperty('dataLayer')) {window.dataLayer = [];}

  let blacklist = document.body.getAttribute('data-gtm-blacklist');

  if (blacklist !== 'null') {
    /* eslint-disable-next-line no-param-reassign */
    data = deepmerge(data, JSON.parse(blacklist));
  }

  window.dataLayer.push(data);
}
