<template>
  <div class="download-spol">
    <a
      :disabled="isTooltipLoading"
      class="download-spol-button"
      href="#"
      @click.prevent.self="downloadSpol"
    >
      {{ title }}
    </a>
    <vue-order-bulk-upload
      v-if="showOrderBulkUpload"
      :bulkUploadFileMaxUploadSize="orderBulkUploadFileMaxSize"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { TRANSLATIONS_NAMESPACE, getterTypes as translationGetterTypes } from '../../store/modules/translations';
  import { SPOL_NAMESPACE, getterTypes as spolGetterTypes, actionTypes } from '../../store/modules/spol';
  import VueOrderBulkUpload from '../order-bulk-upload.vue';

  export default {
    name: 'VueDownloadSpol',

    components: {
      'vue-order-bulk-upload': VueOrderBulkUpload,
    },

    props: {
      title: {
        type: String,
        default: '',
      },
      spolIsAvailable: {
        type: String,
        default: 'false',
      },
      isOrderBulkUploadAvailable: {
        type: String,
        default: 'false',
      },
      orderBulkUploadFileMaxSize: {
        type: String,
        default: 'false',
      },
    },

    data() {
      return {
        isTooltipLoading: false,
      }
    },

    computed: {
      showOrderBulkUpload() {
        return this.isOrderBulkUploadAvailable === 'true';
      },
      ...mapGetters(SPOL_NAMESPACE, {
        'spolAvailability': spolGetterTypes.AVAILAIBILITY,
        'wasSpolSet': spolGetterTypes.WAS_SPOL_SET,
      }),
      ...mapGetters(TRANSLATIONS_NAMESPACE, {
        'translations': translationGetterTypes.TRANSLATIONS,
      }),
    },

    mounted () {
      if (!this.wasSpolSet) {
        this.updateAvalability(this.spolIsAvailable);
      }
    },

    methods: {
      ...mapActions(SPOL_NAMESPACE, {
        'requestDownload': actionTypes.REQUEST_DOWNLOAD,
        'updateAvalability': actionTypes.UPDATE_AVAILABILITY,
        'requestAvailability': actionTypes.REQUEST_AVAILABILITY,
      }),

      async downloadSpol() {
        if (this.isTooltipLoading) {
          return;
        }

        if (this.spolAvailability) {
          const loader = this.$loading.show({
            active: true,
            isFullPage: true,
          });

          try {
            await this.requestDownload();
            let level = 'success';
            let message = this.translations.spolExportSuccessMessage;

            this.showNotification(message, level);
          }
          catch(err) {
            const level ='error';
            const message = this.translations.spolExportErrorMessage;

            this.showNotification(message, level);
          }
          finally {
            this.downloadSpolUnavailable();
            loader.hide();
          }
        } else {
          this.showAvailabilityTooltip();
        }
      },

      downloadSpolUnavailable() {
        this.updateAvalability(false);
      },

      async showAvailabilityTooltip() {
        this.isTooltipLoading = true;
        const response = await this.getAvailabilityMessage();

        this.showNotification(response, 'error');
        this.isTooltipLoading = false;
      },

      async getAvailabilityMessage() {
        try {
          const date = new Date();
          const parsedDate = date.toString().split(' ');
          const formattedDate = parsedDate.slice(0, 6).join(' ');
          const response = await this.requestAvailability(formattedDate);

          return response.nextExportTimeMessage;
        } catch {
          return this.translations.spolExportErrorMessage;
        }
      },

      showNotification(text, level) {
        const notification = window.BNS.globalNotification(text, level);

        notification.show();
      },
    },
  };
</script>