<template>
  <div class="file-input">
    <vue-alert
      v-if="isFileSizeExceeded"
      :message="errorForMaxAllowedFileSize"
      level="error"
      isActiveByDefault
      canClose
      @close="onCloseErrorFileSizeExceededAlert"
    />
    <vue-alert
      v-if="isFilesLimitExceeded"
      :message="errorForLimitExceeded"
      level="error"
      isActiveByDefault
      canClose
      @close="onCloseErrorFilesLimitExceededAlert"
    />

    <div v-if="files.length">
      <div
        v-for="(file, index) in files"
        :key="index"
        class="file space-mb-3"
      >
        <span class="file-name">{{ file.name }}</span>
        <span>({{ calculateFileSize(file) }})</span>
        <button
          class="file-remove"
          type="button"
          @click="onRemoveFile(index)"
        >
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
          >
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#icon--close"
            ></use>
          </svg>
        </button>
      </div>
    </div>

    <button
      v-if="hasLoading"
      :disabled="hasLoading"
      class="upload-file-button is-primary"
    >
      {{ translations.fileInputLoadingStatusCaption }}
    </button>
    <button
      v-else
      class="upload-file-button is-primary"
    >
      {{ uploadButtonValue }}
      <input
        ref="input"
        type="file"
        :accept="acceptedFiles"
        :name="inputName"
        :size="maxFileSizeInBytes"
        :disabled="disabled"
        :required="required"
        :multiple="multiple"
        v-on="$listeners"
        @change="onChange"
      />
    </button>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { TRANSLATIONS_NAMESPACE, getterTypes as translationGetterTypes } from '../../store/modules/translations';
  import { fillPlaceholderWithValue } from '../../utils';
  import VueAlert from '../alert/alert.vue';

  const BYTE = 1024;
  const mbToBytes = mb => {
    const ONE_MEGABYTE = BYTE ** 2;

    return Number(mb) * ONE_MEGABYTE;
  };
  const bytesToMb = bytes => {
    return Number(bytes / (BYTE ** 2))
  }

  export default {
    name: 'VueFileInput',

    components: {
      VueAlert,
    },

    props: {
      loadedFiles: {
        type: Array,
        default: () => [],
      },
      uploadButtonValue: {
        type: String,
        default: 'Upload',
      },
      name: {
        type: String,
        default: 'file-upload',
      },
      acceptedFiles: {
        type: String,
        default: '',
      },
      fizeSizeInBytes: {
        type: Boolean,
        default: false,
      },
      maxFileSize: {
        type: String,
        default: "10",
      },
      maxFiles: {
        type: String,
        default: "1",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      isLoadingState: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        files: this.loadedFiles,
        isLoading: false,
        isFileSizeExceeded: false,
        isFilesLimitExceeded: false,
        errorForLimitExceeded: '',
        errorForMaxAllowedFileSize: '',
      };
    },

    computed: {
      ...mapGetters(TRANSLATIONS_NAMESPACE, {
        'translations': translationGetterTypes.TRANSLATIONS,
      }),

      maxFileSizeInBytes() {
        if (this.fizeSizeInBytes) {
          return this.maxFileSize;
        }

        return mbToBytes(this.maxFileSize);
      },

      inputName() {
        return this.multiple ? `${this.name}[]` : this.name;
      },

      isMultipleInput() {
        return this.multiple;
      },

      hasLoading() {
        return this.isLoading || this.isLoadingState
      },
    },

    methods: {
      calculateFileSize(file) {
        const size = file?.size || 1;
        const sizeInKiloBytes = size / BYTE;

        if (sizeInKiloBytes > BYTE) {
          const sizeInMegaBytes = sizeInKiloBytes / BYTE;

          return `${sizeInMegaBytes.toFixed(2)} Mb`;
        }

        return `${sizeInKiloBytes.toFixed(2)} Kb`;
      },

      checkIsValidFileSize(size) {
        return size <= this.maxFileSizeInBytes;
      },

      resetInput() {
        const { input } = this.$refs;

        this.files = [];
        input.value = '';

        if (!/safari/i.test(navigator.userAgent)) {
          input.type = '';
          input.type = 'file';
        }
      },

      addFile(file) {
        if (this.disabled) {
          return;
        }

        const { size } = file;

        if (this.checkIsValidFileSize(size)) {
          if (this.isMultipleInput) {
            this.files.push(file);
          } else {
            this.files[0] = file;
          }

          this.isFileSizeExceeded = false;
        } else {
          this.errorForMaxAllowedFileSize = fillPlaceholderWithValue(
            this.translations.fileInputErrorForMaxAllowedFileSize,
            this.maxFileSizeInBytes ? bytesToMb(this.maxFileSize) : this.maxFileSize,
          );

          const error = {
            code: 'exceededFileSize',
            message: this.errorForMaxAllowedFileSize,
          };

          if (!this.isMultipleInput) {
            this.resetInput();
          }

          this.isFileSizeExceeded = true;
          this.$emit('error', error);
        }
      },

      onChange(event) {
        const filesToUpload = Array.from(
          event.type === 'change' ? event.target.files : event.dataTransfer.files,
        );

        if (!filesToUpload.length) {
          this.$emit('cancel');

          return;
        }

        this.isLoading = true;

        const { files, maxFiles, isMultipleInput } = this;
        const maxLeftToUpload = files.length + filesToUpload.length > Number(maxFiles);

        if (isMultipleInput && maxLeftToUpload) {
          const availableCount = Number(maxFiles) - files.length;
          const filesNotUpload = filesToUpload.splice(availableCount);

          this.errorForLimitExceeded = fillPlaceholderWithValue(
            this.translations.fileInputErrorForLimitExceeded,
            filesNotUpload.length,
          );

          const error = {
            code: 'maxFiles',
            message: this.errorForLimitExceeded,
          };

          this.isFilesLimitExceeded = true;
          this.$emit('error', error);
        }

        filesToUpload.forEach(this.addFile, this);
        this.isLoading = false;
        this.$emit('change', this.files);
      },

      onRemoveFile(index) {
        const { files } = this;
        const file = files[index];
        const onRemove = () => {
          files.splice(index, 1);

          this.$nextTick(() => {
            this.$emit('change', files);
          });

          if (this.files.length < 1) {
            this.resetInput();
          }
        };

        onRemove();
        this.$emit('remove', file);
      },

      onCloseErrorFileSizeExceededAlert() {
        this.isFileSizeExceeded = false;
      },

      onCloseErrorFilesLimitExceededAlert() {
        this.isFilesLimitExceeded = false;
      },
    },
  };
</script>
