import Cookie from 'js-cookie';
import { DomLoadedCheck } from '../utils/dom-loaded-check';

const COOKIE_BANNER_NAME = 'ANKER_COOKIE_PREF';
const COOKIE_BANNER_CLASS_NAME_ON_BODY_ELEMENT = 'has-cookiebanner-opened';
const IS_HIDDEN_CLASS_NAME = 'is-hidden';

function getCookieBanner() {
  return document.querySelector('.fragment--cookie-banner');
}

function showCookieBanner() {
  let cookieBanner = getCookieBanner();

  if (!cookieBanner) {return;}

  cookieBanner.classList.remove(IS_HIDDEN_CLASS_NAME);
  document.body.classList.add(COOKIE_BANNER_CLASS_NAME_ON_BODY_ELEMENT);
}

function hideCookieBanner() {
  let cookieBanner = getCookieBanner();

  if (!cookieBanner) {return;}

  cookieBanner.classList.add(IS_HIDDEN_CLASS_NAME);
  document.body.classList.remove(COOKIE_BANNER_CLASS_NAME_ON_BODY_ELEMENT);
}

function saveCookiePreference(pref) {
  Cookie.set(COOKIE_BANNER_NAME, pref, {
    expires: 182, /* days */
  });
}

function init() {
  const acceptButtons = document.querySelectorAll('.i-accept-cookies');
  const closeButtons = document.querySelectorAll('.close-the-cookie-banner');

  if (Cookie.get(COOKIE_BANNER_NAME) !== 'all') {
    showCookieBanner();
  }

  [].forEach.call(acceptButtons, acceptButton => {
    acceptButton.addEventListener('click', () => {
      saveCookiePreference('all');
      hideCookieBanner();
    });
  });

  [].forEach.call(closeButtons, closeButton => {
    closeButton.addEventListener('click', () => {
      saveCookiePreference('all');
      hideCookieBanner();
    });
  });
}

(() => {
  DomLoadedCheck(init);
})();
