export function globalNotification(message, level = 'error') {
  const {globalNotification} = window.BNS.vueInstance.$root.$refs;

  globalNotification.messageData = typeof message === 'string'
    ? message
    : globalNotification.$el.dataset.defaultErrorMessage;
  globalNotification.levelData = level;

  return {
    show() {
      globalNotification.show();
    },

    hide() {
      globalNotification.hide();
    },
  };
}


export function loader(loaderArgs) {
  return window.BNS.vueInstance.$loading.show(loaderArgs);
}