import { DomLoadedCheck } from '../utils/dom-loaded-check';

function placeholderPolyfill() {
  this.classList[this.value ? 'remove' : 'add']('placeholder-shown');
}

function init() {
  document.querySelectorAll('[placeholder]').forEach(el => {
    placeholderPolyfill.call(el);

    el.addEventListener('change', placeholderPolyfill);
    el.addEventListener('keyup', placeholderPolyfill);
  });
}

(() => {
  DomLoadedCheck(init);
})();
