<template>
  <label class="text-grey">
    <input
      type="radio"
      :checked="$attrs.value"
      v-bind="$attrs"
      v-on="listeners"
    />
    <span v-if="label"> {{ label }}</span>
  </label>
</template>
<script>
  export default {
    name: 'VueRadioButton',

    inheritAttrs: false,

    props: {
      label: {
        default: '',
        type: String,
      },
    },

    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.onChange,
        };
      },
    },

    methods: {
      onChange(event) {
        const { target } = event;

        this.$emit('change', event, target.value);
      },
    },
  };
</script>