const IS_HIDDEN_ACCORDION_CONTENT = 'is-hidden';
const ACCORDION_CONTENT_CLASS_NAME = 'accordion__content';
const ACCORDION_TITLE_CLASS_NAME = 'accordion__title';
const ACCORDION_ACTIVE_CLASS = 'active';
const ACCORDION_ITEM_CLASS = 'accordion__item';

export default {
  name: 'VueAccordion',
  render() {
    return this.$scopedSlots.default({
      activeIndex: this.activeIndex,
      showItem: this.showItem,
      toggle: this.toggle,
    });
  },
  props: {
    openAccordionNumber: Number,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  methods: {
    toggle(e) {
      let accordionTitle = e.target.closest(`.${ACCORDION_TITLE_CLASS_NAME}`);
      let accordionItem = e.target.closest(`.${ACCORDION_ITEM_CLASS}`);
      let accordionContent = accordionTitle.nextElementSibling;

      if (!accordionContent.classList.contains(ACCORDION_CONTENT_CLASS_NAME))
      {return;}
      accordionContent.classList.toggle(IS_HIDDEN_ACCORDION_CONTENT);
      accordionTitle.classList.toggle(ACCORDION_ACTIVE_CLASS);
      accordionItem.classList.toggle(ACCORDION_ACTIVE_CLASS);
    },
    showItem(index) {
      /* eslint-disable no-param-reassign */
      index -= 1;
      this.hideAllItems();
      this.setActiveIndex(index);
      const accordionList = this.getAllAccorddionListItems();

      if (accordionList.length < 1) {return;}
      if (accordionList[this.activeIndex]) {
        const accordionContent = accordionList[this.activeIndex].querySelector(
          `.${ACCORDION_CONTENT_CLASS_NAME}`,
        );
        const accordionTitle = accordionList[this.activeIndex].querySelector(
          `.${ACCORDION_TITLE_CLASS_NAME}`,
        );

        if (!accordionContent && !accordionTitle) {return;}
        accordionContent.classList.remove(IS_HIDDEN_ACCORDION_CONTENT);
        accordionTitle.classList.add(ACCORDION_ACTIVE_CLASS);
        accordionList[this.activeIndex].classList.add(ACCORDION_ACTIVE_CLASS);
      }
    },
    getAllAccorddionListItems() {
      return this.$el.querySelector('.accordion__list').children;
    },
    setActiveIndex(index) {
      this.activeIndex = index;
    },
    hideAllItems() {
      [].forEach.call(this.getAllAccorddionListItems(), item => {
        const accordionContent = item.querySelector(
          `.${ACCORDION_CONTENT_CLASS_NAME}`,
        );

        if (!accordionContent) {return;}
        accordionContent.classList.add(IS_HIDDEN_ACCORDION_CONTENT);
      });
    },
  },
  mounted() {
    this.openAccordionNumber
      ? this.showItem(this.openAccordionNumber)
      : this.hideAllItems();

    let urlParams = new URLSearchParams(document.location.search);

    if (urlParams.has('openAccordionNumber')) {
      this.showItem(parseInt(urlParams.get('openAccordionNumber'), 10));
    }
  },
};
