import mutationTypes from './mutationTypes';

const mutations = {
  [mutationTypes.UPDATE_LINK](state, availability) {
    state.spolIsAvailable = availability;
  },
  [mutationTypes.WAS_SPOL_SET](state, wasSet) {
    state.wasSpolSet = wasSet;
  },
};

export default mutations;
