export function getFacetName(facet) {
  return facet.label || facet.name || facet.value;
}

export function getParentPath(facet) {
  let currentItem = { ...facet };
  const path = [];

  while(currentItem.parent) {
    path.push(currentItem.parent);
    currentItem = currentItem.parent;
  }

  return path;
}

// sending too many requests to BE may lead to 500 errors.
class SearchQueue {
  constructor() {
    this.searchQueue = [];
  }

  add(callback) {
    this.searchQueue.push(callback);
  }

  next() {
    const callback = this.searchQueue.shift();

    if (callback) {
      callback();
    }
  }
}

export const searchQueue = new SearchQueue();