import mutationTypes from './mutationTypes';
import actionTypes from './actionTypes';

const actions = {
  [actionTypes.SET_HIERARCHY]({ commit }, { facetId, hierarchy, refine }) {
    commit(mutationTypes.SET_HIERARCHY, { facetId, hierarchy, refine });
  },
  [actionTypes.SET_ITEM_REFINEMENT]({ commit }, { item, isRefined }) {
    commit(mutationTypes.SET_ITEM_REFINEMENT, { item, isRefined });
  },
}

export default actions;