<template>
  <div class="columns">
    <div class="column">
      <dl v-if="showLogisticDetail" class="logistic-details-container">
        <div
          v-for="logisticDetailsAttribute in logisticDetails.attributes"
          :key="logisticDetailsAttribute.name"
          class="logistic-details-item"
        >
          <div>
            <dt>{{ logisticDetailsAttribute.name }}</dt>
            <dd>{{ logisticDetailsAttribute.value }}</dd>
          </div>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
 export default {
  name: 'VueLogisticDetails',

  props: {
    logisticDetailsData: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      isCartPage: false
    };
  },

  computed: {
    logisticDetails() {
      return JSON.parse(this.logisticDetailsData);
    },
    showLogisticDetail() {
      return !this.isCartPage || (this.isCartPage && this.logisticDetails && this.logisticDetails.attributes)
    }
  },

  mounted() {
    this.isCartPage = !!document.querySelector('.page--cart');
  },
 }
</script>