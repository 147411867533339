import {
  HAS_DO_NOT_TRACK_DISABLED,
  VUE_URL__ADD_TO_CART,
  VUE_URL__CART_LOAD,
  VUE_URL__CLEAR_CART,
  VUE_URL__ADD_DISCOUNT_TO_CART,
} from '../../../_global-constants';
import {http} from "../../../utils/http-requests";
import mutationTypes from './mutationTypes';

export const CART_ACTION__INIT_CART = 'initCart';
export const CART_ACTION__ADD_TO_CART = 'addCart';
export const CART_ACTION__ADD_DISCOUNT_TO_CART = 'addDiscount';
export const CART_ACTION__CLEAR_DISCOUNT_CODE = 'clearDiscountCode';
export const CART_ACTION__UPDATE_LINE_ITEM = 'updateLineItem';
export const CART_ACTION__DELETE_FROM_CART = 'deleteFromCart';
export const CART_ACTION__SHOW_ERROR = 'showError';
export const CART_ACTION__CLEAR_CART = 'clearCart';
export const CART_ACTION__DISABLE_CHECKOUT = 'disableCartCheckout';

const normalizeQuantity = quantity => typeof quantity === 'string'
  ? Number(quantity.replace(',', '.'))
  : quantity
const actions = {
  async [CART_ACTION__INIT_CART]({ state, commit }, {isReload}) {
    if (!state.isInitialState && isReload === false) {return state.data;}

    try {
      let response = await http.get(VUE_URL__CART_LOAD)

      commit(mutationTypes.CART_MUTATION__SET_CART, response);
      if (response.discountCode) {
        commit(mutationTypes.CART_MUTATION__SET_DISCOUNT_CODE, {
          ...response.discountCode,
        });
      }

      return response;
    }
    catch (error) {
      return error
    }
  },

  async [CART_ACTION__ADD_TO_CART]({ commit }, { sku, quantity, tradingPrice }) {
    if (!sku) {throw new Error('No sku provided');}
    if (!quantity) {throw new Error('No quantity provided');}

    let bodyJson = {
      sku,
      requestedQty: normalizeQuantity(quantity),
    };

    if (tradingPrice !== null) {
      bodyJson.price = tradingPrice;
    }

    try {
      let response = await http.post(VUE_URL__ADD_TO_CART,bodyJson)

      // push to Bloomreach pixel
      if (HAS_DO_NOT_TRACK_DISABLED && window.dataLayer) {
        /* eslint-disable camelcase */
        window.BNS.addNonBlacklistedDataToDataLayer({
          event: 'add-to-cart', // Bloomreach
          prod_id: sku, // Bloomreach
        });
      }

      commit(mutationTypes.CART_MUTATION__SET_CART, response.cart);
      response.isSuccess = true;

      return response;
    }
    catch (error) {
      return error
    }
  },

  async [CART_ACTION__UPDATE_LINE_ITEM](
    { commit },
    { lineId, sku, quantity, tradingPrice },
  ) {
    if (!lineId) {throw new Error('No lineId provided');}
    if (!sku) {throw new Error('No sku provided');}
    if (!quantity) {throw new Error('No quantity provided');}

    let bodyJson = {
      sku,
      requestedQty: normalizeQuantity(quantity),
    };

    if (tradingPrice !== null) {
      bodyJson.price = tradingPrice;
    }
    const url = VUE_URL__ADD_TO_CART + '/' + lineId;

    try {
      // Use this to mimic 417 response
      // let response = http.put('http://www.mocky.io/v2/5e6b793c2d00006f008e8f3b')
      let response = await http.put(url, bodyJson);

      commit(mutationTypes.CART_MUTATION__SET_CART, response.cart);

      return response;
    }
    catch (error) {
      return error
    }
  },

  async [CART_ACTION__DELETE_FROM_CART]({ commit }, { lineId }) {
    if (!lineId) {throw new Error('No lineId provided');}
    const url = VUE_URL__ADD_TO_CART + '/' + lineId;

    try {
      let response = await http.delete(url);

      commit(mutationTypes.CART_MUTATION__SET_CART, response);

      return response;
    }
    catch (error) {
      return error
    }
  },

  [CART_ACTION__SHOW_ERROR](error) {
    if (!{}.hasOwnProperty.call(error, 'response')) {
      let notification = window.BNS.globalNotification(error);

      notification.show();
      throw new Error(error);
    }

    return error.response
      .json()
      .then(errResp => {
        let notification = window.BNS.globalNotification(errResp.message);

        notification.show();

        return true;
      })
      .catch(e => {
        console.error(e);

        return true;
      });
  },

  async [CART_ACTION__ADD_DISCOUNT_TO_CART]({ commit }, discountCode) {
    try {
      let body = {code: discountCode}
      const { message, isValid } = await http.post(VUE_URL__ADD_DISCOUNT_TO_CART, body)

      commit(mutationTypes.CART_MUTATION__SET_DISCOUNT_CODE, {
        isValid,
        message,
        code: isValid ? discountCode : null,
      });
    } catch (error) {
      commit(mutationTypes.CART_MUTATION__SET_DISCOUNT_CODE, {
        isValid: false,
        message: error.message,
        code: null,
      });
    }
  },

  [CART_ACTION__CLEAR_DISCOUNT_CODE]({ commit }) {
    commit(mutationTypes.CART_MUTATION__CLEAR_DISCOUNT_CODE);
  },

  async [CART_ACTION__CLEAR_CART]() {
    try {
      await http.delete(VUE_URL__CLEAR_CART);

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },

  [CART_ACTION__DISABLE_CHECKOUT]({ commit }, { lineItemId, status }) {
    commit(mutationTypes.CART_MUTATION__UPDATE_VALIDITY, { lineItemId, status })
  },
};

export default actions;
