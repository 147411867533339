import store from '../store/index';
import { HIERARCHY_NAMESPACE } from '../store/modules/facet-hierarchy/index';
import { getFacetName, getParentPath } from './facet-hierarchy';

export function extractFacetLabel(value) {
  let index = value.indexOf('|');

  if (index < 0) {
    return value;
  }

  return value.substring(index + 1);
}

export default function () {
  throw new Error('Not implemented');
}

const parseFacetFilters = facetFilters => {
  return facetFilters.map(facetFilter => facetFilter.map(facet => {
    const [category, value] = facet.split(":");

    return { category, value }
  }));
}

export function debounce(f, interval) {
  let timer = null;

  return (...args) => {
    clearTimeout(timer);

    return new Promise((resolve) => {
      timer = setTimeout(
        () => resolve(f(...args)),
        interval,
      );
    });
  };
}

const removeParentsOnInitialLoad = (category, pathToCategoriesMap) => {
  const getUrlForCategory = category => {
    const pathForCategory = Object.keys(pathToCategoriesMap).find(key => {
      return pathToCategoriesMap[key].toLowerCase() === category.toLowerCase();
    });

    if (pathForCategory) {
      const url = pathForCategory.split("/");

      return url[url.length - 1];
    }

    return category;
  }
  const hasInUrl = category => {
    let pathName = location.pathname;

    if (pathName.endsWith("/")){
      pathName = pathName.substring(0, (pathName.length - 1));
    }

    return pathName.includes(category.toLowerCase());
  }
  const isParentCategory = category => {
    const url = getUrlForCategory(category);

    return hasInUrl(`/${url}/`) || hasInUrl(`~${url}/`);
  };

  return category.filter(({ value }) => !isParentCategory(value));
}

export function removeFacetParent(category, pathToCategoriesMap) {
  let newCategory = category;
  const flatHierarchy = store.getters[`${HIERARCHY_NAMESPACE}/flatHierarchy`];
  const isHierarchyEmpty = Object.keys(flatHierarchy).length === 0;

  if (isHierarchyEmpty) {
    return removeParentsOnInitialLoad(category, pathToCategoriesMap);
  }

  category.forEach(filter => {
    const getItemByName = store.getters[`${HIERARCHY_NAMESPACE}/getItemByName`];
    const facet = getItemByName(filter.value);
    const parents = getParentPath(facet).map(getFacetName);

    newCategory = newCategory.filter(cat => !parents.includes(getFacetName(cat)));
  });

  return newCategory;
}

const removeParents = (parsedFacetFilters, pathToCategoriesMap) => {
  const result = [];

  parsedFacetFilters.forEach(category => {
    let newCategory = removeFacetParent(category, pathToCategoriesMap);

    result.push(newCategory);
  });

  return result;
}

// Backend requires sending only leafs for subcategories. For example,
// if we have the following hierarchy ticked "Gedistilleerd" -> "Whisky" -> "Canadian"
// only "Canadian" must be sent to Backend.
export function leaveOnlyLeafs(facetFilters, pathToCategoriesMap) {
  if (!facetFilters) {return;}
  const parsedFacetFilters = parseFacetFilters(facetFilters);
  const newFacetFilters = removeParents(parsedFacetFilters, pathToCategoriesMap);

  return newFacetFilters.map(facetFilter => facetFilter.map(filter => `${filter.category}:${filter.value}`));
};

/* Algolia expects hierarchy in following way:
  category: {
    name1: count1, // but, actually, instead of count object is present
    name2: count2
  }
  but backend sends it as array:
  category: [
    { name: "someName", count: 1, childValues: [...] },
    ...
  ]
*/
export function mapFacetArrayToObject(results) {
  results.forEach(result => {
    if (result.facets) {
      Object.keys(result.facets).forEach(facet => {
        const newFacet = result.facets[facet].reduce((acc, value) => {
          acc[value.name] = {
            count: value.count,
            name: value.name,
            value: value.name,
            childValues: value.childValues,
          };

          return acc;
        }, {});

        result.facets[facet] = newFacet;
      });
    }
  });

  return results;
}

export function addSkusForRequests(requests, skus) {
  requests.forEach(request => {
    request.params.skus = skus;
  });
}