export const CART_GETTER__CART = 'cart';
export const CART_GETTER__DISCOUNT_CODE = 'discountCode';
export const CART_GETTER__ORDER_LINES = 'orderLines';
export const CART_GETTER__TOTAL_ITEMS = 'totalItems';
export const CART_GETTER__LOGISTIC_DETAILS = 'logisticDetails';
export const CART_GETTER__IS_CART_EXISTING = 'isCartExisting';
export const CART_GETTER__GET_LINE_ITEM_BY_ID = 'getLineItemById';
export const CART_GETTER__TOTAL_PRICE = 'totalPrice';
export const CART_GETTER__TOTAL_NET = 'totalNet';
export const CART_GETTER__TOTAL_GROSS = 'totalGross';
export const CART_GETTER__CART_TOTALS = 'cartTotals';
export const CART_GETTER__IS_CHECKOUT_ENABLED = 'isCheckoutEnabled';
export const CART_GETTER__CHECKOUT_ERROR_MESSAGES = 'checkoutErrorMessages';
export const CART_GETTER__RATIONS = 'rations';
export const CART_GETTER__INVALID_LINE_ITEMS = 'hasInvalidLineItems';

const getters = {
  [CART_GETTER__CART]: state => state.data,

  [CART_GETTER__DISCOUNT_CODE]: state => state.discountCode,

  [CART_GETTER__ORDER_LINES]: state => state.data.lineItems,

  [CART_GETTER__TOTAL_ITEMS]: state => state.data.lineItems.length,

  [CART_GETTER__LOGISTIC_DETAILS]: state => state.data.logisticDetails,

  [CART_GETTER__IS_CART_EXISTING]: state => state.data.lineItems.length > 0,

  [CART_GETTER__GET_LINE_ITEM_BY_ID]: state => id => {
    return state.data.lineItems.find(lineItem => lineItem.id === id);
  },

  // Used in cart-icon to display the total price what the visitor needs to pay
  [CART_GETTER__TOTAL_PRICE]: state => state.data.totalPrice,

  [CART_GETTER__TOTAL_NET]: state => state.data.totalNet,

  [CART_GETTER__TOTAL_GROSS]: state => state.data.totalGross,

  // Cart totals is the calculated VAT, discounts, etc of the whole cart, except the total price
  [CART_GETTER__CART_TOTALS]: state => state.data.cartTotals,

  [CART_GETTER__IS_CHECKOUT_ENABLED]: state => state.data.isCheckoutEnabled,

  [CART_GETTER__CHECKOUT_ERROR_MESSAGES]: state => state.data.isCheckoutEnabled ? [] : state.data.messages,

  [CART_GETTER__RATIONS]: state => {
    if ({}.hasOwnProperty.call(state.data, CART_GETTER__RATIONS)) {
      return state.data[CART_GETTER__RATIONS];
    }

    return false;
  },

  [CART_GETTER__INVALID_LINE_ITEMS]: state => {
    return state.hasInvalidLineItems
  },
};

export default getters;