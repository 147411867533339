<template>
  <div>
    <vue-numeric-input
      :class="['text-align-center', inputClass]"
      v-bind="{ ...$props, ...$attrs }"
      :value="value"
      :maxValue="maxValue"
      :step="step"
      :disabled="isModalActive || $attrs.disabled"
      @input="onInput"
      v-on="$listeners"
    />
    <div
      v-if="hasErrorsCheck"
      class="form-notifications"
    >
      <div
        v-if="showErrors"
        class="is-invalid"
      >
        <span>{{ errorMessage.value }}</span>

        <button
          v-if="isOrderAndRequestAvailable && isCart"
          class="request-stock"
          :class="{ 'request-stock--disabled': isRequestStockDisabled }"
          @click="requestStock"
        >
          {{ requestStockLabel }}
        </button>
      </div>
    </div>
    <vue-request-and-order-popup
      v-if="isModalActive || isRequestAndNotifyPopupActive"
      :key="passProduct.id"
      :currentProduct="passProduct"
      :isCart="isCart"
      :isModalShown="isModalActive"
      :isRequestAndNotifyPopupActive="isRequestAndNotifyPopupActive"
      :requestedQuantity="value"
      :totalItemsInCart="totalItemsInCart"
      @hide-modal="$emit('hide-modal')"
      @close-notify-popup="$emit('hide-notify-popup')"
    />
  </div>
</template>

<script>
  import { GTMEvents } from '../consts/gtm_events';
  import { fillPlaceholderWithValue } from '../utils';
  import VueNumericInput from './numeric-input.vue';

  export default {
    name: 'VueProductCountInput',

    components: {
      VueNumericInput,
      VueRequestAndOrderPopup: () => import('./request-and-order-popup.vue'),
    },

    props: {
      inputClass: {
        type: String,
        default: '',
      },

      step: {
        type: [Number, String],
        default: 1,
      },

      maxOrderQuantityErrorMessage: {
        type: String,
        default: '',
      },

      // max allowed product quantity for specific product
      maxOrderQuantity: {
        type: [String, Number],
        default: 0,
      },

      stockQuantityErrorMessage: {
        type: String,
        default: '',
      },

      // totally available products count
      stockQuantity: {
        type: [String, Number],
        default: 0,
      },

      cartContainsMaxLimitMessage: {
        type: String,
        default: '',
      },

      totalItemsInCart: {
        type: [String, Number],
        default: 0,
      },

      showErrors: {
        type: Boolean,
        default: false,
      },

      initialValue: {
        type: [String, Number],
        default: '1',
      },

      isRequestOutOfStockQuantityAvailable: {
        type: String,
        default: 'false',
      },

      isRequestStockDisabled: {
        type: Boolean,
        default: false,
      },

      requestStockLabel: {
        type: String,
        default: 'Request stock',
      },

      isCart: {
        type: Boolean,
        default: false,
      },

      currentProduct: {
        type: Object,
        default: () => ({}),
      },

      isModalActive: {
        type: Boolean,
        default: false,
      },

      isOnStock: {
        type: Boolean,
        default: true,
      },

      isRequestAndNotifyPopupActive: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        openModal: this.isModalActive,
        value: Number(this.initialValue),
        isOrderLine: false,
      };
    },

    computed: {
      stockQuantityNumber() {
        return Number(this.stockQuantity);
      },

      totalItemsInCartNumber() {
        return Number(this.totalItemsInCart);
      },

      isOrderLineInCart() {
        return this.isCart && this.isOrderLine;
      },

      maxValue() {
        if (this.isOnStock) {
          const lowest = Math.min(this.stockQuantityNumber, this.maxOrderQuantityNumber);

          if (this.isOrderLineInCart) {
            return lowest;
          }

          return this.isOrderLine
            ? this.stockQuantityNumber
            : lowest - this.totalItemsInCartNumber;
        }

        return null;
      },

      maxOrderQuantityNumber() {
        const number = Number(this.maxOrderQuantity);

        return number === 0 ? Infinity : number;
      },

      maxOrderQuantityError() {
        const value = this.isOrderLineInCart
          ? this.maxOrderQuantityNumber
          : this.maxOrderQuantity - this.totalItemsInCart

        return fillPlaceholderWithValue(this.maxOrderQuantityErrorMessage, value);
      },

      stockQuantityError() {
        const value = this.isOrderLineInCart
          ? this.stockQuantityNumber
          : this.stockQuantity - this.totalItemsInCart

        return fillPlaceholderWithValue(this.stockQuantityErrorMessage, value);
      },

      isOrderAndRequestAvailable() {
        return this.isRequestOutOfStockQuantityAvailable === 'true';
      },

      passProduct() {
        return this.currentProduct;
      },

      errorMessage() {
        if (!this.isOnStock) {
          return;
        }

        if (this.totalItemsInCartNumber === this.maxOrderQuantityNumber) {
          return {
            value: this.cartContainsMaxLimitMessage,
            type: 'cartContainsMaxLimitMessage',
          };
        }

        if (this.value > (this.maxOrderQuantityNumber - this.totalItemsInCart)
          && this.maxOrderQuantityNumber < this.stockQuantityNumber) {
          return {
            value: this.maxOrderQuantityError,
            type: 'maxOrderQuantityError',
          };
        }

        if (this.value > (this.stockQuantityNumber - this.totalItemsInCart)) {
          return {
            value: this.stockQuantityError,
            type: 'stockQuantityError',
          };
        }

        return null;
      },

      hasErrors() {
        return this.value === 0 ? 0 : (this.showErrors && this.errorMessage)
      },

      hasErrorsCheck() {
        const hasStockQuantityError = this.errorMessage?.type === 'stockQuantityError';

        return this.hasErrors || hasStockQuantityError;
      },
    },

    mounted() {
      this.$nextTick(() => {
        this.isOrderLine = Boolean(this.$el.closest('.order-line'));
      });
    },

    methods: {
      onInput(event) {
        this.value = Number(event.target.value);
      },

      requestStock() {
        if (this.isRequestStockDisabled) {
          return
        }

        this.$emit('show-modal');

        window.dataLayer.push({
          event:GTMEvents.REQUEST_STOCK,
        });
      },

      closeModal() {
        this.openModal = false;
      },
    },

    watch: {
      initialValue(value) {
        this.value = value;
      },

      hasErrors(value) {
        this.$emit('has-error', value);
      },

      errorMessage(value) {
        this.$emit('error-message', value?.value);
      },
    },
  };
</script>

