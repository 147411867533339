<template>
  <div
    class="fragment--notification"
    :class="[
      levelCssClass,
      {
        'is-active': currentIsActive,
        'is-compact': isCompact,
        'is-global': isGlobal
      }
    ]"
  >
    <div
      v-if="isIconVisible"
      class="notification__icon"
    >
      <svg
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
      >
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          :xlink:href="iconReference"
        ></use>
      </svg>
    </div>

    <div v-if="Array.isArray(message)">
      <div
        v-for="text in message"
        :key="text"
        class="notification__message"
      >
        {{ text }}
      </div>
    </div>
    <div
      v-else
      v-html="message"
      ref="message_container"
      class="notification__message"
    >
      My message
    </div>

    <div
      v-if="canClose"
      class="notification__close"
      @click="hide"
    >
      <svg
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
      >
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#icon--close"
        ></use>
      </svg>
    </div>
  </div>
</template>

<script>
  const NOTIFICATION_LEVELS = ['info', 'warning', 'error', 'success'];

  export default {
    name: 'VueAlert',

    props: {
      level: {
        required: true,
        type: String,
        validator: level =>
          NOTIFICATION_LEVELS.indexOf(level.toLowerCase()) !== -1,
      },

      message: {
        required: true,
        type: [Array, String],
      },

      isCompact: {
        default: false,
        required: false,
        type: Boolean,
      },

      isGlobal: {
        default: false,
        required: false,
        type: Boolean,
      },

      isActiveByDefault: {
        default: false,
        required: false,
        type: Boolean,
      },

      canClose: {
        default: true,
        required: false,
        type: Boolean,
      },

      isIconVisible: {
        default: true,
        required: false,
        type: Boolean,
      },

      isAutoCompact: {
        default: false,
        required: false,
        type: Boolean,
      },

      interactiveChangeKey: {
        type: [Number, String],
        default: '',
      },

      isActive: {
        type: Boolean,
        default: false,
      },

      defaultErrorMessage: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        currentIsActive: this.isActive,
      };
    },

    computed: {
      levelData() {
        return this.level.toLowerCase();
      },

      iconReference() {
        return `#icon--notification--${this.levelData.toLowerCase()}`;
      },

      levelCssClass() {
        return `is-level-${this.levelData.toLowerCase()}`;
      },
    },

    async mounted() {
      if (this.isActiveByDefault) {
        this.show();
      }
    },

    methods: {
      hide() {
        this.currentIsActive = false;
        this.$emit('close');
        this.$emit('update:isActive', this.currentIsActive);
      },

      show() {
        this.currentIsActive = true;
        this.$emit('update:isActive', this.currentIsActive);
      },
    },

    watch: {
      isActive(newValue) {
        this.currentIsActive = newValue;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "./alert.scss";
</style>