import createStickyHeader from './sticky-header';
import addPaddingOnBodyElement from './padding-body';

(() => {
  document.addEventListener('DOMContentLoaded', () => {
    const containerHeaderElement = document.querySelector('.container--header');

    if (!containerHeaderElement) {return;}

    createStickyHeader(containerHeaderElement);
    addPaddingOnBodyElement(containerHeaderElement);
  });
})();
