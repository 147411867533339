export const http = {
  get,
  post,
  put,
  patch,
  delete: _delete,
};

async function get(url, options) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    ...options,
  };
  let response = await fetch(
    url,
    requestOptions,
  );
  const checkedResponse = await checkStatus(response);

  return await normalizeResponse(checkedResponse);
}

async function post(url, body, options) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: normalizeBody(body),
    ...options,
  };
  let response = await fetch(
    url,
    requestOptions,
  );
  const checkedResponse = await checkStatus(response);

  return await normalizeResponse(checkedResponse);
}

async function put(url, body, options) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: normalizeBody(body),
    ...options,
  };
  let response = await fetch(
    url,
    requestOptions,
  );
  const checkedResponse = await checkStatus(response);

  return await normalizeResponse(checkedResponse);
}

async function patch(url, body, options) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: normalizeBody(body),
    ...options,
  };
  let response = await fetch(
    url,
    requestOptions,
  );
  const checkedResponse = await checkStatus(response);

  return await normalizeResponse(checkedResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
// eslint-disable-next-line no-underscore-dangle
async function _delete(url, options) {
  const requestOptions = {
    method: 'DELETE',
    credentials: 'include',
    ...options,
  };
  let response = await fetch(
    url,
    requestOptions,
  );
  const checkedResponse = await checkStatus(response);

  return await normalizeResponse(checkedResponse);
}

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(response.statusText);

    error.response = response;
    error.status = response.status;
    throw error;
  }
}

function isString(variable) {
  return typeof variable === 'string';
}

function normalizeBody(requestBody) {
  return isString(requestBody) ? requestBody : JSON.stringify(requestBody);
}

async function normalizeResponse(response) {
  const text = await response.text();

  try {
    return JSON.parse(text);
  } catch {
    return text;
  }
}
