import { algoliaRequiredType } from '../../../_global-constants';
import { getFacetName, getParentPath, searchQueue } from '../../../utils/facet-hierarchy';
import mutationTypes from './mutationTypes';

const setFlatHierarchyItem = (state, item) => {
  const itemName = getFacetName(item);

  if (!state.flatHierarchy[itemName] || state.flatHierarchy[itemName].count === 0) {
    state.flatHierarchy[itemName] = item;
  }
}
const buildFlatHierarchy = (state, hierarchy) => {
  hierarchy.forEach((item) => {
    setFlatHierarchyItem(state, item);
    if (item.childValues && item.childValues.length > 0) {
      buildFlatHierarchy(state, item.childValues);
      item.childValues.forEach(child => {
        child.parent = item;
        child.value = child.name;
        child.isRefined = child.isRefined || false;
        child.type = algoliaRequiredType;
        setFlatHierarchyItem(state, child);
      });
    }
  });
}
const syncItemState = ({ item, index, state, facetIndex, hierarchy, level }) => {
  const changedItem = hierarchy.find(hierarchyItem => getFacetName(hierarchyItem) === getFacetName(item));

  item.isRefined = changedItem ? changedItem.isRefined : false;
  state.facets[facetIndex].levels[level].splice(index, 1, item);
}
const refineParent = ({ item, state, refine, hierarchy }) => {
  if (!item.parent) {
    return;
  }

  const parent = state.flatHierarchy[getFacetName(item.parent)];
  const refinedItem = hierarchy.find(item => getFacetName(item) === getFacetName(parent));

  if (!parent.isRefined && (refinedItem && !refinedItem.isRefined)) {
    refine(getFacetName(parent));
    parent.isRefined = true;
  }

  searchQueue.add(() => refineParent({ item: parent, state, refine, hierarchy }));
}
const refineParents = ({ hierarchy, state, refine }) => {
  const refinedItems = hierarchy.filter(item => item.isRefined);
  const hierarchyWithAllRefinedItems = addAbsentParentsToHierarchy(refinedItems, hierarchy, state);

  refinedItems.forEach(refinedItem => {
    const item = state.flatHierarchy[getFacetName(refinedItem)];

    item.isRefined = true;
    refineParent({ item, state, refine, hierarchy: hierarchyWithAllRefinedItems });
  });
}
const addAbsentParentsToHierarchy = (refinedItems, hierarchy, state) => {
  let newHierarchy = [...hierarchy];

  refinedItems.forEach(refinedItem => {
    const item = state.flatHierarchy[getFacetName(refinedItem)];
    const parents = getParentPath(item);

    parents.forEach(parent => {
      const hierarchyIncludesParent = newHierarchy.find(item => getFacetName(item) === getFacetName(parent));

      if (!hierarchyIncludesParent) {
        newHierarchy.push(parent);
      }
    });
  });

  return newHierarchy;
}
const findFacetIndexById = (state, facetId) => state.facets.findIndex(({ id }) => id === facetId);
const updateChildren = (levels, startLevel) => {
  if (levels[startLevel].length === 0) {
    for (let level = startLevel; level < levels.length; level++) {
      levels[level] = [];
    }
  }
}
const getChildrenForNextLevel = (items) => {
  return items.reduce((acc, item) => {
    return acc.concat(item.isRefined && item.childValues ? item.childValues : [])
  }, []);
}
const mutations = {
  [mutationTypes.SET_HIERARCHY](state, { facetId, hierarchy, refine }) {
    buildFlatHierarchy(state, hierarchy);

    refineParents({ hierarchy, state, refine });

    let facetIndex = findFacetIndexById(state, facetId);

    if (facetIndex === -1) {
      state.facets.push({ id: facetId, levels: [] });
      facetIndex = state.facets.length - 1;
    }

    let level = 0;

    while (level < state.facets[facetIndex].levels.length + 1) {
      const facetLevel = state.facets[facetIndex].levels[level];

      facetLevel = level === 0 ? hierarchy : facetLevel;
      if (facetLevel) {
        state.facets[facetIndex].levels.splice(level, 1, facetLevel);
        facetLevel.forEach((item, index) =>
          syncItemState({ item, index, state, facetIndex, hierarchy, level, refine }));
        if (facetLevel.length > 0) {
          state.facets[facetIndex].levels.splice(level + 1, 1, getChildrenForNextLevel(facetLevel));
          updateChildren(state.facets[facetIndex].levels, level + 1);
        }
      }
      level++;
    }
  },
  [mutationTypes.SET_ITEM_REFINEMENT](state, { item, isRefined }) {
    state.flatHierarchy[getFacetName(item)].isRefined = isRefined;
  },
};

export default mutations;
