import { VUE_URL__GET_INVOICES, VUE_URL__GET_INVOICE } from '../../../_global-constants';
import { downloadFile } from '../../../utils/index';
import {http} from "../../../utils/http-requests";
import mutationTypes from './mutationTypes';

async function downloadInvoice(orderId, invoice) {
  try {
    const response = await http.get(VUE_URL__GET_INVOICE(orderId, invoice));
    const blob = response.blob();

    if (blob.type === 'application/json') {
      handleNoFileException(blob);
    } else {
      downloadFile(blob, `invoice-${invoice}`);
    }
  } catch (err) {
    console.error('Error while loading invoice: ', err)
  }
}

function showNotification(text) {
  const notification = window.BNS.globalNotification(text);

  notification.show();
}

async function handleNoFileException(blob) {
  try {
    const text = await blob.text();
    const { error } = JSON.parse(text);

    showNotification(error);
  } catch (err) {
    console.error('Unexpected error: ', err)
  }
}

const actions = {
  downloadAllInvoices({ getters }, orderId) {
    const invoices = getters.invoicesByOrderId(orderId);

    return Promise.all(
      invoices.map(invoice => downloadInvoice(orderId, invoice)),
    );
  },

  async downloadOneInvoice({}, { orderId, invoiceId }) {
    return downloadInvoice(orderId, invoiceId);
  },

  async getInvoicesForOrder({ commit }, orderId) {
    try {
      const response = await http.get(VUE_URL__GET_INVOICES(orderId));
      const { invoices } = response.json();

      if (invoices.length > 0) {
        commit(mutationTypes.ADD_INVOICE, { orderId, invoices });
      }

      return invoices;
    } catch (err) {
      console.error('Error while getting invoices: ', err)
    }
  },
}

export default actions;
