import Glide from '@glidejs/glide';

const PRODUCT_CAROUSEL_CLASS_NAME = '.glide--product-carousel';
const ORDER_CONFIRMATION_CAROUSEL_ELEMENT =
  '.glide--order-confirmation-carousel';

(() => {
  document.addEventListener('DOMContentLoaded', () => {
    let sliderProductCarousel = document.querySelectorAll(
      PRODUCT_CAROUSEL_CLASS_NAME,
    );

    if (sliderProductCarousel) {
      [].forEach.call(sliderProductCarousel, productCarousel => {
        let glideProductCarousel = new Glide(productCarousel, {
          type: 'slider',
          startAt: 0,
          perView: 4,
          peek: 48,
          bound: true,
          gap: 0,
          breakpoints: {
            // breakpoint: mobile until tablet
            768: {
              peek: 0,
              perView: 1,
            },

            // breakpoint: tablet until desktop
            999: {
              perView: 1,
            },
          },
        });

        glideProductCarousel.on('run.after', function() {
          // eslint-disable-next-line no-underscore-dangle
          let isStart = glideProductCarousel._c.Run.isStart();
          // eslint-disable-next-line no-underscore-dangle
          let isEnd = glideProductCarousel._c.Run.isEnd();
          let hiddenClass = 'is-hidden';
          let arrowLeft = productCarousel.querySelector('.glide__arrow--left');
          let arrowRight = productCarousel.querySelector(
            '.glide__arrow--right',
          );

          if (isStart) {
            arrowLeft.classList.add(hiddenClass);
            arrowRight.classList.remove(hiddenClass);
          } else if (isEnd) {
            arrowRight.classList.add(hiddenClass);
            arrowLeft.classList.remove(hiddenClass);
          } else {
            arrowLeft.classList.remove(hiddenClass);
            arrowRight.classList.remove(hiddenClass);
          }
        });

        /* Mount slider */
        glideProductCarousel.mount();
      });
    }

    if (
      document.querySelectorAll(ORDER_CONFIRMATION_CAROUSEL_ELEMENT).length > 0
    ) {
      let orderConfirmationCarousel = null;
      const instanceOrderCarousel = () => {
        if (!orderConfirmationCarousel) {
          orderConfirmationCarousel = new Glide(
            ORDER_CONFIRMATION_CAROUSEL_ELEMENT,
            {
              type: 'carousel',
              startAt: 0,
              perView: 3,
              peek: 20,
              bound: true,
              gap: 14,
            },
          );
          orderConfirmationCarousel.mount();
        }
      };

      window.addEventListener('resize', function() {
        if (window.innerWidth < 1000) {
          instanceOrderCarousel();
        }
      });

      instanceOrderCarousel();
    }
  });
})();
