<template>
  <span>
    <span
      v-if="isCartExisting"
      :class="[
        'cart-icon__container',
        { 'cart-icon__container-border': totalItems }
      ]"
    >
      <svg
        class="cart-icon__icon"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        stroke="#ED1C24"
      >
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#icon--cart"
        ></use>
      </svg>
      <span
        v-if="totalItems"
        class="cart-icon__total-items"
      >
        {{ totalItems }}
      </span>
      <span
        v-if="totalItems"
        class="is-hidden-touch cart-icon__total-price"
      >
        {{ totalPrice }}
      </span>
    </span>
    <svg
      v-if="!isCartExisting"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      stroke="#ED1C24"
    >
      <use
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xlink:href="#icon--cart"
      ></use>
    </svg>
  </span>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';

  import { CART_NAMESPACE } from '../../store/modules/cart/index';
  import {
    CART_GETTER__TOTAL_ITEMS,
    CART_GETTER__TOTAL_PRICE,
    CART_GETTER__IS_CART_EXISTING,
  } from '../../store/modules/cart/getters';
  import {
    CART_ACTION__INIT_CART,
    CART_ACTION__SHOW_ERROR,
  } from '../../store/modules/cart/actions';

  const { mapGetters, mapActions } = createNamespacedHelpers(CART_NAMESPACE);

  export default {
    name: 'VueCartIcon',

    computed: {
      ...mapGetters({
        totalItems: CART_GETTER__TOTAL_ITEMS,
        totalPrice: CART_GETTER__TOTAL_PRICE,
        isCartExisting: CART_GETTER__IS_CART_EXISTING,
      }),
    },

    async mounted() {
      const container = this.$root.$refs.containerOrderLines || this.$el;
      const loader = this.$loading.show({
        active: true,
        container,
      });

      try {
        await this.initCart(false);
      } catch (error) {
        this.showError()
      } finally {
        loader.hide();
      }
    },

    methods: {
      ...mapActions({
        initCart: CART_ACTION__INIT_CART,
        showError: CART_ACTION__SHOW_ERROR,
      }),
    },
  };
</script>
