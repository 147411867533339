import dialogPolyfill from 'dialog-polyfill';
import { DomLoadedCheck } from '../utils/dom-loaded-check';

const dialogTriggersSelector = '.show-dialog';
const HAS_DIALOG_ACTIVATED_CSS_CLASS_NAME = 'has-dialog-activated';

function openDialogEvent(event, self) {
  let {body} = document;
  let dialog = document.querySelector(`#${self.dataset.modalWindow}`);

  if (!dialog) {
    return; // skip when modalWindow is not found
  }

  if (dialog.dataset.appendToBody) {
    body.appendChild(dialog);
  }

  dialog.addEventListener('close', () => {
    body.classList.remove(HAS_DIALOG_ACTIVATED_CSS_CLASS_NAME);
    document.body.style.overflowY = 'auto';
  });

  dialogPolyfill.registerDialog(dialog);

  if(event) {
    event.stopPropagation();
  }

  dialog.showModal();

  if (self.dataset.modalData) {
    dialog.dataset.param = self.dataset.modalData;
  }

  body.classList.add(HAS_DIALOG_ACTIVATED_CSS_CLASS_NAME);

  let closeIcons = document.querySelectorAll('.dialog__close-icon');

  [].forEach.call(closeIcons, closeIcon => {
    closeIcon.addEventListener('click', () => {
      dialog.close();
    });
  });
}

function addEventDialog(dialogTriggers) {
  [].forEach.call(dialogTriggers, dialogTrigger => {
    dialogTrigger.addEventListener('click', function(event) {
      openDialogEvent(event, this);
    });
  });
}

function init() {
  let dialogTriggers = document.querySelectorAll(dialogTriggersSelector);

  addEventDialog(dialogTriggers);

  window.addEventListener('click', function(event) {
    if (event.target.tagName.toUpperCase() === 'DIALOG') {
      event.target.close();
    }
  });
}

(() => {
  DomLoadedCheck(init);
})();

export default function() {
  throw new Error('Not implemented');
}

export { addEventDialog, openDialogEvent };
