export default {
  name: 'VuePayment',

  render() {
    return this.$scopedSlots.default({
      payInvoice: this.payInvoice,
    });
  },

  mounted() {
    this.selectInvoiceMethod();
    this.addClickListeners();
  },

  beforeDestroy() {
    this.removeClickListeners();
  },

  methods: {
    payInvoice() {
      this.$loading.show({
        active: true,
        container: document.querySelector('.order-confirmation-section__gutter'),
      });
    },

    getPaymentMethods() {
      return document.querySelectorAll('form.invoice-payment, div#dropin.payment-dropin-container');
    },

    addClickListeners() {
      this.getPaymentMethods().forEach((method) => {
        method.addEventListener('click', this.clickMethodListener);
      });
    },

    removeClickListeners() {
      this.getPaymentMethods().forEach((method) => {
        method.removeEventListener('click', this.clickMethodListener);
      });
    },

    clickMethodListener(event) {
      this.unselectMethods(event);
      this.selectClickedMethod(event);
    },

    unselectMethods(event) {
      const isClickInsideMethod = !!event.target.closest('.adyen-checkout__payment-method');

      if (!isClickInsideMethod) {
        return
      }
      const selectedMethod = document.querySelector('.adyen-checkout__payment-method--selected');

      if (selectedMethod) {
        const selectedRadioButton = selectedMethod.querySelector('.adyen-checkout__payment-method__radio--selected');

        selectedMethod.classList.remove('adyen-checkout__payment-method--selected');
        selectedRadioButton.classList.remove('adyen-checkout__payment-method__radio--selected');
      }
    },

    selectClickedMethod(event) {
      const selectedMethod = event.target.closest('.adyen-checkout__payment-method');

      if (selectedMethod) {
        const selectedRadioButton = selectedMethod.querySelector('.adyen-checkout__payment-method__radio');

        selectedMethod.classList.add('adyen-checkout__payment-method--selected');
        selectedRadioButton.classList.add('adyen-checkout__payment-method__radio--selected');
      }
    },

    selectInvoiceMethod() {
      const invoiceForm = document.querySelector('form.invoice-payment');
      const radioButton = invoiceForm.querySelector('.adyen-checkout__payment-method__radio');

      invoiceForm.classList.add('adyen-checkout__payment-method--selected');
      radioButton.classList.add('adyen-checkout__payment-method__radio--selected');
    },
  },
};
