var render = function render(_c,_vm){return _c('li',_vm._g({staticClass:"glide__slide glide__slide__hero-banner",attrs:{"data-link":_vm.props.dataLink,"data-index":_vm.props.dataIndex,"data-glide-autoplay":_vm.props.dataGlideAutoplay}},_vm.listeners),[(_vm.props.carouselItem.showOverlay)?_c('div',{staticClass:"glide__slide__hero-banner__overlay",style:({
      opacity: 0.6,
      background: `linear-gradient(90deg, ${_vm.props.carouselItem.overlayColor1}, ${_vm.props.carouselItem.overlayColor2})`
    })}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"glide__slide__hero-banner__content"},[(_vm.props.carouselItem.title)?_c('h1',{staticClass:"font-hero space-m-3 glide__hero-banner__content__item",class:[
        (_vm.props.carouselItem.titleTextColor != 'default') ? `text-${_vm.props.carouselItem.titleTextColor}` : '',
        (_vm.props.carouselItem.titlePosition != 'left') ? `is-text-${_vm.props.carouselItem.titlePosition}` : ''
      ]},[_vm._v("\n      "+_vm._s(_vm.props.carouselItem.title)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.props.carouselItem.content)?_c('p',{staticClass:"font-regular-body-copy space-m-3 glide__hero-banner__content__item",class:[
        (_vm.props.carouselItem.contentTextColor != 'default') ? `text-${_vm.props.carouselItem.contentTextColor}` : '',
        (_vm.props.carouselItem.contentPosition != 'left') ? `is-text-${_vm.props.carouselItem.contentPosition}` : ''
      ]},[_vm._v("\n      "+_vm._s(_vm.props.carouselItem.content)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"glide__hero-banner__content-wrapper"},[(
          _vm.props.carouselItem.showButton
            && _vm.props.carouselItem.buttonInternalLink
            && _vm.props.carouselItem.buttonExternalLink == ''
        )?_c('a',{staticClass:"button glide__hero-banner__content__item intrn",class:[
          (_vm.props.carouselItem.buttonPosition != 'left') ? `is-item-${_vm.props.carouselItem.buttonPosition}` : '',
          (_vm.props.carouselItem.invertButtonColor) ? 'is-secondary' : 'is-primary',
          _vm.props.carouselItem.buttonColor
        ],attrs:{"href":_vm.props.carouselItem.buttonInternalLink}},[_vm._v("\n        "+_vm._s(_vm.props.carouselItem.buttonText)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.props.carouselItem.showButton && _vm.props.carouselItem.buttonExternalLink != '')?_c('a',{staticClass:"button glide__hero-banner__content__item hide-external-link-icon",class:[
          (_vm.props.carouselItem.buttonPosition != 'left') ? `is-item-${_vm.props.carouselItem.buttonPosition}` : '',
          (_vm.props.carouselItem.invertButtonColor) ? 'is-secondary' : 'is-primary',
          _vm.props.carouselItem.buttonColor
        ],attrs:{"href":'https://' + _vm.props.carouselItem.buttonExternalLink,"target":"_blank"}},[_vm._v("\n        "+_vm._s(_vm.props.carouselItem.buttonText)+"\n      ")]):_vm._e(),_vm._v(" "),(
          _vm.props.carouselItem.linkText != ''
            && _vm.props.carouselItem.linkInternalLink
            && _vm.props.carouselItem.linkExternalLink == ''
        )?_c('a',{staticClass:"is-link text-uppercase arrow-right glide__hero-banner__content__item",class:[
          (_vm.props.carouselItem.linkTextColor != 'default') ? 'text-' + _vm.props.carouselItem.linkTextColor : '',
          (_vm.props.carouselItem.linkPosition != 'left') ? 'is-text-' + _vm.props.carouselItem.linkPosition : ''
        ],attrs:{"href":_vm.props.carouselItem.linkInternalLink}},[_vm._v("\n        "+_vm._s(_vm.props.carouselItem.linkText)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.props.carouselItem.linkText != '' && _vm.props.carouselItem.linkExternalLink != '')?_c('a',{staticClass:"is-link text-uppercase arrow-right glide__hero-banner__content__item hide-external-link-icon",attrs:{"href":'https://' + _vm.props.carouselItem.linkExternalLink,"target":"_blank","classappend":[
          (_vm.props.carouselItem.linkTextColor != 'default') ? 'text-' + _vm.props.carouselItem.linkTextColor : '',
          (_vm.props.carouselItem.linkPosition != 'left') ? 'is-text-' + _vm.props.carouselItem.linkPosition : ''
        ]}},[_vm._v("\n        "+_vm._s(_vm.props.carouselItem.linkText)+"\n      ")]):_vm._e()])]),_vm._v(" "),_c('div',[(!_vm.props.video)?_c('picture',{staticClass:"glide__hero-banner__picture",attrs:{"id":"image_hero_banner_carousel"}},[_c('img',{staticClass:"is-hidden-mobile is-hidden-tablet-only",attrs:{"src":_vm.props.images.desktop.src,"alt":"banner"}}),_vm._v(" "),_c('img',{staticClass:"is-hidden-mobile is-hidden-desktop",attrs:{"src":_vm.props.images.tablet.src,"alt":"banner"}}),_vm._v(" "),_c('img',{staticClass:"is-hidden-tablet-only is-hidden-desktop",attrs:{"src":_vm.props.images.mobile.src,"alt":"banner"}})]):_c('div',{staticClass:"glide__hero-banner__picture"},[_c('video',{attrs:{"src":_vm.props.video,"muted":"","loop":"","autoplay":"","playsinline":""},domProps:{"muted":true}},[_vm._v("\n        Video Not supported\n      ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }