import { DomLoadedCheck } from '../utils/dom-loaded-check';

const GO_TO_TOP_BUTTON_CLASS = '.go-to-top-button__container';

function init() {
  const goToTopButton = document.querySelector(GO_TO_TOP_BUTTON_CLASS);

  if (!goToTopButton) {return;}

  goToTopButton.addEventListener('click', () => {
    window.scrollTo(0, 0);
  });

  window.addEventListener('scroll', () => {
    if (window.pageYOffset > 600) {
      if (!goToTopButton.classList.contains('btnEntrance')) {
        goToTopButton.classList.remove('btnExit');
        goToTopButton.classList.add('btnEntrance');
        goToTopButton.style.display = 'block';
      }
    } else {
      if (goToTopButton.classList.contains('btnEntrance')) {
        goToTopButton.classList.remove('btnEntrance');
        goToTopButton.classList.add('btnExit');
        goToTopButton.style.display = 'none';
      }
    }
  });
}

(() => {
  DomLoadedCheck(init);
})();
