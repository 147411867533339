import { DomLoadedCheck } from '../utils/dom-loaded-check';

const INPAGE_NAVIGATION_CLASS_NAME = '.inpage-navigation__toggle';
const INPAGE_NAVIGATION_ACTIVE_ITEM_CLASS_NAME =
  '.inpage-navigation__title.active';
const INPAGE_NAVIGATION_PARENT_CLASS_NAME = '.inpage-navigation__items--parent';
const INPAGE_NAVIGATION_SELECTED_CLASS_NAME = 'selected';
const INPAGE_NAVIGATION_TOGGLE_LABEL_CLASS_NAME = '.toggle--inpage-navigation';

function init() {
  let inpageNavigationToggle = document.querySelectorAll(
    INPAGE_NAVIGATION_CLASS_NAME,
  );

  if (inpageNavigationToggle) {
    [].forEach.call(inpageNavigationToggle, checkbox => {
      checkbox.addEventListener('change', () => {
        if (checkbox.checked) {
          [].forEach.call(inpageNavigationToggle, checkbox2 => {
            checkbox2.checked = false;
          });
          checkbox.checked = true;
        }
      });
    });
  }

  let inpageNavigationActiveItem = document.querySelector(
    INPAGE_NAVIGATION_ACTIVE_ITEM_CLASS_NAME,
  );

  if (inpageNavigationActiveItem) {
    const parentListItem = inpageNavigationActiveItem.closest(
      INPAGE_NAVIGATION_PARENT_CLASS_NAME,
    );

    parentListItem.querySelector(INPAGE_NAVIGATION_CLASS_NAME).checked = true;
    parentListItem
      .querySelector(INPAGE_NAVIGATION_TOGGLE_LABEL_CLASS_NAME)
      .classList.add(INPAGE_NAVIGATION_SELECTED_CLASS_NAME);
  }
}

(() => {
  DomLoadedCheck(init);
})();
