import { DomLoadedCheck } from '../utils/dom-loaded-check';

const SWITCH_ITEM_CLASS = '.password-reveal-icon';

function init() {
  let switchList = document.querySelectorAll(SWITCH_ITEM_CLASS);

  [].forEach.call(switchList, item => {
    item.addEventListener('click', event => {
      event.stopPropagation();

      let clickedItem = event.target;
      let itemToSwitch = clickedItem.dataset.for;
      let input = document.getElementById(itemToSwitch);

      if (input.getAttribute('type') === 'password') {
        input.setAttribute('type', 'text');
      } else {
        input.setAttribute('type', 'password');
      }
    });
  });
}

(() => {
  DomLoadedCheck(init);
})();
