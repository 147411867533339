import {
  VUE_URL__REQUEST_STOCK,
  VUE_URL__UNSUBSCRIBE_STOCK_NOTIFICATIONS,
  VUE_URL__SUBSCRIBE_STOCK_NOTIFICATIONS,
  VUE_URL__BARCODE_SEARCH,
} from '../../../_global-constants';
import {http} from "../../../utils/http-requests";
import mutationTypes from './mutationTypes';


const actions = {
  async requestStock({}, data) {
    try {
      const response = await http.post(VUE_URL__REQUEST_STOCK, data)

      return response;
    } catch(error) {
      console.error('Error while requesting stock: ', error);
      throw error;
    }
  },

  setHintStatus({ commit }, status) {
    commit(mutationTypes.UPDATE_HINT_STATUS, status);
  },

  async removeSubscription({}, sku) {
    try {
      let url = `${VUE_URL__UNSUBSCRIBE_STOCK_NOTIFICATIONS}${sku}`;
      const response = await http.delete(url);

      return response;
    } catch(error) {
      console.error('Error while removing subscription: ', error);

      throw error;
    }
  },

  async fetchSuggestions(_, payload) {
    try {
      const response = await http.get(payload.url,{signal: payload.signal})

      return response;

    } catch (errorResponse) {
      throw errorResponse;
    }
  },

  async addSubscription( _ , sku) {
    try {
      const response = await http.post(VUE_URL__SUBSCRIBE_STOCK_NOTIFICATIONS, {sku})

      return response;
    } catch(error) {
      console.error('Error while creating subscription: ', error);
      throw error;
    }
  },


  async fetchSKUsByBarcodeScan(_, barcode){
    try {
      const response = await http.post(VUE_URL__BARCODE_SEARCH, {barcode})

      return response;
    } catch(error) {
      console.error('Error while fetching products By Barcode: ', error);
      throw error;
    }
  },
};

export default actions;
