<template>
  <label class="has-float-label">
    <input
      :id="inputId"
      :type="type"
      :placeholder="placeholder"
      :name="name"
      :value="value"
      :aria-label="label"
      :tabindex="$attrs.tabindex || 0"
      :title="label"
      :class="['placeholder-shown', additionalClass]"
      v-bind="$attrs"
      v-on="listeners"
    />
    <span :data-optional-label="optionalLabel">{{ label }}</span>
    <slot></slot>
  </label>
</template>

<script>
  import uniqInputId from '../../utils/uniq-id';

  const regExpForSpecialSymbols = /[?¿¡"`‘“”«»#%&@^=~$*+±§.,:;|‒–—―‐‹›<>{}⟨⟩/[\]()\\]/gi;
  const sanitizeOfValue = (value, regExp) => value.replace(regExp, '');

  export default {
    name: 'VueInput',

    inheritAttrs: false,

    props: {
      name: {
        type: String,
        default: 'input',
      },

      type: {
        type: String,
        default: 'text',
      },

      value: {
        type: [Number, String, Date],
        default: '',
      },

      id: {
        type: String,
        default: '',
      },

      placeholder: {
        type: String,
        default: ' ',
      },

      label: {
        type: String,
        default: '',
      },

      optionalLabel: {
        type: String,
        default: '',
      },

      additionalClass: {
        type: String,
        default: '',
      },

      isSanitize: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.onChange,
          focus: this.onFocus,
          blur: this.onBlur,
          keydown: this.onKeydown,
        };
      },

      inputId() {
        return this.id || uniqInputId(this.label);
      },
    },

    methods: {
      onChange(event) {
        let { value } = event.target;

        if (this.isSanitize) {
          value = sanitizeOfValue(value, regExpForSpecialSymbols);
        }

        this.$emit('change', event, value);
      },

      onFocus(value) {
        this.$emit('focus', value);
      },

      onBlur(value) {
        this.$emit('blur', value);
      },

      onKeydown(event) {
        let { value } = event.target;

        if (this.isSanitize) {
          const isSymbolDisallowed = regExpForSpecialSymbols.test(event.key);

          if (isSymbolDisallowed) {
            value = sanitizeOfValue(value, regExpForSpecialSymbols);
            event.preventDefault();
          }
        }

        this.$emit('keydown', event, value);
      },
    },
  };
</script>
