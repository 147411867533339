<template>
  <div>
    <div
      class="filter-container"
      :class="{ 'is-hidden-touch': !isFilterView }"
    >
      <div class="filter-close-icon is-hidden-desktop">
        <svg
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          @click="closeFilterView()"
        >
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="#icon--close"
          ></use>
        </svg>
      </div>

      <div class="filter-section">
        <div class="filter-title is-flex">
          <div class="font-h3 text-uppercase">
            {{ translations.filterTitle }}
          </div>
          <ais-clear-refinements>
            <div
              class="text-uppercase is-link-secondary filter-selections_all-option"
              slot-scope="{ canRefine, refine, createURL }"
            >
              <a
                v-if="canRefine"
                v-html="translations.clearSearchFilter"
                :href="createURL()"
                class="is-link is-color-secondary"
                @click.prevent="refine"
              ></a>
            </div>
          </ais-clear-refinements>
        </div>
      </div>

      <div
        v-for="(filter, index) in filters"
        :key="index"
        class="filter-section"
      >
        <input
          :id="`panel-${filter.id}`"
          type="checkbox"
          name="panel"
          checked
        />
        <div class="accordion__content font-small-body-copy">
          <div class="accordion__body">
            <ais-refinement-list
              searchable
              showMore
              :attribute="filter.id"
              :limit="998"
              :showMoreLimit="999"
              :transformItems="transformFacetValueItems"
            >
              <div
                slot-scope="{
                  items,
                  isShowingMore,
                  canToggleShowMore,
                  refine,
                  toggleShowMore,
                }"
              >
                <hr v-if="items.length > 0" />
                <vue-hierarchy
                  :filter="filter"
                  :initialItems="items"
                  :refine="refine"
                />

                <button
                  v-if="canToggleShowMore"
                  class="show-all-panel is-tertiary has-no-margin text-grey text-uppercase"
                  @click="toggleShowMore"
                >
                  <span
                    v-if="isShowingMore"
                    class="more-less-link is-link min-right"
                  >
                    {{ translations.showLessSearch }}
                  </span>
                  <span
                    v-else
                    class="more-less-link is-link plus-right"
                  >
                    {{ translations.showMoreSearch }}
                  </span>
                </button>
              </div>
            </ais-refinement-list>
          </div>
        </div>
      </div>

      <hr />
      <ais-pagination>
        <div
          slot-scope="{ nbHits }"
          class="button-container is-hidden-desktop"
        >
          <button
            type="button"
            class="is-primary has-no-max-width has-no-margin"
            @click="closeFilterView()"
          >
            <span
              remove="tag"
            >
              {{ translations.showHitsMessagePrefix }}
            </span>
            {{ nbHits }}
            <span
              remove="tag"
            >
              {{ translations.showHitsMessageSuffix }}
            </span>
          </button>
        </div>
      </ais-pagination>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import {
    AisClearRefinements,
    AisRefinementList,
    AisPagination,
  } from 'vue-instantsearch';

  import VueHierarchy from '../hierarchy/hierarchy.vue'
  import { getterTypes, TRANSLATIONS_NAMESPACE } from '../../store/modules/translations';
  import { getFacetName } from '../../utils/facet-hierarchy';
  import { VUE_EVENT_NAME__SHOW_FILTERS } from '../../_global-constants';

  export default {
    name: 'VueFilter',
    components: {
      AisClearRefinements,
      AisRefinementList,
      AisPagination,
      VueHierarchy,
    },
    props: {
      filters: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        isFilterView: false,
      }
    },
    computed: {
      ...mapGetters(TRANSLATIONS_NAMESPACE, {
        translations: getterTypes.TRANSLATIONS,
      }),
    },
    mounted() {
      this.$root.$on(VUE_EVENT_NAME__SHOW_FILTERS, this.openFilterView);
    },
    destroyed() {
      this.$root.$off(VUE_EVENT_NAME__SHOW_FILTERS, this.openFilterView)
    },
    methods: {
      openFilterView() {
        this.isFilterView = true;
        document.body.style.overflowY = 'hidden';
        document.body.classList.add('has-dialog-activated');
      },
      closeFilterView() {
        this.isFilterView = false;
        document.body.classList.remove('has-dialog-activated');
        document.body.style.overflowY = 'auto';
      },
      transformFacetValueItems(items) {
        return items.map(item => ({
          ...item,
          count: item.count.count || item.count,
          childValues: item.count.childValues,
          value: item.value,
          highlighted: getFacetName(item.count),
          label: getFacetName(item.count),
        }));
      },
    },
  }
</script>
