import qs from 'qs';

/* eslint-disable import/extensions */
import { history as historyRouter } from 'instantsearch.js/es/lib/routers';
import { simple as simpleMapping } from 'instantsearch.js/es/lib/stateMappings';
/* eslint-enable import/extensions */

import {
  VUE_URL__SEARCH_QUERY,
  VUE_URL__SEARCH_FACET,
  VUE_URL__SEARCH_LISTS_QUERY,
  SEARCH_BY_BARCODE_MULTIPLE_SKU_URL,
} from '../_global-constants';
import {
  leaveOnlyLeafs,
  mapFacetArrayToObject,
  debounce,
  addSkusForRequests,
} from '../utils/search-utils';
import { searchQueue } from '../utils/facet-hierarchy';
import { http } from '../utils/http-requests';

let lastRequest = null;
let lastResponse = null;
const debouncedSearch = debounce((requests, vueContext) => {
  let loader = vueContext.$loading.show({
    active: true,
    container: vueContext.$el,
  });

  requests.forEach(request => {
    request.params.facetFilters = leaveOnlyLeafs(
      request.params.facetFilters,
      vueContext.pathToCategoriesMap,
    );
  });

  const isCached = JSON.stringify(requests) === JSON.stringify(lastRequest);
  const { query } = requests?.[0]?.params ?? '';

  if (isCached) {
    loader.hide();

    return Promise.resolve(lastResponse);
  }

  lastRequest = requests;

  let url = VUE_URL__SEARCH_QUERY;

  if (vueContext.searchType === 'myListsEntries') {
    url = VUE_URL__SEARCH_LISTS_QUERY;
    if (!isValidListInput(query) && lastResponse) {
      return Promise.resolve(lastResponse);
    }
  }

  if (window.location.href.includes(SEARCH_BY_BARCODE_MULTIPLE_SKU_URL)) {
    const skus = window.location.href.split('skuID=')[1].split(',');

    addSkusForRequests(requests, skus);
  }

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      requests,
    }),
  })
    .then(response => response.json())
    .then(response => {
      if (response.keywordRedirect && response.keywordRedirect.redirectedUrl) {
        window.location.href = response.keywordRedirect.redirectedUrl;
      }
      if (vueContext) {
        vueContext.$root.$emit('hidessrsearch');
      }

      return response;
    })
    .then(response => {
      response.results = mapFacetArrayToObject(response.results);
      searchQueue.next();
      lastResponse = response;
      loader.hide();

      return response;
    });
}, 300);
const customSearchClient = {
  loadContext(vueComponent) {
    this.vueContext = vueComponent;

    return this;
  },
  search(requests) {
    return debouncedSearch(requests, this.vueContext);
  },
  async searchForFacetValues(requests) {
    return await http.post(VUE_URL__SEARCH_FACET, requests);
  },
};
// @TODO: move to separate file.
const fixedFacetRouting = historyRouter({
  // converts the routeState to URL
  createURL({ routeState, location }) {
    // get protocol and host (including port, if present)
    let baseUrl = location.href.substring(
      0,
      location.href.indexOf(location.host) + location.host.length,
    );
    let categoriesPaths;

    if (routeState.categories) {
      categoriesPaths = routeState.categories
        .split('~')
        .map(cat => {
          let value = this.categoriesToPathMap[cat];

          // fallback to keys if present
          if (value == null && this.categoriesToKeysMap) {
            value = this.categoriesToKeysMap[cat];
          }

          return value;
        })
        .join('~');
    }
    let routeStateArray = [this.leadingPath, categoriesPaths];

    if (routeState.page > 1) {
      routeStateArray.push('p');
      routeStateArray.push(routeState.page);
    }
    let url = baseUrl + routeStateArray.join('/');
    let stateOnQueryString = [];

    if (routeState.query && routeState.query.trim() !== '') {
      stateOnQueryString.push('q=' + encodeURIComponent(routeState.query));
    }
    if (routeState.secundaryFacets) {
      stateOnQueryString.push('f=' + qs.stringify(routeState.secundaryFacets));
    }
    if (routeState.sortBy) {
      stateOnQueryString.push('s=' + encodeURIComponent(routeState.sortBy));
    }
    if (stateOnQueryString.length === 0) {
      return url;
    }

    return url + '?' + stateOnQueryString.join('&');
  },
  // converts the URL to routeState
  parseURL({ location }) {
    let { pathname } = location;
    let leadingPathIndex = pathname.indexOf(this.leadingPath);

    if (leadingPathIndex === -1) {
      console.error(
        'leading path [' +
          this.leadingPath +
          '] not found in [' +
          pathname +
          ']',
      );

      return {};
    }
    let routeStateString = pathname.substring(
      leadingPathIndex + this.leadingPath.length,
    );
    const routeStateValues = routeStateString.match(
      // split categories and pagination
      /^(\/(.*?))?(\/p\/(.*?))?$/,
    );

    if (routeStateValues == null) {
      return {};
    }
    let categoriesValue;

    if (routeStateValues[2]) {
      let categoriesPaths = decodeURIComponent(
        //replace any + in the url to space (decodeURIComponent does not this)
        routeStateValues[2].replace(/\+/g, ' '),
      );

      categoriesValue = categoriesPaths
        .split('~')
        .map(cat => {
          let key = cat.toLowerCase();

          if (key.endsWith('/')) {
            key = key.slice(0, -1); // remove trailing /
          }
          let value = this.pathToCategoriesMap[key];

          //fallback to keys if present
          if (value == null && this.keysToCategoriesMap) {
            value = this.keysToCategoriesMap[key];
          }

          return value;
        })
        .join('~');
    }
    let page = 1;

    if (routeStateValues[4]) {
      page = parseInt(
        decodeURIComponent(routeStateValues[4]).replace(/\+/g, ' '),
        10,
      );
      if (isNaN(page)) {
        page = 1;
      }
    }
    let params = new URLSearchParams(location.search);
    let query = params.get('q') == null ? '' : params.get('q');
    let secundaryFacets =
      params.get('f') === null ? null : qs.parse(params.get('f'));
    let sortBy = params.get('s') === null ? null : params.get('s');
    let routeState = {
      categories: categoriesValue,
      page: page,
      query: query,
      secundaryFacets: secundaryFacets,
      sortBy: sortBy,
    };

    return routeState;
  },
});
const isValidListInput = (q)=> {
  return q.length > 2 || q === '';
}

export default {
  name: 'VueInstantSearch',
  props: {
    leadingPath: String,
    pathToCategoriesMap: Object,
    keysToCategoriesMap: Object,
    searchType: {
      type: String,
      default: 'products',
    },
  },
  render() {
    return this.$scopedSlots.default({
      searchClient: this.searchClient,
      pathToCategoriesMap: this.pathToCategoriesMap,
      keysToCategoriesMap: this.keysToCategoriesMap,
      routing: this.routing,
      simpleRouting: this.simpleRouting,
      fixedFacetRouting: this.fixedFacetRouting,
      isValidListInput: isValidListInput,
    });
  },
  data() {
    return {
      baseUrl: '',
      searchClient: customSearchClient.loadContext(this),
      simpleRouting: {
        router: historyRouter(),
        stateMapping: simpleMapping(),
      },
      fixedFacetRouting: {
        router: this.createFixedFacetRouting(),
        stateMapping: {
          // converts the uiState to the routeState
          stateToRoute(uiState) {
            let secundaryFacets;

            if (uiState.refinementList) {
              secundaryFacets = Object.assign({}, uiState.refinementList);
              // remove primary facet
              if (secundaryFacets['category']) {
                delete secundaryFacets['category'];
              }
              // if this was the only facet remove the secundaryFacets
              if (Object.keys(secundaryFacets).length === 0) {
                secundaryFacets = undefined;
              }
            }
            let routeState = {
              query: uiState.query || '',
              categories:
                (uiState.refinementList
                  && uiState.refinementList.category
                  && uiState.refinementList.category.join('~'))
                || 'all',
              secundaryFacets: secundaryFacets,
              page: uiState.page || 1,
              sortBy: uiState.sortBy,
            };

            return routeState;
          },
          // converts the routeState to the uiState
          routeToState(routeState) {
            if (routeState.categories === 'all') {
              routeState.categories = undefined;
            }
            let refinementList;

            if (routeState.secundaryFacets) {
              refinementList = routeState.secundaryFacets;
            } else {
              refinementList = [];
            }
            if (routeState.categories) {
              let selectedCategories = routeState.categories.split('~');

              if (selectedCategories.length > 0) {
                refinementList.category = selectedCategories;
              }
            }
            let uiState = {
              refinementList: refinementList,
              page: routeState.page,
              query: routeState.query,
              sortBy: routeState.sortBy,
            };

            return uiState;
          },
        },
      }, // ends routing
    };
  },
  methods: {
    createFixedFacetRouting() {
      fixedFacetRouting.leadingPath = this.leadingPath;
      fixedFacetRouting.pathToCategoriesMap = this.pathToCategoriesMap;
      fixedFacetRouting.keysToCategoriesMap = this.keysToCategoriesMap;

      // create reverse maps
      let categoriesToPathMap = {};

      for (let key in this.pathToCategoriesMap) {
        categoriesToPathMap[this.pathToCategoriesMap[key]] = key;
      }
      fixedFacetRouting.categoriesToPathMap = categoriesToPathMap;

      if (this.keysToCategoriesMap) {
        let categoriesToKeysMap = {};

        for (let key in this.keysToCategoriesMap) {
          categoriesToKeysMap[this.keysToCategoriesMap[key]] = key;
        }
        fixedFacetRouting.categoriesToKeysMap = categoriesToKeysMap;
      }

      return fixedFacetRouting;
    },
  },
};

