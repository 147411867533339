<template>
  <div
    class="barcode-modal"
    :class="{ 'is-shown': isReady }"
  >
    <button
      class="barcode-modal-close has-no-margin has-no-max-width"
      @click="stopScan"
    >
      <svg>
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#icon--cross"
        ></use>
      </svg>
    </button>
    <div class="barcode-modal-text">
      {{ translations.barcodeModalText }}
    </div>
    <div
      id="barcode-reader"
      class="barcode-reader"
    ></div>
  </div>
</template>

<script>
  import { Html5Qrcode } from 'html5-qrcode';
  import { mapGetters } from 'vuex';
  import {
    TRANSLATIONS_NAMESPACE,
    getterTypes as translationGetterTypes,
  } from '../../../store/modules/translations';
  import { sleep } from '../../../utils/index';

  export default {
    name: 'VueBarcodeModal',

    data() {
      return {
        isReady: false,
        html5QrCode: null,
        message: '',
        config: {
          fps: 10,
          qrbox: 250,
        },
      };
    },

    computed: {
      ...mapGetters(TRANSLATIONS_NAMESPACE, {
        translations: translationGetterTypes.TRANSLATIONS,
      }),
    },

    mounted() {
      this.html5QrCode = new Html5Qrcode('barcode-reader');
      this.startScan();
    },

    destroyed() {
      this.html5QrCode = null;
    },

    methods: {
      async startScan() {
        let timer;

        try {
          timer = setTimeout(() => {
            this.message = this.translations.barcodeCameraAccessNote;
          }, 1500);
          const startCamera = this.html5QrCode.start(
            { facingMode: 'environment' },
            this.config,
            this.successCallback,
            this.errorCallback,
          );

          this.message = this.translations.barcodeCameraPreparing + '...';

          await startCamera;

          this.isReady = true;
        } catch (error) {
          this.isReady = false;
          await sleep(500);
          this.errorCallback(error);
        } finally {
          clearTimeout(timer);
          this.message = '';
        }
      },

      async stopScan() {
        try {
          this.isReady = false;
          await this.html5QrCode.stop();
          this.html5QrCode.clear();
          this.$emit('stopScan');
        } catch (error) {
          this.errorCallback(error);
        }
      },

      successCallback(decodedText, decodedResult) {
        this.$emit('scan', decodedText, decodedResult);
        this.stopScan();
      },

      errorCallback(error) {
        this.$emit('error', error);
      },
    },
    watch: {
      message(newV) {
        this.$emit('messageUpdate', newV);
      },
    },
  };
</script>
