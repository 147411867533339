import mutationTypes from './mutationTypes';

const actions = {
  updateTranslations({ commit }, newTranslation) {
    commit(mutationTypes.UPDATE_TRANSLATIONS, newTranslation);
  },

  setDefaultTranslations({ commit }, defaultTranslations) {
    commit(mutationTypes.UPDATE_TRANSLATIONS, defaultTranslations);
    commit(mutationTypes.DEFAULT_TRANSLATIONS_ADDED, true);
  },
};

export default actions;
