<template>
  <div class="form-select">
    <label class="has-float-label">
      <div class="select-container">
        <select
          v-model="selectedValue"
          :name="name"
          v-bind="{ ...$props, ...$attrs }"
          data-qa="dropdown"
          v-on="$listeners"
          @change="onSelect"
        >
          <option
            v-for="{ label, value } in normalizedOptions"
            :key="value"
            :value="value"
          >
            {{ label }}
          </option>
        </select>
      </div>
      <span v-if="dropdownLabel">{{ dropdownLabel }}</span>
    </label>
  </div>
</template>
<script>
  export default {
    name: 'VueDropdown',

    props: {
      // Should be either array of strings
      // or array of { label, value } objects
      options: {
        type: Array,
        default: () => [],
      },

      name: {
        type: String,
        default: '',
      },

      dropdownLabel: {
        type: String,
        default: '',
      },

      defaultSelectedOption: {
        type: [String, Number],
        default: '',
      },
    },

    data() {
      return {
        selectedValue: this.defaultSelectedOption,
      }
    },

    computed: {
      normalizedOptions() {
        if (this.options.length === 0) {
          return []
        }

        const isArrayOfObjects = typeof this.options[0] === 'object';

        if (isArrayOfObjects) {
          return this.options;
        }

        return this.options.map(option => ({
          label: option,
          value: option,
        }));
      },
    },

    methods: {
      onSelect() {
        this.$emit('select', this.selectedValue);
      },
    },
  }
</script>