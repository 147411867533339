<template>
  <div class="wrapper__hero-banner__carousel">
    <div
      ref="carousel"
      class="glide__hero-banner__carousel"
    >
      <div class="glide">
        <div
          class="glide__track"
          data-glide-el="track"
        >
          <ul class="glide__slides glide__slides__hero-banner is-unstyled">
            <CarouselItem
              v-for="(carouselItem, index) in carouselSlides"
              :key="index"
              :carouselItem="carouselItem"
              :images="carouselItem.images"
              :video="carouselItem.backgroundVideoFileLinkString"
              :data-link="carouselItem.buttonExternalLink"
              :data-index="index"
              :data-glide-autoplay="(enableAutoRotation) ? carouselItem.transitionTime * 1000 : ''"
              @click="redirectOnSlideClick(
                $event,
                index,
                carouselItem.buttonInternalLink,
                carouselItem.buttonExternalLink,
              )"
            />
          </ul>
          <div
            v-if="showCarouselArrows"
            class="glide__arrows glide__arrows__hero-banner"
            data-glide-el="controls"
          >
            <button
              v-show="!isStart"
              class="glide__arrow glide__arrow--left has-no-margin"
              data-glide-dir="<"
              type="button"
            >
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
              >
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#icon--carousel-left-stroke-white"
                ></use>
              </svg>
            </button>
            <button
              v-show="!isEnd"
              class="glide__arrow glide__arrow--right has-no-margin"
              data-glide-dir=">"
              type="button"
            >
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
              >
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#icon--carousel-right-stroke-white"
                ></use>
              </svg>
            </button>
          </div>
        </div>
        <div
          v-if="showCarouselBullets"
          class="glide__bullets glide__bullets__hero-banner"
          data-glide-el="controls[nav]"
        >
          <button
            v-for="(carouselItem, index) in carouselItems"
            :key="index"
            class="glide__bullet glide__bullet__hero-banner glide__bullet--active"
            :data-glide-dir="'=' + index"
          >
          </button>
        </div>
        <svg
          v-if="isCarouselEmpty"
          class="glide__empty"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
        >
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
  import Glide from "@glidejs/glide";
  import CarouselItem from "./carousel-item.vue";
  import {IS_USE_AUTOROTATION_IN_MILI_SEC, CONTROL_ELEMENTS_SELECTORS} from './const'
  import {trackHerobannerAction, hasControlElementInPath} from './utils'

  const DEFAULT_CAROUSEL_CONFIG = {
    type: 'slider',
    startAt: 0,
    perView: 1,
    peek: 0,
    autoplay: false,
    hoverpause: true,
    bound: true,
    gap: 0,
    dragThreshold: false,
    breakpoints: {
      // Breakpoint: mobile until tablet
      768: {
        peek: 0,
        autoplay: false,
        dragThreshold: 120,
      },
      // Breakpoint: tablet until desktop
      999: {
        autoplay: false,
        dragThreshold: 120,
      },
    },
  };

  export default {
    name: 'VueCarouselVideo',
    components: {CarouselItem},
    props: {
      enableAutoRotation: {
        type: Boolean,
        default: false,
      },
      enableArrows: {
        type: Boolean,
        default: false,
      },
      enableTabs: {
        type: Boolean,
        default: false,
      },
      carouselItems: {
        type: Array,
        default: () => [],
      },
      images: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        isStart: true,
        isEnd: false,
      };
    },
    computed: {
      carouselSlides() {
        return this.carouselItems.map((item, index) => {
          return {
            ...item,
            images: this.images[index],
          }
        })
      },
      showCarouselBullets() {
        return this.carouselItems.length > 1 && this.enableTabs
      },
      showCarouselArrows() {
        return this.carouselItems.length > 1 && this.enableArrows
      },
      isCarouselEmpty() {
        return this.carouselItems.length == 0
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        const sliderHerobannerCarousel = this.$refs.carousel;
        const {enableAutoRotation} = this;

        if (sliderHerobannerCarousel) {
          const glideHerobannerCarousel = new Glide(sliderHerobannerCarousel, {
            ...DEFAULT_CAROUSEL_CONFIG,
            autoplay: enableAutoRotation ? IS_USE_AUTOROTATION_IN_MILI_SEC : false,
          })

          glideHerobannerCarousel.on('run.after', () => {
            // eslint-disable-next-line no-underscore-dangle
            this.isStart = glideHerobannerCarousel._c.Run.isStart();
            // eslint-disable-next-line no-underscore-dangle
            this.isEnd = glideHerobannerCarousel._c.Run.isEnd();
          })

          glideHerobannerCarousel.mount();
        }
      },

      redirectOnSlideClick(event, slideIndex, internalLink, externalLink) {
        if (hasControlElementInPath(event.target, CONTROL_ELEMENTS_SELECTORS)) {
          return
        }

        trackHerobannerAction(`hb_s${slideIndex + 1}`);

        if (externalLink) {
          window.open(`https://${externalLink}`, '_blank');
        } else if (internalLink) {
          window.location.href = internalLink;
        }
      },
    },
  }
</script>