import { DomLoadedCheck } from '../utils/dom-loaded-check';
import { isMobile } from '../utils/index';

const CATEGORY_NAVIGATION_COMPONENT_CLASS = '.component--category-navigation';
const INPUT_CATEGORY_NAVIGATION_CLASS =
  'input.toggle--category-navigation.is-toggle-trigger';
const LABEL_CATEGORY_NAVIGATION_CLASS =
  '.toggle--category-navigation.is-toggle-label';
const LABEL_LANGUAGE_SWITCHER_CLASS =
  '.toggle--language-switcher.is-toggle-label';
const INPUT_LANGUAGE_SWITCHER =
  'input.toggle--language-switcher.is-toggle-trigger';
const CLOSE_BUTTON_CLASS = '.is-toggle-label.is-closing';
const LANGUAGE_SWITCHER = '.component--language-switcher-list';
const TOGGLE_NAVIGATION_MENU_ELEMENTS_CLASS = [
  '.toggle--hamburger.is-toggle-label',
  '.toggle--category-navigation.is-toggle-label',
].join();
const CLOSE_NAVIGATION_MENU_ELEMENTS_CLASS = '.toggle--category-navigation.is-toggle-label.is-closing';
const SEARCH_INPUT_MOBILE_ID = 'search-menu';
let isMenuShown = false;
const hideScroll = () => document.body.style.overflowY = 'hidden';
const showScroll = () => document.body.style.overflowY = 'auto';

function init() {
  let componentCategoryNavigationElement = document.querySelector(
    CATEGORY_NAVIGATION_COMPONENT_CLASS,
  );
  let categoryNavigationInputSelector = document.querySelector(
    INPUT_CATEGORY_NAVIGATION_CLASS,
  );
  let languageSwitcherInputSelector = document.querySelector(
    INPUT_LANGUAGE_SWITCHER,
  );
  let labelCategoryNavigationClass = document.querySelector(
    LABEL_CATEGORY_NAVIGATION_CLASS,
  );
  let labelLanguageSwitcherClass = document.querySelector(
    LABEL_LANGUAGE_SWITCHER_CLASS,
  );
  const toggleNavigationMenuElements = document.querySelectorAll(TOGGLE_NAVIGATION_MENU_ELEMENTS_CLASS);
  const closeNavigationMenuElements = document.querySelectorAll(CLOSE_NAVIGATION_MENU_ELEMENTS_CLASS);

  function addNavigationMenuVisibilityListeners() {
    toggleNavigationMenuElements.forEach(element => {
      element.addEventListener('click', event => {
        event.stopPropagation();
        if (isMobile() && (!isMenuShown || categoryNavigationInputSelector.checked)) {
          hideScroll();
          isMenuShown = true;
        } else {
          showScroll();
          isMenuShown = false;
        }
      });
    });

    closeNavigationMenuElements.forEach(element => {
      element.addEventListener('click', event => {
        event.stopPropagation();
        if (!categoryNavigationInputSelector.checked) {
          isMenuShown = false;
          showScroll();
        }
      });
    });

    document.addEventListener('click', (event) => {
      const classes = [
        'toggle--search-container',
        'is-hidden',
        'component--service-navigation',
        'component--language-switcher-list',
        'component--category-navigation',
      ];
      const isClickedInsideNavigation = event
        .composedPath()
        .some(({ classList }) => classList && classes.some(cssClass => classList.contains(cssClass)));

      if (isClickedInsideNavigation) {
        return;
      }

      if (document.body.classList.contains('has-dialog-activated')) {
        hideScroll();

        return;
      }

      showScroll();
      isMenuShown = false;
    });
  }


  function addEventListenerToLabelCategoryNavigation() {
    if (!labelCategoryNavigationClass) {
      return;
    }

    labelCategoryNavigationClass.addEventListener('click', () => {
      categoryNavigationInputSelector.checked = !categoryNavigationInputSelector.checked;
    });
  }

  function addEventListenerToLabelLanguageSwitcher() {
    if (!labelLanguageSwitcherClass) {
      return;
    }

    labelLanguageSwitcherClass.addEventListener('click', () => {
      languageSwitcherInputSelector.checked = !languageSwitcherInputSelector.checked;
    });
  }
  if (!componentCategoryNavigationElement) {
    return;
  }

  function addEventWhenClickedOutOfNav() {
    window.addEventListener('click', function(event) {
      if (event.target === labelCategoryNavigationClass) {
        return;
      }

      if (
        categoryNavigationInputSelector.checked
        && event.target.tagName !== 'NAV'
      ) {
        categoryNavigationInputSelector.checked = !categoryNavigationInputSelector.checked;
      }
    });
  }

  function addEventWhenClickedOutOfLanguageSelector() {
    window.addEventListener('click', function(event) {
      const { target } = event;

      if (target.closest(LABEL_LANGUAGE_SWITCHER_CLASS) === labelLanguageSwitcherClass
        || target.closest(LANGUAGE_SWITCHER)
      ) {
        return;
      }

      if (!target.closest('nav') || target.closest(CLOSE_BUTTON_CLASS)) {
        languageSwitcherInputSelector.checked = false;
      }
    });
  }

  function setEventOnSearchInputMobile() {
    document.getElementById(SEARCH_INPUT_MOBILE_ID).addEventListener('click', () => {
      if (isMobile() && document.getElementById(SEARCH_INPUT_MOBILE_ID).checked){
        hideScroll();
      } else {
        showScroll();
      }
    })
  }
  addEventListenerToLabelCategoryNavigation();
  addEventListenerToLabelLanguageSwitcher();
  addEventWhenClickedOutOfNav();
  addEventWhenClickedOutOfLanguageSelector();
  addNavigationMenuVisibilityListeners();
  setEventOnSearchInputMobile();
}

(() => {
  DomLoadedCheck(init);
})();
