// SPOL is a Stock and Price Overview List
import actions from './actions';
import mutations from './mutations';
import state from './state';
import getters from './getters';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state,
};

export const SPOL_NAMESPACE = 'SPOL_NAMESPACE';

export { default as actionTypes } from './actionTypes';
export { default as mutationTypes } from './mutationTypes';
export { default as getterTypes } from './getterTypes';