function setOffset(headerElement) {
  const headerOffset = headerElement.clientHeight;

  document.body.style.paddingTop = `${headerOffset}px`;
}

export default function(headerElement) {
  if (!headerElement) {
    return;
  }

  window.setTimeout(() => {
    setOffset(headerElement);
  }, 100);

  window.addEventListener('resize', () => {
    window.setTimeout(() => {
      setOffset(headerElement);
    }, 100);
  });
}
