import { DomLoadedCheck } from '../utils/dom-loaded-check';
import { EventBus , AppEvents } from '../utils/event_bus';

const SHOW_AS_PRODUCT_LINES_CLASS_NAME = 'show-as-product-lines';
const IS_TILE_VIEW_CLASS_NAME = 'is-tile-view';
const IS_LIST_VIEW_CLASS_NAME = 'is-list-view';
const SELECTOR_FRAGMENT_LIST_TILE_SWITCH = '.fragment--list-tile-switch';
const SELECTOR_CONTAINER_WITH_SWITCHABLE_PRODUCT_TILES = [
  '.container--product-tiles',
  '.container--list-detail-items',
];

function getTilesContainers() {
  return document.querySelectorAll(
    `${SELECTOR_CONTAINER_WITH_SWITCHABLE_PRODUCT_TILES.join(', ')}`,
  );
}

function showTileView() {
  let tilesContainers = getTilesContainers();

  [].forEach.call(tilesContainers, tilesContainer => {
    tilesContainer.classList.remove(SHOW_AS_PRODUCT_LINES_CLASS_NAME);
  });

}

function showListView() {
  let tilesContainers = getTilesContainers();

  [].forEach.call(tilesContainers, tilesContainer => {
    tilesContainer.classList.add(SHOW_AS_PRODUCT_LINES_CLASS_NAME);
  });

}

function changePresentation(newView) {

  EventBus.$emit(AppEvents.CHANGE_PRODUCT_VIEW);

  switch (true) {
    case newView.target.classList.contains(IS_TILE_VIEW_CLASS_NAME):
      showTileView();
      break;

    case newView.target.classList.contains(IS_LIST_VIEW_CLASS_NAME):
      showListView();
      break;
  }
}

function init() {
  let listTileSwitchToggleElement = document.querySelector(
    SELECTOR_FRAGMENT_LIST_TILE_SWITCH,
  );

  if (!listTileSwitchToggleElement) {return;}

  let presentation = listTileSwitchToggleElement.querySelectorAll(
    '.toggle--list-tile-switch.is-toggle-trigger',
  );

  [].forEach.call(presentation, option => {
    option.addEventListener('change', changePresentation, true);
  });
}

(() => {
  DomLoadedCheck(init);
})();
