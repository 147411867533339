import Glide from '@glidejs/glide';
import { DomLoadedCheck } from '../utils/dom-loaded-check';
import { screenSizeBreakPoint } from '../_global-constants';

const THUMBNAIL = '.thumbails__thumbnail';
const DIALOG_THUMBNAIL = '.dialog-thumbails__thumbnail';
const DIALOG_CLASS = '.show-dialog__product-image';
const PRODUCT_IMAGE_WITH_ZOOM_CONTAINER = '#dialog__product-image';
const PRODUCT_IMAGE_ZOOM = '.product-image-zoom';
const PRODUCT_IMAGE_ZOOM_IN = '.zoom-in';
const SLIDER_PRODUCT_IMAGE = '.glide--product-images';
const PRODUCT_IMAGE_ZOOM_NOTIFICATION = '.product-image__zoom-notification';
const IS_VISIBLE_IN_START = 'is-visible-in-start';
const TIMEOUT = 3000;

function init() {
  const thumbnails = document.querySelectorAll(THUMBNAIL);
  const dialogThumbnails = document.querySelectorAll(DIALOG_THUMBNAIL);
  const productImageDialogSelector = document.querySelectorAll(DIALOG_CLASS);
  const sliderProductImages = document.querySelector(SLIDER_PRODUCT_IMAGE);
  const productImageZoomIn = document.querySelectorAll(PRODUCT_IMAGE_ZOOM);
  const productImageZoomDialog = document.querySelector(
    PRODUCT_IMAGE_WITH_ZOOM_CONTAINER,
  );
  const productImageZoomNotificationSelector = document.querySelector(
    PRODUCT_IMAGE_ZOOM_NOTIFICATION,
  );

  /**Show image hover notification on mobile for 3 seconds */
  if (
    productImageZoomNotificationSelector
    && window.innerWidth < screenSizeBreakPoint
  ) {
    if (
      !productImageZoomNotificationSelector.classList.contains(
        IS_VISIBLE_IN_START,
      )
    ) {
      productImageZoomNotificationSelector.classList.add(IS_VISIBLE_IN_START);
    }
    setTimeout(() => {
      productImageZoomNotificationSelector.classList.remove(
        IS_VISIBLE_IN_START,
      );
    }, TIMEOUT);
  }

  /**Instantiate Slides */
  const glideProductImages = new Glide('.glide--product-images', {
    type: 'carousel',
    startAt: 0,
    perView: 1,
  });
  const glideProductImagesWithZoom = new Glide(
    '.glide--product-images-with-zoom',
    {
      type: 'carousel',
      perView: 1,
    },
  );

  /** Events on thumbnails to set/remove active class */
  [].forEach.call(thumbnails, (thumbnail, i) => {
    thumbnail.addEventListener('click', () => {
      [].forEach.call(thumbnails, thumbnail => {
        thumbnail.classList.remove('active');
      });
      thumbnails[i].classList.add('active');
      glideProductImages.go(`=${i}`);
    });
  });

  [].forEach.call(dialogThumbnails, (thumbnail, i) => {
    thumbnail.addEventListener('click', () => {
      [].forEach.call(dialogThumbnails, thumbnail => {
        thumbnail.classList.remove('active');
      });
      dialogThumbnails[i].classList.add('active');
      glideProductImagesWithZoom.go(`=${i}`);
    });
  });

  /** Always select the first thumbnail as active */
  glideProductImages.on('mount.after', () => {
    thumbnails[0].classList.add('active');
  });

  /**Align zoom image with product image after zoom image is mounted */
  glideProductImagesWithZoom.on('mount.after', () => {
    dialogThumbnails[glideProductImages.index].classList.add('active');
  });

  /** Sync the active slide with thumbail images*/
  glideProductImages.on('run', () => {
    const i = glideProductImages.index;

    [].forEach.call(thumbnails, thumbnail => {
      thumbnail.classList.remove('active');
    });

    thumbnails[i].classList.add('active');
  });

  glideProductImagesWithZoom.on('run', () => {
    const i = glideProductImagesWithZoom.index;

    glideProductImages.go(`=${i}`);
    [].forEach.call(dialogThumbnails, thumbnail => {
      thumbnail.classList.remove('active');
    });

    dialogThumbnails[i].classList.add('active');
  });

  /**Product Zoom in/out */
  [].forEach.call(productImageZoomIn, image => {
    image.addEventListener('click', () => {
      if (image.classList.contains('zoom-in')) {
        image.classList.remove('zoom-in');
        if (window.innerWidth < screenSizeBreakPoint) {
          productImageZoomDialog.close();
        }
        image.classList.add('zoom-out');
      } else {
        image.classList.remove('zoom-out');
        image.classList.add('zoom-in');

        const productImageZoomIn = document.querySelector(
          PRODUCT_IMAGE_ZOOM_IN,
        );

        productImageZoomIn.addEventListener('mousemove', event => {
          let zoomer = event.currentTarget;

          productImageZoomIn.style.WebkitTransformOrigin = `${event.offsetX} ${event.offsetY}`;
          zoomer.style.transformOrigin = `${event.offsetX}px ${event.offsetY}px 0`;
        });
      }
    });
  });

  /**Before mounting image with zoom align it with product image */
  glideProductImagesWithZoom.on('mount.before', () => {
    glideProductImagesWithZoom.update({
      startAt: glideProductImages.index,
    });
  });

  /* Mount slider */
  if (sliderProductImages) {
    glideProductImages.mount();
  }

  if (productImageDialogSelector) {
    let firstTimeClick = true;

    [].forEach.call(productImageDialogSelector, imageDialogContainer => {
      imageDialogContainer.addEventListener('click', e => {
        e.preventDefault();
        if (productImageZoomDialog.open && firstTimeClick) {
          firstTimeClick = false;
          glideProductImagesWithZoom.mount();
        } else {
          /*
            update method is used to update glide component
            regarding new size of browser. This is the solution to a bug where
            images were not visible in image zoom if browser resize after mounting
            imageZoom component.
          */
          glideProductImagesWithZoom.update({});
          glideProductImagesWithZoom.go(`=${glideProductImages.index}`);
        }
      });
    });
  }
}

(() => {
  DomLoadedCheck(init);
})();
