<template>
  <div>
    <vue-hierarchy-level
      v-for="(level, index) in levels"
      :key="index"
      :filter="parsedFilter.levels[index]"
      :initialItems="level"
      @item-clicked="onItemClick"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import { HIERARCHY_NAMESPACE, getterTypes, actionTypes } from '../../store/modules/facet-hierarchy';
  import { getFacetName } from '../../utils/facet-hierarchy';
  import VueHierarchyLevel from './hierarchy-level.vue';

  export default {
    name: 'VueHierarchy',

    components: {
      VueHierarchyLevel,
    },

    props: {
      initialItems: {
        type: Array,
        default: () => [],
      },
      refine: {
        type: Function,
        required: true,
      },
      filter: {
        type: [String, Object],
        required: true,
      },
    },

    data: function() {
      return {
        parsedFilter: typeof this.filter === 'string' ? JSON.parse(this.filter) : this.filter,
      };
    },

    computed: {
      ...mapGetters(HIERARCHY_NAMESPACE, {
        storeLevels: getterTypes.LEVELS,
      }),
      levels() {
        const hierarchyLevelsFromStore = this.storeLevels(this.parsedFilter.id);

        return hierarchyLevelsFromStore?.filter(level => level.length > 0) || [];
      },
    },

    created: function() {
      this.setHierarchy({ facetId: this.parsedFilter.id, hierarchy: this.initialItems, refine: this.refine });
    },

    methods: {
      ...mapActions(HIERARCHY_NAMESPACE, {
        'setHierarchy': actionTypes.SET_HIERARCHY,
        'setItemRefinement': actionTypes.SET_ITEM_REFINEMENT,
      }),

      refineItemHierarchy(item) {
        if (item.childValues && item.childValues.length > 0) {
          item.childValues.forEach(child => {
            this.refineItemHierarchy(child);
            if (child.isRefined) {
              this.refine(getFacetName(child));
              this.setItemRefinement({ item: child, isRefined: false });
            }
          });
        }
      },

      onItemClick(item) {
        if (item.isRefined) {
          this.refineItemHierarchy(item);
        }

        this.setItemRefinement({ item, isRefined: !item.isRefined });
        this.refine(getFacetName(item));
      },
    },

    watch: {
      initialItems(changedItems) {
        this.setHierarchy({ facetId: this.parsedFilter.id, hierarchy: changedItems, refine: this.refine });
      },
    },
  }
</script>