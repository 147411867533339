var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'fragment--badge',
    {
      'is-error' : _vm.error,
      'is-bigger' : _vm.big,
      'is-success' : _vm.success,
    },
    _vm.classes,
  ]},[_c('span',{staticClass:"fragment--label-badge",domProps:{"textContent":_vm._s(_vm.label)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }