import { mapGetters, mapActions } from 'vuex';
import { HIERARCHY_NAMESPACE } from '../store/modules/facet-hierarchy/index';
import { VUE_EVENT_NAME__SHOW_FILTERS, algoliaRequiredType } from '../_global-constants';
import { extractFacetLabel } from '../utils/search-utils';

export default {
  name: 'VueFilterSelections',
  render() {
    return this.$scopedSlots.default({
      openFilterView: this.openFilterView,
      transformFacetValues: this.transformFacetValues,
      onItemClick: this.onItemClick,
    });
  },
  computed: {
    ...mapGetters(HIERARCHY_NAMESPACE, ['getItemByName']),
  },
  methods: {
    ...mapActions(HIERARCHY_NAMESPACE, ['setItemRefinement']),

    openFilterView: function() {
      this.$root.$emit(VUE_EVENT_NAME__SHOW_FILTERS);
    },
    transformFacetValues(items) {
      return items.map(item => ({
        ...item,
        refinements: this.transformRefinements(item.refinements),
      }));
    },
    transformRefinements(refinements) {
      return refinements.map(refinement => ({
        ...refinement,
        label: extractFacetLabel(refinement.label),
      }));
    },
    refineHierarchy(item, refine, attribute) {
      if (item.childValues && item.childValues.length > 0) {
        item.childValues.forEach(child => {
          this.refineHierarchy(child, refine, attribute);
          if (child.isRefined) {
            refine({ ...child, attribute, type: algoliaRequiredType });
            this.setItemRefinement({ item: child, isRefined: false });
          }
        });
      }
    },
    onItemClick(refinement, refine) {
      const item = this.getItemByName(refinement.value);

      if (item.isRefined) {
        this.refineHierarchy(item, refine, refinement.attribute);
      }
      refine(refinement);
      this.setItemRefinement({ item: refinement, isRefined: !refinement.isRefined });
    },
  },
};
