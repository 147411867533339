<template>
  <div
    v-if="invoices && invoices.length > 0"
    data-test-id="download-invoices"
    class="download-invoices"
  >
    <template v-if="isMobile">
      <div
        v-for="(invoiceId, index) in invoices"
        :key="invoiceId"
        @click="downloadInvoice(invoiceId)"
      >
        <svg
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="16"
        >
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="#icon--document"
          ></use>
        </svg>
        <span
          class="download-invoices-text"
        >
          {{ translations.downloadOrderDetailInvoiceOne }}-{{ index + 1 }}
        </span>
      </div>
    </template>
    <template v-else>
      <div @click="downloadInvoices">
        <svg
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="16"
        >
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="#icon--document"
          ></use>
        </svg>
        <span
          class="download-invoices-text"
        >
          {{ translations.downloadOrderDetailInvoice }}
        </span>
      </div>
    </template>
  </div>
  <div
    v-else
    data-test-id="download-invoices"
    class="download-invoices not-available"
  >
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="16"
    >
      <use
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xlink:href="#icon--document"
      ></use>
    </svg>
    <span
      class="download-invoices-text"
    >
      {{ translations.downloadOrderDetailInvoiceNotReady }}
    </span>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import { INVOICES_NAMESPACE } from '../../store/modules/invoices';
  import { TRANSLATIONS_NAMESPACE, getterTypes as translationGetters } from '../../store/modules/translations';
  import { isMobile } from '../../utils';

  export default {
    name: 'VueDownloadInvoices',

    props: {
      orderId: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        isMobile: false,
        invoices: [],
      }
    },

    computed: {
      ...mapGetters(TRANSLATIONS_NAMESPACE, [translationGetters.TRANSLATIONS]),
    },

    mounted() {
      this.isMobile = isMobile();
      this.disableDownloadLinkIfNoInvoices();
    },

    methods: {
      ...mapActions(INVOICES_NAMESPACE, ['downloadAllInvoices', 'getInvoicesForOrder', 'downloadOneInvoice']),

      async downloadInvoices() {
        if (this.invoices.length > 0) {
          const loader = this.$loading.show({
            active: true,
            isFullPage: true,
          });

          try {
            await this.downloadAllInvoices(this.orderId);
          } finally {
            loader.hide();
          }
        }
      },

      async downloadInvoice(invoiceId) {
        return this.downloadOneInvoice({ orderId: this.orderId, invoiceId });
      },

      async disableDownloadLinkIfNoInvoices() {
        const loader = this.$loading.show({
          active: true,
          isFullPage: true,
        });

        try {
          this.invoices = await this.getInvoicesForOrder(this.orderId);
        } finally {
          loader.hide();
        }
      },
    },
  };
</script>