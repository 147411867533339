<template>
  <div
    v-if="addedQuantity > 0"
    class="product-tile__notifications__cart product-cart-icon"
    :class="{ 'is-approved' : isApproved }"
  >
    <div class="product-tile__notifications__cart--inner font-family-title">
      <svg
        class="cart-icon__icon"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        stroke="#202a4f"
      >
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#icon--cart"
        ></use>
      </svg>
      <span
        class="product-tile__notifications__cart__total-of-product font-h5"
      >{{ addedQuantity }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VueProductCartIcon',

    props: {
      addedQuantity: {
        type: [Number, String],
        default: 0,
      },
      isApproved: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>