import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import state from './state';

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

export const HIERARCHY_NAMESPACE = 'HIERARCHY_NAMESPACE';
export { default as getterTypes } from './getterTypes';
export { default as actionTypes } from './actionTypes';
export { default as mutationTypes } from './mutationTypes';