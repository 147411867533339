import { VUE_URL__GET_SPOL, VUE_URL__GET_SPOL_AVAILABILITY } from '../../../_global-constants';
import {http} from "../../../utils/http-requests";
import mutationTypes from './mutationTypes';
import actionTypes from './actionTypes';

const actions = {
  async [actionTypes.REQUEST_DOWNLOAD]() {
    try {
      const response = await http.post(VUE_URL__GET_SPOL)

      return response;
    } catch (err) {
      console.error('Error while requesting SPOL export: ', err);
      throw err;
    }
  },

  async [actionTypes.REQUEST_AVAILABILITY](_, date) {
    const formattedBody = {zonedDateTime: date};

    try {
      const request = await http.post(VUE_URL__GET_SPOL_AVAILABILITY, formattedBody)

      return request;
    } catch (err) {
      console.error('Error while requesting SPOL availability: ', err);
      throw new Error;
    }
  },

  [actionTypes.UPDATE_AVAILABILITY]({ commit }, availability) {
    commit(mutationTypes.UPDATE_LINK, availability);
    commit(mutationTypes.WAS_SPOL_SET, true);
  },
};

export default actions;
