var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"filter-container",class:{ 'is-hidden-touch': !_vm.isFilterView }},[_c('div',{staticClass:"filter-close-icon is-hidden-desktop"},[_c('svg',{attrs:{"role":"img","xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24"},on:{"click":function($event){return _vm.closeFilterView()}}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":"#icon--close"}})])]),_vm._v(" "),_c('div',{staticClass:"filter-section"},[_c('div',{staticClass:"filter-title is-flex"},[_c('div',{staticClass:"font-h3 text-uppercase"},[_vm._v("\n          "+_vm._s(_vm.translations.filterTitle)+"\n        ")]),_vm._v(" "),_c('ais-clear-refinements',{scopedSlots:_vm._u([{key:"default",fn:function({ canRefine, refine, createURL }){return _c('div',{staticClass:"text-uppercase is-link-secondary filter-selections_all-option"},[(canRefine)?_c('a',{staticClass:"is-link is-color-secondary",attrs:{"href":createURL()},domProps:{"innerHTML":_vm._s(_vm.translations.clearSearchFilter)},on:{"click":function($event){$event.preventDefault();return refine.apply(null, arguments)}}}):_vm._e()])}}])})],1)]),_vm._v(" "),_vm._l((_vm.filters),function(filter,index){return _c('div',{key:index,staticClass:"filter-section"},[_c('input',{attrs:{"id":`panel-${filter.id}`,"type":"checkbox","name":"panel","checked":""}}),_vm._v(" "),_c('div',{staticClass:"accordion__content font-small-body-copy"},[_c('div',{staticClass:"accordion__body"},[_c('ais-refinement-list',{attrs:{"searchable":"","showMore":"","attribute":filter.id,"limit":998,"showMoreLimit":999,"transformItems":_vm.transformFacetValueItems},scopedSlots:_vm._u([{key:"default",fn:function({
                items,
                isShowingMore,
                canToggleShowMore,
                refine,
                toggleShowMore,
              }){return _c('div',{},[(items.length > 0)?_c('hr'):_vm._e(),_vm._v(" "),_c('vue-hierarchy',{attrs:{"filter":filter,"initialItems":items,"refine":refine}}),_vm._v(" "),(canToggleShowMore)?_c('button',{staticClass:"show-all-panel is-tertiary has-no-margin text-grey text-uppercase",on:{"click":toggleShowMore}},[(isShowingMore)?_c('span',{staticClass:"more-less-link is-link min-right"},[_vm._v("\n                  "+_vm._s(_vm.translations.showLessSearch)+"\n                ")]):_c('span',{staticClass:"more-less-link is-link plus-right"},[_vm._v("\n                  "+_vm._s(_vm.translations.showMoreSearch)+"\n                ")])]):_vm._e()],1)}}],null,true)})],1)])])}),_vm._v(" "),_c('hr'),_vm._v(" "),_c('ais-pagination',{scopedSlots:_vm._u([{key:"default",fn:function({ nbHits }){return _c('div',{staticClass:"button-container is-hidden-desktop"},[_c('button',{staticClass:"is-primary has-no-max-width has-no-margin",attrs:{"type":"button"},on:{"click":function($event){return _vm.closeFilterView()}}},[_c('span',{attrs:{"remove":"tag"}},[_vm._v("\n            "+_vm._s(_vm.translations.showHitsMessagePrefix)+"\n          ")]),_vm._v("\n          "+_vm._s(nbHits)+"\n          "),_c('span',{attrs:{"remove":"tag"}},[_vm._v("\n            "+_vm._s(_vm.translations.showHitsMessageSuffix)+"\n          ")])])])}}])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }