import { createNamespacedHelpers } from 'vuex';

import { CART_NAMESPACE } from '../store/modules/cart/index';

import {
  CART_GETTER__TOTAL_PRICE,
  CART_GETTER__TOTAL_NET,
  CART_GETTER__TOTAL_GROSS,
  CART_GETTER__CART_TOTALS,
} from '../store/modules/cart/getters';

const { mapGetters } = createNamespacedHelpers(CART_NAMESPACE);

export default {
  name: 'VueCartTotals',

  props: {
    ssr: {
      required: false,
    },

    cart: {
      required: false,
    },
  },

  computed: {
    ...mapGetters({
      totalPrice: CART_GETTER__TOTAL_PRICE,
      totalNet: CART_GETTER__TOTAL_NET,
      totalGross: CART_GETTER__TOTAL_GROSS,
      cartTotals: CART_GETTER__CART_TOTALS,
    }),
  },

  render() {
    let scopedSlotsData = {
      totalPrice: this.totalPrice,
      totalNet: this.totalNet,
      totalGross: this.totalGross,
      cartTotals: this.cartTotals,
    };

    if (this.ssr) {
      let cartData = JSON.parse(this.cart);

      scopedSlotsData = {
        totalPrice: cartData.totalPrice,
        totalNet: cartData.totalNet,
        totalGross: cartData.totalGross,
        cartTotals: cartData.cartTotals,
      };
    }

    return this.$scopedSlots.default(scopedSlotsData);
  },
};
