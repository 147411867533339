import {
  VUE_OPEN_ORDER_ACTION_DIALOG,
  VUE_URL__ORDERS,
} from '../_global-constants';
import {http} from "../utils/http-requests";
import { addEventDialog } from './dialog';

export default {
  name: 'VueActionButton',
  render() {
    return this.$scopedSlots.default({
      openOrderActionDialog: this.openOrderActionDialog,
      sendOrderAction: this.sendOrderAction,
      showMoreActions: this.showMoreActions,
      isMoreActionOpen: this.isMoreActionOpen,
    });
  },
  data: function() {
    return {
      isMoreActionOpen: false,
    };
  },
  mounted: function() {
    let dialogTriggers = this.$el.querySelectorAll('.show-dialog-link');

    addEventDialog(dialogTriggers);
  },
  methods: {
    openOrderActionDialog: function(action) {
      this.$root.$emit(VUE_OPEN_ORDER_ACTION_DIALOG, action);
    },
    showMoreActions: function() {
      this.isMoreActionOpen = !this.isMoreActionOpen;
    },
    sendOrderAction: async function(orderId, action) {
      this.$loading.show({
        active: true,
        container: this.$el,
      });

      let url = VUE_URL__ORDERS + '/' + orderId + '?action=' + action;

      try {
        let response = await http.put(url);

        if (response) {
          //TODO check if we can update the page without reloading
          window.location.reload();
        }

        return true;
      }
      catch (error) {
        if (error.hasOwnProperty('response')) {
          error.response
            .json()
            .then(errResp => {
              let notification = window.BNS.globalNotification(
                errResp.message,
              );

              notification.show();

              return true;
            })
            .catch(e => {
              console.error(e);

              return true;
            });
        }

        let notification = window.BNS.globalNotification(
          error.message || error,
        );

        notification.show();

        return;
      }
    },
  },
};
