import actionTypes from "./actionTypes";
import mutationTypes from "./mutationTypes";

const actions = {
  [actionTypes.UPDATE_FEATURE_TOGGLES]: ({ commit }, payload) => {
    const features = payload ?? {}

    commit(mutationTypes.UPDATE_FEATURE_TOGGLES, features)
  },
}

export default actions;