export default function(element) {
  let wScrollBefore = 0;

  window.addEventListener('scroll', () => {
    let elHeight = element.offsetHeight;
    // let dHeight = document.body.offsetHeight;
    // let wHeight = window.innerHeight;
    let wScrollCurrent = window.pageYOffset;
    let wScrollDiff = wScrollBefore - wScrollCurrent;
    let elTopCurrent = parseInt(
      window.getComputedStyle(element).getPropertyValue('top'),
      10,
    );
    let elTop = elTopCurrent + wScrollDiff;

    if (wScrollCurrent <= 0) {
      // scrolled to the very top; element sticks to the top
      element.style.top = '0px';
    } else if (wScrollDiff > 0) {
      // scrolled up; element slides in
      element.style.top = (elTop > 0 ? 0 : elTop) + 'px';
    } else if (wScrollDiff < 0) {
      // scrolled down
      element.style.top =
        (Math.abs(elTop) > elHeight ? -elHeight : elTop) + 'px';
    }

    wScrollBefore = wScrollCurrent;
  });
}
